//
// This file defines a few objects that are used to provide
// control and viewing of scoring related panels and setup.
//
// Each should be bound to the appropriate root interface
// element after dom ready.

// Creating and Managing Point Tables
// Used in: /scoring/models/*
//
//   ScoringPointTable.setup(pointTableElement)
//
var ScoringPointTable = {}
; (function(){

  var $element

  var $pointTableActiveList = function(){
    return $element.find('#point-table-list')
  }

  var $pointTableInactiveList = function(){
    return $element.find('#inactive-point-table-list')
  }

  var $pointTableTemplates = function(){
    return $element.find('#point-table-template-list')
  }

  var $pointTableDialog = function(){
    var $dialog = $('#point_table_dialog')
    if ($dialog.length < 1) {
      $dialog = $('<div/>')
      $dialog.attr('id', 'point_table_dialog')
      $dialog.appendTo($element)
    }
    return $dialog
  }

  var conditionallyMarkAsync = function($link, $dialog) {
    if ($link.hasClass('async')) {
      $dialog.addClass('async-dialog')
    } else {
      $dialog.removeClass('async-dialog')
    }
  }

  var conditionallyMarkAction = function($link, $dialog) {
    if ($link.hasClass('new')) {
      $dialog.addClass('new')
    } else if ($link.hasClass('edit')) {
      $dialog.addClass('edit')
    }
  }

  var triggerDialog = function(element){
    var $triggeringLink = $(element)
    var loadUrl = $triggeringLink.attr('href')

    $.get(loadUrl, function(data){
      var $dialog = $pointTableDialog()
      conditionallyMarkAsync($triggeringLink, $dialog)
      conditionallyMarkAction($triggeringLink, $dialog)
      $dialog.dialog({
        autoOpen: false,
        width: 600,
        title: $triggeringLink.text(),
        draggable: false,
        resizable: false,
        position: { my: "top", at: "top+150" }
      }).dialog('open').html(data)
      $dialog.find('form').submit(onSubmitPointTable)
    })
  }

  var onSubmitPointTable = function(event) {

    var $form = $(this)
    var $container = $form.parent()

    var url = $form.attr('action')

    $.ajax({
      type: "POST",
      url: url,
      data: $form.serialize(),
      success: function(data){
        if($dialog.hasClass('new')) {
          $dialog.dialog('close')
          retrieveHtml()
        }
        else if ($dialog.hasClass('edit')) {
          $dialog.html(data.responseText)
          $dialog.find('#form_flash').delay(2000).fadeOut()
        }
      },
      error: function(data){
        $dialog.html(data.responseText)
        $dialog.find('#form_flash').delay(2000).fadeOut()
      }
    })

    $form.find('input').attr('disabled', true)

    event.preventDefault ? event.preventDefault() : event.returnValue = false;

    return false
  }

  var bindClickHandlers = function(){
    $element.on('click', '.dialog', function(event){
      event.stopPropagation()
      triggerDialog(this)
      return false
    })
  }

  var retrieveHtml = function(){
    var $ptActiveList = $pointTableActiveList()
    $ptActiveList.html('').addClass('loading')
    $.get('/scoring/point_tables', {active: true}, function(data){
      $ptActiveList.removeClass('loading')
        .html(data)
    })
    var $ptInactiveList = $pointTableInactiveList()
    $ptInactiveList.html('').addClass('loading')
    $.get('/scoring/point_tables', {active: false}, function(data){
      $ptInactiveList.removeClass('loading')
        .html(data)
    })
    var $ptTempl = $pointTableTemplates()
    $ptTempl.html('').addClass('loading')
    $.get('/scoring/point_table_templates', function(data){
      $ptTempl.removeClass('loading')
        .html(data)
    })
  }

  this.setup = function(element){
    $element = $(element)
    if (this.isElementPresent()) {
      bindClickHandlers()
      retrieveHtml()
    }
  }

  this.element = function(){
    return $element
  }

  this.isElementPresent = function(){
    return $element.length != 0
  }

}).apply(ScoringPointTable)

$(function(){
  ScoringPointTable.setup('#configure_point_tables')
})



// Scoring model interface controls
// Used in: /scoring/models/*
//
//   ScoringModelControl.setup(scoringInterfaceElement)
//
var ScoringModelControl = {}
; (function(){

  var $element

  var $scoringModelList = function(){
    return $element.find('.record-list')
  }

  var $scoringModelDialog = function(){
    var $dialog = $('#scoring_model_dialog')
    if ($dialog.length < 1) {
      $dialog = $('<div/>')
      $dialog.attr('id', 'scoring_model_dialog')
      $dialog.appendTo($element)
    }
    return $dialog
  }

  var bindClickHandlers = function(){
    $element.on('click', '.dialog', function(event){
      event.stopPropagation()
      triggerDialog(this)
      return false
    })
  }

  var triggerDialog = function(element){
    var $triggeringLink = $(element)
    var loadUrl = $triggeringLink.attr('href')

    $.get(loadUrl, function(data){
      $dialog = $scoringModelDialog()
      conditionallyMarkAsync($triggeringLink, $dialog)
      conditionallyMarkAction($triggeringLink, $dialog)
      $dialog.dialog({
        autoOpen: false,
        width: 600,
        title: $triggeringLink.text(),
        draggable: false,
        resizable: false,
        position: { my: "top", at: "top+150" }
      }).dialog('open').html(data)
      $dialog.find('form').submit(onSubmitScoringModel)
    })
  }

  var conditionallyMarkAsync = function($link, $dialog) {
    if ($link.hasClass('async')) {
      $dialog.addClass('async-dialog')
    } else {
      $dialog.removeClass('async-dialog')
    }
  }

  var conditionallyMarkAction = function($link, $dialog) {
    if ($link.hasClass('new')) {
      $dialog.addClass('new')
    } else if ($link.hasClass('edit')) {
      $dialog.addClass('edit')
    }
  }

  var onSubmitScoringModel = function(event) {

    var $form = $(this)
    var $container = $form.parent()

    var url = $form.attr('action')

    $.ajax({
      type: "POST",
      url: url,
      data: $form.serialize(),
      success: function(data){
        $dialog.dialog('close')
        $scoringModelList().prepend(data).children().eq(0).highlight(1000)
        $('.blank_pane').hide();
      },
      error: function(data){
        $dialog.html(data.responseText)
        $dialog.find('#form_flash').delay(2000).fadeOut()
        $dialog.find('form').submit(onSubmitScoringModel)
      }
    })

    $form.find('input').attr('disabled', true)

    event.preventDefault ? event.preventDefault() : event.returnValue = false;

    return false
  }

  this.setup = function(element){
    $element = $(element)
    if (this.isElementPresent()) {
      bindClickHandlers()
    }
  }

  this.element = function(){
    return $element
  }

  this.isElementPresent = function(){
    return $element.length != 0
  }

}).apply(ScoringModelControl)

$(function(){
  ScoringModelControl.setup('#configure_scoring_models')
})

$(function(){
  $('#scroing_model_information').find('ul.switch li a').removeAttr('href');
  // select tab via URL hash
  // this way, refreshing the page won't change the tab
  var hash = window.location.hash
  if (hash == "#show_point_tables" || hash == "#show_scoring_models") {
    $.switchTab(window.location.hash)
  }
})

$(function(){
  $(document).on('click', '#update_scores_button', function(event){
    var $pane = $("#async_content_scoring_pane")
    $pane.html('')
    $pane.addClass('loading')
    var asyncTargetUrl = $pane.data('async-url')
    var delayedAsyncTargetUrl = $pane.data('delayed-async-url')
    var url = asyncTargetUrl || delayedAsyncTargetUrl
    $.get(url, function(data){
      $pane.html(data)
      $pane.effect('highlight', {color:'#DFD'}, 2000)
      // need to re-initialize the score details button
      $pane.find('.pop_over').initPopOver();
    })
    return false
  })
})
