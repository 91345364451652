(function ($) {
  /* auto submit */
  $.fn.autoSubmitInput = function () {
    return this.off('change').on('change', function () {
      var applicant_criterion = $(this);
      var comment_text = applicant_criterion.val();
      var applicant_criterion_id = applicant_criterion.data('applicant-criterion-id');
      var applicant_id = applicant_criterion.data('applicant-id');
      var criterion_id = applicant_criterion.data('criterion-id');
      var url = '/criteria/' + criterion_id + '/free_comment';
      var criterion_name = applicant_criterion.attr('data-name');
      var updated_state = 'Notes of criterion ' + criterion_name + ' updated.'

      $.post(url, {
        _method: 'put', applicant_id: applicant_id, applicant_criterion_id: applicant_criterion_id, free_form_comment: comment_text
      }, function (data) {
        if (data.requirement) {
          $('#requirement_' + data.requirement_id).replaceWith(data.requirement);
          $('tr#criterion_' + criterion_id + ' td').effect('highlight', { color: '#dfd' }, 2000);
          apply_alternate_row_color();
          $('#requirement_' + data.requirement_id).find('.auto_submit').autoSubmitInput();
          $('.req-status-text').text(updated_state);
          $('#criterion_' + criterion_id).find('.auto_submit').focus();
        }
      });
    });
  };
}(jQuery));
