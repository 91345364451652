/* jquery.local_gpas.js */

$(document).ready(function() {
  $(document).on("click", "fieldset.switcher input[type=radio]", function () {
    var switch_to = $(this).attr("switch_to");
    $(".switch_pane").hide();
    $("#" + switch_to).show();
  });
});

jQuery.fn.local_gpa_selector = function(container_name, gpa_overview) {

  var prefix = "local_gpa_custom_";
  // controls
  var $select_box = $(this); // select local_gpa
  var $group_by = $("#courses_group_by"); // select group by
  var $local_gpa_filter = $("#local_gpa_filter"); // local_gpa checkbox filter
  var $hide_unrelated = $("#hide_unrelated_courses"); // local_gpa checkbox filter

  $hide_unrelated.hide();

  // var $gpa_dialog = $('#new_gpa_template');
  // $gpa_dialog.dialog({autoOpen: false, width: 500, title: "New Local GPA"});

  var selected_bgcolor = "#f7f6af";
  var bgcolor = "#fff";
  var unselected_style = {color: '#666', backgroundColor: bgcolor};
  var selected_style   = {color: '#000', backgroundColor: selected_bgcolor};
  var $new_link = $('#new_local_gpa_link');

  $new_link.on("new_local_gpa", function() {
    $gpa_dialog.dialog('open');
  });

  $new_link.on("click", function(){
    $(this).trigger("new_local_gpa");
  });

  $select_box.on("select_local_gpa", function () {
    var $option = $(this).find(":selected");
    $('.local_gpa_column').hide();
    $('.subject').css(unselected_style).removeClass('selected_subject');
    if ( $option.parent().hasClass("select_local_gpas") ) {
      $('.local_gpa_subject_' + $(this).val()).css(selected_style).addClass('selected_subject');
      $('.' + prefix + $(this).val()).show();
      $local_gpa_filter.attr("disabled", false);
      $hide_unrelated.show();
      $local_gpa_filter.trigger("filter_courses");
    } else if ( $option.parent().hasClass("select_requirements") ) {
      $(".pop_up_requirement").hide();
      $(".pop_up_requirement_"+$select_box.val()).show();
      $local_gpa_filter.attr("disabled", true);
      $hide_unrelated.hide();
      $("."+ $group_by.val() + "_course").show();
    } else {
      $hide_unrelated.hide();
    }
  });

  $select_box.change( function() { $select_box.trigger("select_local_gpa"); });
  $local_gpa_filter.click(function() { $local_gpa_filter.trigger("filter_courses") });

  // set inital state
  $select_box.trigger("select_local_gpa");
};

