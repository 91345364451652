;(function ($) {
  // Adds auto completion to a regular lookup input field.
  // The data will be filled either from `field.field_values`
  // or it will fallback to an API call to `/fields`
  $.fn.listLookupValueField = function (field, source, value, options) {
    var opts = $.extend({}, $.fn.listLookupValueField.defaults, options);

    this.filter('input').each(function () {
      var input = $(this);
      var name = input.attr('name');

      // The hidden field will submit the value
      var hiddenInput = $('<input type="hidden" name="' + name + '">');
      input.attr('name', '');
      input.attr('placeholder', 'Please type to get suggestions');
      input.attr('autocomplete', 'off');
      input.addClass('typeahead');
      input.attr('aria-label', 'Please enter the value of the field ' + field.name);
      input.val('');

      input.after(hiddenInput);

      input.typeahead(opts, {
        limit: 35,
        displayKey: function(item){
          if(item.state){
            return item.name + ' – ' + item.state;
          }else{
            return item.name;
          }
        },
        valueKey: 'value',
        source: source,
        templates: {
          empty: [
            '<div class="empty-message">',
            'unable to find any options that match the current query',
            '</div>'
          ].join('\n'),
          suggestion: function(data) {
            if(data.state) {
            return '<p><strong>' + data.name + '</strong> - ' + data.state + '</p>';
            } else {
            return '<p><strong>' + data.name + '</strong></p>';
            }
          }
        }
      }).on('typeahead:autocomplete typeahead:select', function (event, data) {
        hiddenInput.val(data.value);
      });

      // If we have the value, we set the name in the test input
      // and the key in the hidden input
      if (value && value.length > 0) {
        var results = source.get(value);
        if (results && results.length > 0) {
          var obj = results[0];
          input.typeahead('val', obj.name);
          hiddenInput.val(obj.value);
        }
      }
    });

    return this;
  };

  $.fn.listLookupValueField.defaults = {
    hint: true,
    highlight: true
  };
}(jQuery));
