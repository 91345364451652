$(document).ready(function(){
  $(document).on('click', '.open-date-picker', function () {
    $(".hard-deadline-date-picker").val('');
    datePickerDialog($(this), 'li', 'open-date-picker');
  });

  $(document).on('keydown', '.open-date-picker', function(e){
    $(".hard-deadline-date-picker").val('');
    if (e.keyCode === 9 || e.keyCode === 13) {
      if(isDate($(this).val())){
        var msg = 'Please enter Hard deadline in mm/dd/yyyy format and should be greater or equal to ';
        msg += $(this).val();
        $(".hard-deadline-date-picker").attr('aria-label', msg);
      }
    }
  });

  $(document).on('keydown', '.hard-deadline-date-picker', function(e){
    var currentObj = $(this);
    if (e.keyCode === 9 || e.keyCode === 13) {
      if(currentObj.val()) {
          var format = isDate(currentObj.val());
          if(format) {
            currentObj.val(currentObj.val() + " 23:59:59");
          }
          if($(".deadline-display-select").val()) {
            $(".deadline-display-select").val("");
          }
        }
    }
  });

  $(document).on('click', '.hard-deadline-date-picker', function () {
    var pickerProperties = { dateFormat: "mm/dd/yy", maxDate: $(".hard-deadline-date-picker").data("max-date"),
    minDate: new Date($(".open-date-picker").val()) }
    harddatePickerDialog($(this), 'li', 'hard-deadline-date-picker', pickerProperties);
  });

  function harddatePickerDialog(currentObj,targetTag,targetClass,pickerProp) {
    if (pickerProp === undefined) {
      pickerProp = {};
    }
    var picker = currentObj;
    var targetEle = picker.closest(targetTag);
    picker.append('<div class="picker"></div>');
    var dialog = $('.picker').dialog({
    modal: true,
    autoResize: true,
    draggable: true,
    closeOnEscape: false,
    resizable: false,
    title: 'Select a date',
    open: function(event, ui) {
      $('.ui-widget-overlay').on('click', function(event,ui) {
        $('.picker').dialog('close');
      });
      $.extend(pickerProp, {onSelect: function(selectedDate){
          targetEle.find('.' + targetClass).val(selectedDate + " 23:59:59");
          if($(".deadline-display-select").val()) {
            $(".deadline-display-select").val("");
          }
          $(".picker").dialog('close');
         },beforeShow: function(){
        $('.hard-deadline-date-picker').datepicker("option", { minDate: $(".open-date-picker").datepicker('getDate') });
      }});
      $(this).datepicker(pickerProp).datepicker( 'show' );
    },
    width: 'auto',
    height: 'auto',
    overflow: 'none',
    close: function(event, ui) {
      $(this).closest('.ui-dialog').remove();
      $(this).remove();
    }
    });
  }

  function isDateTime(txtDate) {
    var currVal = txtDate;
    if (currVal === '')
      return true;

    var rxDatePattern = /^(\d{1,2})(\/)(\d{1,2})(\/)(\d{4}) (\d{1,2}):(\d{1,2}):(\d{1,2})$/ ;

    var dtArray = currVal.match(rxDatePattern);

    if (dtArray == null)
      return false;

    var dtMonth = dtArray[1];
    var dtDay = dtArray[3];
    var dtYear = dtArray[5];

    return dateFormatCheck(dtDay, dtMonth, dtYear);
  }

  $('#edit_program, #new_program').on('submit', function(e) {
    $('.hard-deadline-date-picker').each(function(){
      var currentObj = $(this);
      var format = isDateTime(currentObj.val());
      if(!format) {
        alert(currentObj.attr('aria-label'));
        e.preventDefault();
      }
    });
  });
});
