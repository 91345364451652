/* jquery.statefulpanes.js */

; (function($){
  $.cas_id = $.email = $.first_name = $.last_name = function() {}

  $.openedState = function(domId, feedback){
    var pane = $(domId).parent().find('.state_pane');
    $(domId).removeClass('closed');
    $(domId).addClass('opened');

    var eventOnOpen = pane.attr('data-on-open')
    if (eventOnOpen) {
      pane.trigger(eventOnOpen)
      pane.attr('data-on-open', null)
    }

    if(feedback) {
      pane.slideDown('fast');
    } else {
      pane.show();
    }
  };

  $.closedState = function(domId, feedback){
    var pane = $(domId).parent().find('.state_pane');

    $(domId).removeClass('opened');

    if(feedback) {
      pane.slideUp('fast');
    } else {
      pane.hide();
    }
    $(domId).addClass('closed');
  };

  $.addOpenedState = function(domId){
    $.openedState(domId);
    $.postUiState(domId, 'opened');
  };

  $.addClosedState = function(domId){
    $.closedState(domId, 'feedback');
    $.postUiState(domId, 'closed');
  };

  $.switchTab = function(switchId, autofocus){
    autofocus = (autofocus === undefined) ? false : autofocus;
    var $switchTab = $(switchId);
    if ($switchTab.length) {
      closeOpenedPopups();
      var $switchTabUl = $switchTab.closest('ul');

      var $targetPanel = $($switchTab.attr('data-show-panel'));
      $switchTabUl.next('.switch.panels').find('.panel').hide();
      $targetPanel.show();
      reorder_widget_instruction();
      if(autofocus == true){
        if($targetPanel.find('span.screen-reader-only.position-absolute').length){
          $targetPanel.focus();
        }else{
          $targetPanel.prepend('<span class="screen-reader-only position-absolute">&nbsp;</span>').focus();
        }
      }
      $switchTabUl.find('li').removeClass('active');
      $switchTab.addClass('active');
      if($switchTab.attr('tabindex') !== undefined && $switchTab.attr('aria-selected') !== undefined){
        $switchTabUl.find('li').attr({'tabindex': -1, 'aria-selected': false});
        $switchTab.attr({'tabindex': 0, 'aria-selected': true});
        $switchTabUl.find('li').removeAttr('aria-describedby');
      }
    }
  };

  $.postUiState = function(targetDomId, stateMeth) {
    var $target = $(targetDomId)
    var scope = $target.closest('.panel_container').attr('id')
    if(! $target.hasClass('transitory')) {
      if ( scope && stateMeth && targetDomId ) {
        var scopeParam = 'scope=' + scope;
        var domIdParam = 'dom_id=' + targetDomId;
        var domClass  = 'state=' + stateMeth;
        $.post("/ui_state/add", [scopeParam, domIdParam, domClass].join("&") );
      } else {
        if ( console && console.log ) {
          console.log("Bad attempt to post state", targetDomId, $target)
        }
      }
    }

  };

})(jQuery);
