var report_configuration = {
    templates: "",
    templates_by_type: "",
    type_string: "",
    list_options: "",
    all_options: "",
    parent_templates_hash: {},
    filter_templates_hash: {},
    parent_report_identifier: "",
    filter_identifier: "",
    post_filter_drop_down_labels: {}
};

window.initializeReports = function(config){
  $.extend(report_configuration,config);

  $('#report_report_template_identifier').on('change', function() {
    updateOptionalFilter();
    updateListSelect();
  })

  $('#report_report_template_type').on('change', function() {
    addReportFormatSelect();
    showOrHideComparison();
    updateOptionalFilter();
  })

  $('#report_report_comparison_type').on("change", function() {
    updateOrganizationSelect();
  })

  updateOrganizationSelect();
  addReportFormatSelect();
  updateListSelect();
  updateOptionalFilter();
  setFilter();
  showOrHideComparison();
}

function selectedReportTemplate() {
  var template_identifier = $("#report_report_template_identifier").val();
  return _.find(report_configuration.templates, function(template) {
    return template['identifier'] == template_identifier });
}

function updateListSelect(){
  var list_select_box = $("#export_list_select_box")
  if (report_configuration.parent_templates_hash[$('#report_report_template_identifier').val()] == 0) {
     list_select_box.html(report_configuration.all_options)
  } else {
     list_select_box.html(report_configuration.list_options)
  }
}

function addReportFormatSelect() {
  var template_type = $('#report_report_template_type').val()
  var parent = report_configuration.parent_report_identifier
  var selected_option = parent ? parent : report_configuration.type_string;
  var reports_by_type = report_configuration.templates_by_type[template_type];
  var report_format_options = $('#report_report_template_identifier');

  var options_string = $.map( reports_by_type, function(n) {
    if (selected_option == n.identifier) {
      var option_string = "<option value=" + n.identifier + " selected=\"selected\">" + n.display_name + "</option>";
    } else {
      var option_string = "<option value=" + n.identifier + ">" + n.display_name + "</option>";
    }
    return (option_string)}).join("");

  report_format_options.html(options_string);
}

function updateOptionalFilter(){
  var report           = $('#report_report_template_identifier');
  var report_name      = report.val();
  var filter           = $('#report_report_filter');
  var filter_templates = report_configuration.filter_templates_hash[report_name];
  var filter_options   = $('.filter_options');

  $('#report_filter_post_filter_drop_down_label').text(report_configuration.post_filter_drop_down_labels[report_name] || '');

  var options          = "";
  var length = (filter_templates ? filter_templates.length : 0);

  if(filter_templates) {
    filter_templates.sort();
  }

  for (var i = 0;i < length; ++i) {
    var details = filter_templates[i].split(":")
    options += "<option value='" + details[1] + "'>" + details[0] + "</option>"
  }
  filter.html(options);
  if (filter_templates && length > 1){
    report.attr('name',"parent_report[report_template_identifier]")
    filter.attr('name',"report[report_template_identifier]")
    filter_options.show();
  } else {
    report.attr("name","report[report_template_identifier]")
    filter.attr("name","child_report[report_template_identifier]")
    filter.attr("name","filter_report[report_template_identifier]")
    filter_options.hide();
  }
}

function setFilter() {
  if(report_configuration.filter_identifier != "") {
    $('#report_report_filter').val(report_configuration.filter_identifier)
    $("#report_report_filter option[value='" + report_configuration.filter_identifier + "']").attr('selected', true)
  }
}

function showOrHideComparison() {
  var report_template = selectedReportTemplate();
  var comparison_options = $('.comparison_report_options');
  var isComparisonReportSelected = report_template && report_template['comparison_report?'];
  if (isComparisonReportSelected) {
    comparison_options.show();
  } else {
    comparison_options.hide();
  }
}

function updateOrganizationSelect() {
  if ($('#report_report_comparison_type').val() === 'organizations') {
    $('#select_organizations').show()
  } else {
    $('#select_organizations').hide()
  }
}
