WEBADMIT.populateDropdown = function(selector, items, options) {
  options = options || {};
  var dropdown = $(selector);
  var currentValue = dropdown.val();
  var dropOptions = [];
  var nameAttr = options.nameAttr ? options.nameAttr : "name";
  var defaultValue = options.default ? options.default.id : "";
  var defaultText = options.default ? options.default[nameAttr] : "";

  dropdown.empty();

  dropOptions.push("<option value='" + defaultValue + "'>" + defaultText + "</option>");

  $.each(items, function(i, item) {
    dropOptions.push("<option value='" + item.id + "'>" + item[nameAttr] + "</option>");
  });

  dropdown.append(dropOptions.join(""));
  dropdown.val(currentValue);
};
