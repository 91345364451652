// -- ScheduleInterview.ApplicantGatewayModal ^
ScheduleInterview.ApplicantGatewayModal = function (applicantId, programId, method, url) {
  ScheduleInterview.Modal.call(
    this,
    {
      applicantId: applicantId,
      programIds: [programId],
      method: method,
      url: url,
      interviewTypeOptions: { hide_full: 1 }
    });

  this.getViewBuilder = function () {
    return (this.viewBuilder || (this.viewBuilder = new ScheduleInterview.ViewBuilder(
      {
        viewSections: [new ScheduleInterview.InterviewTypeSection],
        interviewTypes: this.interviewTypes
      })));
  };

  this.getSubmitOptions = function () {
    var __ = this;
    return $.extend(
      __.defaultSubmitOptions(),
      {
        httpMethod: function () { return __.options.method },
        url: function () {
          return __.options.url;
        },
        data: function () { return __.modal.find('form').serialize(); },
        onSuccess: function (data, status, xhr) {
          if (data.error != undefined) {
            alert(data.error)
          } else {
            var selector = "#applicant_gateway_activities_" + data.program_id + " .applicant_gateway_activity_" + data.tag;
            $(selector).replaceWith(data.html);
            $(selector).effect('highlight', { color: '#dfd' }, 2000);
            if (data.has_date) {
              $(selector).find('.customize_expiration_date .expiration_date_picker').focus();
            } else {
              setTimeout(function () { $(selector).find('.activity_action .activity_action_button').focus() }, 200);
            }
          }
          return true;
        }
      }
    );
  };
};
// -- ScheduleInterview.ApplicantGatewayModal $
