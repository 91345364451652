const ApplicantGateway = {};

export default ApplicantGateway

function onSuccess (data) {
  if (data.error != undefined) {
    alert(data.error)
  } else {
    var selector = "#applicant_gateway_activities_" + data.program_id + " .applicant_gateway_activity_" + data.tag;
    $(selector).replaceWith(data.html);
    $(selector).effect('highlight', {color: '#dfd'}, 2000);
    if (data.has_date) {
      $(selector).find('.customize_expiration_date .expiration_date_picker').focus();
    } else {
      setTimeout(function(){ $(selector).find('.activity_action .activity_action_button').focus() }, 200);
    }
  }
}

ApplicantGateway.refreshApplicantActivity = function(form) {
  $.post(form.attr('action'), form.serializeArray(), onSuccess, 'json')
}

$(document).ready(function() {
  $(document).on('click', '.expiration_date_picker', function () {
    var picker = $(this);
    var minDate = $('.expiration_date_picker').data('mindate');
    var targetRow = picker.closest('tr');
    picker.append('<div class="picker"></div>');
    var dialog = $('.picker').dialog({
    modal: true,
    title: 'Select a date',
    autoResize: true,
    draggable: true,
    closeOnEscape: false,
    open: function(event, ui) {
      $('.ui-widget-overlay').on('click', function(event,ui) {
        $('.picker').dialog('close');
      });
      $(this).datepicker({ dateFormat: 'mm/dd/yy', minDate:  minDate , inline: false,
      onSelect: function(selectedDate){
        targetRow.find('.expiration_date_picker').val(selectedDate);
        ApplicantGateway.refreshApplicantActivity(picker.parent())
        $(".picker").dialog('close');
      }}).datepicker( 'show' );
    },
    width: 'auto',
    height: 'auto',
    overflow: 'none',
    close: function(event, ui) {
      $(this).closest('.ui-dialog').remove();
      $(this).remove();
    }
    });
  });

  $(document).on('change', '#applicant_gateway_panel .expiration_date_picker', function (e) {
    if (isDate($(this).val())) {
      ApplicantGateway.refreshApplicantActivity($(this).parent())
    }
  })

  $(document).on('ajax:success', '.activity_action form', function (e) {
    onSuccess(e.originalEvent.detail[0])
  })
});
