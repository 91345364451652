document.addEventListener('jquery-loaded', function() {
  $(document).ready(function () {
    $('#primary_actions').on('click', '#bulk_access', function (e) {
      e.preventDefault();
      var bulk_access_link_dialogNode = $('<div id="import_bulk_access_links_dialog"></div>').html('<div class="loading"></div>').load(this.href);
      var bulkaccessdialog = bulk_access_link_dialogNode.dialog({
        height: 450,
        autoOpen: false,
        modal: true,
        width: 400,
        title: 'Request Access Links from .CSV',
        close: function(event, ui) {
            $(this).dialog('close');
            $(this).remove();
        }
      });
      bulkaccessdialog.parent().css('overflow', 'hidden');
      bulkaccessdialog.dialog('open');
    });

    $(document).on('click', '#cancel_bulk_access_links_imports_form', function(e){
      e.preventDefault();
      $('#import_bulk_access_links_dialog').dialog('close');
    });

    $(document).on('change', '#bulk_access_links_upload',function() {
      if($('input#bulk_access_links_upload')[0].files.length > 0) {
        $('#import_bulk_access_links_dialog li').addClass('bulk_access_submit');
      }else{
        $('#import_bulk_access_links_dialog li').removeClass('bulk_access_submit');
      }
    });

    $(document).on('click', '.submit_form_bulk_request', function(e){
      e.preventDefault();
      var data = new FormData();
      jQuery.each($('input#bulk_access_links_upload')[0].files, function(i, file) {
        data.append('file-'+i, file);
      });

      $.ajax({
          url: '/residency_applicants/validate_csv_data',
          dataType:'json',
          contentType: false,
          processData: false,
          data: data,
          method: 'POST',
          success: function (data){
            console.log('SUCCESS!!');
            var r = confirm(data);

            if (r === false) {
              e.preventDefault();
              return false;
            } else {
              $('#bulk_access_links_upload').submit();
            }
          },
          error: function () {
            console.log('FAILURE');
          },
      });
    });
  });
});
