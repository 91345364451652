/* jquery-detail_table.js */

/* Author: Bill Chapman
   Desc:  Provide additional details when clicking on a cell in a table
   Conventions:  Public accessible methods and hash members are camel cased,  Locally scoped should be underscored
   Usage:
     This implementation expects to be called on a table cell (td). It traverses the parent table
     in order to add and remove the necessary rows.

     $('.detailCellClass').detailCells(
         activeClass :  ""  // This is the className of the active cell and detail cell
         staticClass : ""   // all initialized cells with get this class
         singleRecord: [true|false] //decide if more than one row can be active at a given time
         stackable: [true|false] //decide if more than one cell can be active at a givem time
     );

   Default Styles used for development:
     .detail_selected{ background-color: khaki; cursor: pointer; }
*/

//Should allow noConflict compatibility by locally
; (function($) {
  //expects a single table cell
  $.fn.detailCell = function(options) {

    // all options are initialized here for
    // reference, options provided externally will take precidence
    options = $.extend( {
       activeClass:   "detail_selected",
       staticClass:   "detail_table_cell",
       singleRecord: true,
       stackable: false,
       show:  false
    }, options || {});

    //we needed a non user defineable class for active detail rows
    var STATIC_ACTIVE_CLASS = "detail_table_active_class";

    var cell           = $(this);   //the table cell
    var cell_row       = cell.parents('tr:first');
    var cell_siblings  = cell.siblings('td');  //the row in which we reside
    var row_siblings   = cell_row.siblings('tr');
    var row_cell_count = cell_siblings.length + 1;  //how many cells are in the tbody
    var detail_row     = $("<tr>");
    var detail_cell    = $("<td colspan='" + row_cell_count + "'/>");
    var url            = cell.attr("data-url") || false;
    var content        = cell.attr("data-content") || "Loading Details ... ";
    var default_class  = cell.attr("class") + " " + options.staticClass;

    cell.addClass(options.staticClass);
    detail_row.append(detail_cell);
    detail_cell.addClass(options.activeClass);
    detail_cell.html(content);

    //close all open rows
    var clean_open_rows = function(){
      row_siblings.each(function(){
        $(this).children("td").each(function(){
          if($(this).hasClass(STATIC_ACTIVE_CLASS)){
            $(this).click();
          }
        });
      });
    }

    //close all open cells
    var clean_open_cells = function(){
      cell_siblings.each(function(){
        if($(this).hasClass(STATIC_ACTIVE_CLASS)){
          $(this).click();
        }
      });
    }

    //changed style and show details row
    var show_details = function (){
      if(options.stackable == false){
        clean_open_cells();
      }

      if(options.singleRecord == true){
        clean_open_rows();
      }

      cell.addClass(options.activeClass);
      cell.addClass(STATIC_ACTIVE_CLASS);
      cell_row.after(detail_row);

      if ( options.url != false){
        detail_cell.load(options.url);
        // check if data-content has changed
      }  else {
        detail_cell.html(cell.attr('data-content'));
      }
    }

    //remove styles destroy details row
    var hide_details = function(){
      cell.attr("class",default_class);
      detail_row.remove();
    }

    if (options.show) {
      show_details();
      $(this).toggle(hide_details, show_details);
    } else {
      $(this).toggle(show_details, hide_details);
    }

  };// end detailCell extension

  //expects a collection of cells
  $.fn.detailCells = function(options) {
    $(this).each(function(){
    $(this).detailCell(options);
    });
  }
})(jQuery);
