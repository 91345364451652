$(document).ready(function() {
  $('a.clear_job_controller_link').click(function(event) {
    var newDialogContent = $('<div data-role="batch-status-update-dialog"></div>', {id: 'global_update_dialog'});
    if (!$('.clear_job_controller_form').is(':visible')) {
      newDialogContent.load(this.href);
      newDialogContent.dialog({
        width: 600,
        position: { my: 'top', at: 'top' },
        title: $(this).text(),
        close: function() { $(this).closest('.ui-dialog').remove(); }
      });
    }
    event.preventDefault();
  });
});
