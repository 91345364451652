WEBADMIT.autosaveDesignationStatus = {};

;(function ($, WEBADMIT) {
  WEBADMIT.autosaveDesignationStatus.triggerLegacyDesignationAjax = function (localStatusSelect) {
    var updater = new WEBADMIT.Designations.LocalStatusUpdater(localStatusSelect);
    updater.update();
  };

  WEBADMIT.autosaveDesignationStatus.getAdditionalDetails = function (localStatusSelect) {
    var designationId = localStatusSelect.data('designation-id');
    var applicantId = localStatusSelect.data('applicant-id');
    var selectedDecision = localStatusSelect.find(':selected').data('decision');
    var hasDecision = selectedDecision != 'none';
    var actionUrl = applicantId + '/designations/' + designationId + '/additional_designation_details/edit';
    var isNewDecision = localStatusSelect.data('current-decision') != selectedDecision;
    var isBlank = localStatusSelect.find(':selected').val() === '';

    if (hasDecision === true && isNewDecision === true && isBlank != true) {
      return $.ajax({
        url: actionUrl,
        success: function (html) {
          new WEBADMIT.DesignationAdditionalDetailsModal(designationId, applicantId, localStatusSelect.data('local-status-id'), html).show();
        },
        data: { designation: { local_status_id: localStatusSelect.val() } }
      });
    } else {
      WEBADMIT.autosaveDesignationStatus.triggerLegacyDesignationAjax(localStatusSelect);
    }
  };
})(jQuery, WEBADMIT);

$(document).ready(function () {
  var designationsPane = $('#async_content_designations_pane');

  designationsPane.on('change', '.autosave_designation_additional_details', function () {
    WEBADMIT.autosaveDesignationStatus.getAdditionalDetails($(this));
  });
});
