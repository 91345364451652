ScheduleInterview.SlotSection = function() {
  ScheduleInterview.ViewSection.call(this);
  this.render = function(options) {
    return { hasSlots: this.buildListView(
      'slots',
      options.interviewType.slots,
      {
        selectedId: (this.hasPath(options, 'interview', 'slot_id') ? options.interview.slot_id : false),
        selectedName: 'checked',
        resultBuilder: function (value) {
          return {
            selected: value.selected,
            id: value.id,
            date: value.date,
            time: value.time,
            seatsOpen: (value.seats_remaining == null ? 'N/A' : value.seats_remaining)
          };
        }
      }
    )};
  };

  this.bind = function(context) {
    this.context = context;
    var __ = this;
    this.context.modal.find('form input[name="assignment[interview_slot_id]"]').on(
      'click',
      function(event) { __.onSlotClicked(event); }
    );
  };

  this.onSlotClicked = function(event) {
    var current_interview = this.context.viewBuilder.interview;
    var interview = {
      id: (current_interview ? current_interview.id : false),
      assignment_type: { id: parseInt(this.context.modal.find('.interview_type_select').val()) },
      location: { location_id: parseInt(this.context.modal.find('.location_select').val()) },
      user_identity: { id: false },
      slot_id: parseInt(event.target.value)
    };
    this.context.openModal({interview: interview}, 'useCache');
    $('#interview_slot_id_' + interview['slot_id']).focus();
  };

};
ScheduleInterview.SlotSection.prototype = Object.create(ScheduleInterview.ViewSection.prototype);
ScheduleInterview.SlotSection.prototype.constructor = ScheduleInterview.SlotSection;
