(function ($) {
  $.loadingDialog = {
    show: function (title) {
      if (!title) {
        title = 'Loading...';
      }
      var $dialog = $('#loading-dialog-modal');
      if ($dialog.length == 0) {
        $dialog = $('<div id="loading-dialog-modal" class="async-load pending"></div>');
        $dialog.dialog({
          classes: {
            'ui-dialog': 'no-close'
          },
          resizable: false,
          closeOnEscape: false,
          modal: true,
          title: title
        });
      }
      return $dialog;
    },
    hide: function () {
      var $dialog = $('#loading-dialog-modal');
      if ($dialog.length > 0) {
        $dialog.dialog('close');
        $dialog.remove();
      }
    }
  };
}(jQuery));
