WEBADMIT.ListRefresh = function ListRefresh(list, listId, createUri, listsUri, waitingInterval, location) {
  this.list = list;
  this.listId = listId;
  this.progressBar = $('.list-view-progress.inprogress');
  this.createUri = createUri;
  this.listsUri = listsUri;
  this.waitingInterval= waitingInterval;
  this.location = location || window.location;
};

WEBADMIT.ListRefresh.prototype = {
  ready: function() {
    this.progressBar.show();
    this.generateList();
    this.waitingReminder();
  },

  generateList: function() {
    var that = this;
    $.post(this.createUri)
      .done( function() {
        that.list.refreshStatus();
      })
      .fail( function() {
        alert("An error occured and the list could not be generated");
        that.listError();
      });
  },

  listError: function() {
    this.location.assign(this.listsUri);
  },

  waitingReminder: function() {
    var that = this;
    var span = that.progressBar.find('span');
    setTimeout( function() {
      span.text("Please do not refresh the page, your list is still processing");
      span.css('color', that.randomColor());
      that.waitingReminder();
    }, this.waitingInterval);
  },

  randomColor: function() {
    var index = Math.floor(Math.random() * 5);
    var colors = ['#4b7bc2', '#ef0727', '#226904', '#000000', '#e80000'];
    return colors[index];
  },
};
