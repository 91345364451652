// -- ScheduleInterview.BatchModal ^
ScheduleInterview.BatchModal = function() {
  ScheduleInterview.Modal.call(this, { title: 'Interview Checked Applicants' });

  this.onSuccess = function (data, status, xhr) {
    PageUtils.jsonFlashMessage(xhr);
    return true;
  };

  this.getViewBuilder = function() {
    return (this.viewBuilder || (this.viewBuilder = new ScheduleInterview.ViewBuilder(
      {
	viewSections: [
	  new ScheduleInterview.SlotSection(),
	  new ScheduleInterview.InterviewerSection(),
	  new ScheduleInterview.LocationSection(),
	  new ScheduleInterview.InterviewTypeSection(),
	  new ScheduleInterview.ProgramSection()
	],
	interviewTypes: this.interviewTypes
      })));
  };

  this.getSubmitOptions = function() {
    var __ = this;
    return $.extend(
      __.defaultSubmitOptions(),
      {
        beforeSubmit: function () {
          __.modal.find('form select.interviewer_select option[value="none"]').remove();
      	  __.modal.find('form').selectable_submit();
      	  $('<input name="utf8" type="hidden" value="✓">').appendTo(__.modal.find('form'));
      	  $('<input name="_method" type="hidden" value="put">').appendTo(__.modal.find('form'));
      	},
        httpMethod: function() { return __.modal.find('form').attr('method'); },
        url: function() { return $('a.interview_applicants_link').attr('data-submit_url'); },
        data: function () { return __.modal.find('form').serialize(); },
        onSuccess: function (data, status, xhr) { return __.onSuccess(data, status, xhr); },
	dataType: function () { return 'html'; }
      }
    );
  };
};
ScheduleInterview.BatchModal.prototype = Object.create(ScheduleInterview.Modal.prototype);
ScheduleInterview.BatchModal.prototype.constructor = ScheduleInterview.BatchModal;
// -- ScheduleInterview.BatchModal $
