// -- ScheduleInterview.ViewBuilder ^
ScheduleInterview.ViewBuilder = function(viewOptions) {
  this.viewOptions = viewOptions || {};
  this.viewSections = this.viewOptions.viewSections || [];
  this.interviewTypes = this.viewOptions.interviewTypes;

  this.modalViewTemplate = {
    applicant: false,
    interview: false,
    editRecord: false,
    userIdentityFieldName: '',
    hasAssignmentTypes: false,
    hasLocations: false,
    hasUserIdentities: false,
    hasSlots: false,
    hasPrograms: false
  };

  this.combineViewSections = function(options) {
    var modalView = $.extend({}, this.modalViewTemplate);
    for (var index = 0; index < this.viewSections.length; ++index) {
      var section = this.viewSections[index];
      $.extend(modalView, section.render(options));
    }
    return modalView;
  };

  this.buildView = function(renderData) {
    renderData = renderData || {};
    var interviewTypeIndex = 0;
    var interviewType = this.interviewTypes[interviewTypeIndex];
    var editRecord = false;

    if (renderData.interview) {
      this.interview = renderData.interview;
      interviewTypeIndex = this.getInterviewTypeIndex(this.interview.assignment_type.id);
      interviewType = this.interviewTypes[interviewTypeIndex];
      if (typeof this.interview.id == 'number') editRecord = {id: this.interview.id};
    }
    if (interviewType && interviewType.hasNone != true) {
      interviewType.hasNone = true;
      if (interviewType.user_identities instanceof Array) interviewType.user_identities.unshift({id: 'none', name: 'None Assigned'});
      if (interviewType.locations instanceof Array) interviewType.locations.unshift({location_id: 'none', name: 'No Location Assigned'});
    }

    var modalView = this.combineViewSections({
      applicantId: this.viewOptions.applicantId,
      interview: this.interview,
      interviewType: interviewType,
      editRecord: editRecord,
      interviewTypes: this.viewOptions.interviewTypes,
      activityTypes: this.viewOptions.activityTypes,
      activityType: renderData.activityType,
      programs: this.viewOptions.programs
    });

    return modalView;
  };

  this.bindSections = function(context) {
    for (var ix = 0;ix < this.viewSections.length; ++ix) {
      var section = this.viewSections[ix];
      section.bind(context);
    }
  };

  this.getInterviewTypeIndex = function(interviewTypeId) {
    for (var index = 0; index < this.interviewTypes.length; ++index) {
      var interviewType = this.interviewTypes[index];
      if (interviewType.id == interviewTypeId) return index;
    }
    console.log('returning out of bounds index from ScheduleInterview.ViewBuilder.getInterviewTypeIndex');
    return -1;
  };
};
// -- ScheduleInterview.ViewBuilder $
