// Editing Point Tables
// Used in: /scoring/point_tables/*
//
//   ScoringPointTable.setup(pointTableElement)
//
var ScoringPointTableEntryTable = {}
; (function(){

  var $element
  var self = this
  var arrow = "&#8593;"

  var bindHandlers = function(){
    $element.on('change', '.less_than_value', function(event){
      updateRangeValues()
      event.stopPropagation()
      return false
    })
  }

  var updateMaxValue = function(){
    var list = []
    $('.less_than_value').each(function(){
      var val = Number($(this).val());
      list.push(val);
    })
    var maximum = Math.max.apply(Math, list)
    $('#maximum_value').html(maximum == 0 ? arrow : maximum).effect("highlight", {}, 2000);
    $('#scoring_point_table_upper_limit_score').attr('aria-label', 'Input condition greater than or equal to ' + maximum + ' equals a score of');
  }

  var updateRangeValues = function(){
    if(pointTableType() != 'range')
      return;
    // hide the first greater than clause
    $('.greater_than').first().hide()
    $('.delete_entry_link').first().hide()

    // when a less than value changes,
    // update the greater than value of the next row
    // to the same value
    $('.less_than_value').each(function(index) {
      if (isNaN(Number($(this).val()))) {
        return;
      }
      var nextTr = $(this).closest('tr').nextAll('tr:first')
      var newValue = ($(this).val() == "") ? arrow : $(this).val()
      var nextGreaterThan = nextTr.find('.greater_than_value')
      var oldValue = nextGreaterThan.html()
      var screeValue = newValue == arrow ? 'above input condition' : newValue
      if (oldValue != newValue) {
        nextGreaterThan.html(newValue)
        var nextInput = nextTr.find('.less_than_value')
        nextInput.attr('data-label', 'Input condition greater than or equal to ' + screeValue + ' ,less than ' )
        nextInput.attr('aria-label', 'Input condition greater than or equal to ' + screeValue + ' ,less than ' )
        nextGreaterThan.effect("highlight", {}, 2000);
      }
      updatePointLabels($(this), screeValue)
    });

    updateMaxValue();
  }

  var removePointTableEntryRow = function(id) {
    $('#' + id).hide(200, function() {
      $('#' + id).remove();
      updateRangeValues();
      apply_alternate_row_color()
    });
  }

  this.setup = function(element){
    $element = $(element)
    if (this.isElementPresent()) {
      bindHandlers()
    }
    updateRangeValues()
    setupNumericTextInputs()
  }

  this.isElementPresent = function(){
    return $element.length != 0
  }

  this.newPointTableEntry = function(url){
    $.ajax({
      type: "POST",
      async: false,
      url: url,
      data: {},
      success: function(data) {
        var tr = $(data).closest('tr').attr('id');
        if(pointTableType() == 'range') {
          $('#maximum').before(data);
          $element.find('#' + tr).find('input.less_than_value').focus();
        } else {
          $element.append(data);
          $element.find('#' + tr).find('input.pt_input').focus();
        }
        updateRangeValues();
        apply_alternate_row_color();
        setupNumericTextInputs();
      }
    });
  }

  this.destroyPointTableEntry = function(url, id) {
    if (!confirm( "Are you sure you want to delete this entry?"))
    return;

    if(url != '') {
      $.post(url, { '_method': 'delete' }, removePointTableEntryRow);
    }
    else {
      removePointTableEntryRow(id);
    }
    $('.new_point_table_entry').focus();
  }

  var pointTableType = function(){
    return $element.data('point-table-type')
  };

  var setupNumericTextInputs = function() {
    if (typeof POINT_TABLE_ENTRY_SCORE_PRECISION != 'undefined') {
      $(':input[type=number]').numeric({
          precision: POINT_TABLE_ENTRY_SCORE_PRECISION,
          scale: POINT_TABLE_ENTRY_SCORE_SCALE
        }
      );
    }
  };
}).apply(ScoringPointTableEntryTable)

$(function(){
  ScoringPointTableEntryTable.setup('#point-table-entries')
})

window.newPointTableEntry = function(url) {
  ScoringPointTableEntryTable.newPointTableEntry(url)
}

window.destroyPointTableEntry = function(url, id) {
  ScoringPointTableEntryTable.destroyPointTableEntry(url, id)
}

$(document).on('blur', '.pt_input', function(event){
  var value = $(this).val();
  if($(this).hasClass('numeric_input')){
    $(this).closest('tr').find('.point_table_entry_score').attr('aria-label', 'Input condition equals to ' + value + ' equals a score of');
    $(this).closest('tr').find('.delete_point_entry').attr('aria-label', 'Delete input condition equals to ' + value);
  }
  else{
    $(this).closest('tr').find('.point_table_entry_score').attr('aria-label', 'Input condition matches ' + value + ' equals a score of');
    $(this).closest('tr').find('.delete_point_entry').attr('aria-label', 'Delete input condition ' + value);
  }
});

$(document).on('blur', '.less_than_value', function(event){
  updatePointLabels($(this), $(this).val())
});

function updatePointLabels(element, value){
  if(element.attr('data-label') !== undefined){
    element.closest('tr').find('.point_table_entry_score').attr('aria-label', element.attr('data-label') + value + ' equals a score of');
    element.closest('tr').find('.delete_point_entry').attr('aria-label', 'Delete ' + element.attr('data-label') + value);
  }
  else{
    element.closest('tr').find('.point_table_entry_score').attr('aria-label', 'Input condition less than ' + value + ' equals a score of');
    element.closest('tr').find('.delete_point_entry').attr('aria-label', 'Delete input condition less than ' + value);
  }
}

$(document).on('focusin', '.point_table_entry_score', function(event){
  var value = $(this).closest('tr').find('td:first-child').find('input').val();
  if(value == ''){
    $('#condition-status').text('Please add input condition to add your score.');
  }
});

$(document).on('focusin', '.point-submit', function(event){
  $('#point-form-status').text("Point table type can't be changed once table is saved");
});
