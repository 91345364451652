/* jquery.mustache_loader.js */

;(function($) {

  var mustacheTemplateCache = new Object;

  $.mustacheLoader = function(templateName, callback){
    var cachedTemplate = mustacheTemplateCache[templateName];
    var result = false
    if (typeof cachedTemplate == 'string') {
      if (callback) {
        callback(cachedTemplate)
        result = cachedTemplate
      }
    }
    else if (cachedTemplate)
      cachedTemplate.push(callback)
    else {
      mustacheTemplateCache[templateName] = [callback]
      $.get('/templates/' + templateName, function(data) {
        var callbacks = mustacheTemplateCache[templateName]
        for (var i = 0; i < callbacks.length; ++i)
          callbacks[i](data)
        mustacheTemplateCache[templateName] = data
      })
    }
    return result
  };

})( jQuery );
