/* jquery.ordered_table.js */

; ( function($) {
  /* Reorder widget using keyboard */
  function ordered_row_td_val_txt(ordered_row) {
    var td_element = ordered_row.find('td');
    if (td_element.find('input:visible').is('input')){
      var text = td_element.find('input:visible').val();
    }
    else if (td_element.find('h').is('h')) {
      var text = td_element.find('h').text();
    }
    else if (td_element.find('span').is('span')){
      var text = td_element.find('div span').text();
    }
    else{
      var text = td_element.eq(1).text();
    }
    return text;
  }

  function order_update(ordered_row, event, position) {
    event.stopPropagation();
    var table = ordered_row.closest('table');
    var info = {
      ordered_row: ordered_row,
      table: table,
      tbody: ordered_row.parents('tbody:first')
    };
    var next = (position == 'down') ? ordered_row.next().after(ordered_row) : ordered_row.prev().before(ordered_row);
    info.table.trigger('reorder', ordered_row);
    ordered_row.focus();
    apply_alternate_row_color();
    $('.screen-reader-only#reorder-status').text('Successfully moved ' + position + ' ' + ordered_row_td_val_txt(ordered_row));
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
  }

  $(document).on('keydown', 'table.ordered > tbody > tr', function(event) {
    var keycode = event.key
    var ordered_row = $(this);

    if (event.ctrlKey && keycode == '74'){
      order_update(ordered_row, event, 'down');
    }
    else if (event.ctrlKey && keycode == '80'){
      order_update(ordered_row, event, 'up');
    }
  });


  $(document).on('mousedown', 'table.ordered > tbody > tr img.icon.grip', function(event) {
    event.stopPropagation();
    var colspan = 0
    var d = $(document)
    var ordered_row = $(this).closest('tr')
    var offset = ordered_row.offset()
    var table = ordered_row.closest('table')
    var info = {
      ordered_row: ordered_row,
      table: table,
      tbody: ordered_row.parents('tbody:first'),
      y_offset: event.pageY - offset.top
    }
    ordered_row.find('td,th').each(function(j) {
      colspan += this.colSpan
    })
    info.colspan = colspan
    d.on('mousemove', info, on_ordered_row_mousemove)
    d.on('mouseup', info, on_ordered_row_mouseup)
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
  })

  function v_hit_test(x, y, element) {
    var offset = element.offset()
    var top = offset.top
    var bottom = top + element.height()
    if (y < top)
      return -1
    if (y > bottom)
      return 1
    return 0
  }

  function on_ordered_row_click(event) {
    var ordered_row = $(this)
    ordered_row.off('click', on_ordered_row_click)
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
  }

  function on_ordered_row_mousemove(event) {
    var info = event.data
    var ordered_row = info.ordered_row
    var offset = ordered_row.offset()
    var placeholder = info.placeholder
    var tbody = info.tbody
    var tbody_top = tbody.offset().top
    var tbody_bottom = tbody_top + tbody.height() - ordered_row.height()
    var top = event.pageY - info.y_offset
    if (!ordered_row.hasClass('ordered_row_drag')) {
      var placeholder_td
      tbody.find('td').each(function (j) {
        var width
        var cell = $(this)
        width = cell.width()
        cell.width(width)
        cell.width(cell.width() + (width - cell.width()) * 2)
      })
      placeholder_td = $('<td>', {colspan: info.colspan}).append('<div></div>')
      placeholder_td.wrapAll('<tr class="ordered_row_placeholder">')
      placeholder = info.placeholder = placeholder_td.parent()
      ordered_row.before(placeholder)
      placeholder_td.height(ordered_row.height())
      placeholder_td.height(placeholder_td.height() - (placeholder_td.height() - ordered_row.height()) - (placeholder.height() - ordered_row.height()))
      ordered_row.addClass('ordered_row_drag')
      ordered_row.offset({left: 0, top: 0})
      ordered_row.click(on_ordered_row_click)
      ordered_row.mouseout(on_ordered_row_mouseout)
    }
    if (top < tbody_top)
      top = tbody_top
    else if (top > tbody_bottom)
      top = tbody_bottom
    if (v_hit_test(event.pageX, event.pageY, placeholder)) {
      var tbody_hit = v_hit_test(event.pageX, event.pageY, tbody)
      if (tbody_hit == 0) {
        var over
        tbody.children('tr').each(function(i) {
          var tr = $(this)
          if (!tr.hasClass('ordered_row_drag') && !tr.hasClass('ordered_row_placeholder') && (v_hit_test(event.pageX, event.pageY, tr) == 0)) {
            var middle = tr.offset().top + (tr.height() / 2)
            if (event.pageY <= middle)
              tr.before(placeholder).before(ordered_row)
            else
              tr.after(ordered_row).after(placeholder)
            over = tr
            return false
          }
        })
      }
    }
    else if (tbody_hit < 0)
      tbody.prepend(ordered_row).prepend(placeholder)
    else if (tbody_hit > 0)
      tbody.append(placeholder).append(ordered_row)
    ordered_row.offset({left: offset.left, top: top})
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
  }

  function on_ordered_row_mouseout(event) {
    var ordered_row = $(this)
    ordered_row.off('click', on_ordered_row_click)
    ordered_row.off('mouseout', on_ordered_row_mouseout)
    ordered_row.mouseover(on_ordered_row_mouseover)
  }

  function on_ordered_row_mouseover(event) {
    var ordered_row = $(this)
    ordered_row.click(on_ordered_row_click)
    ordered_row.mouseout(on_ordered_row_mouseout)
    ordered_row.off('mouseover', on_ordered_row_mouseover)
  }

  function on_ordered_row_mouseup(event) {
    var info = event.data
    var ordered_row = info.ordered_row
    var placeholder = info.placeholder
    var target = $(event.currentTarget)
    if (ordered_row.hasClass('ordered_row_drag')) {
      ordered_row.removeClass('ordered_row_drag')
      ordered_row.css({left: '', top: ''})
      placeholder.remove()
      info.table.trigger('reorder', ordered_row)
      apply_alternate_row_color()
    }
    target.off('mousemove', on_ordered_row_mousemove)
    target.off('mouseup', on_ordered_row_mouseup)
    ordered_row.off('mouseout', on_ordered_row_mouseout)
    ordered_row.off('mouseover', on_ordered_row_mouseover)
  }
})(jQuery);
