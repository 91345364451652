$(function() {

  function enabledReports() {
    return $('#reports_target');
  }

  function extractReportData(element) {
    return {
      template_type: element.data('report-name'),
      prefix: element.data('prefix'),
      report_name: element.text(),
      report_id: element.data('report-id')
    }
  }

  function removeReport(reportSelector) {
    var containerToRemove = $(reportSelector);
    var reportToReenable = $('#report_' + containerToRemove.attr('data-report-id'));
    reportToReenable.removeClass('taken');
    containerToRemove.remove();
    setupReportLink();
  }

  function setupReportLink() {
    $('.report_link').off().on('click', function() {
      addReportRow($(this));
    });
    $(".report_link.taken").off("click");
  }

  function setupRemoveReportLink() {
    $('.remove_report').off().on('click', function(event) {
      var element = $(this);
      var focusElement =  findfocusableElement(element);
      removeReport("#picked_report_" + element.attr("data-report-id"));
      $('.j-panel-status').text(element.attr('data-report-name') + ' report removed from workgroup.')
      if(focusElement.length > 0){
        $('.reportIconStatus').text($(focusElement).find('a:first').attr('aria-label'))
        setTimeout(function(){ $(focusElement).find('a:first').focus() }, 200);
      }else{
        setTimeout(function(){ $('#all_reports').find('.tree_node_title:first a').focus() }, 200);
      }
    });
  }

  function findfocusableElement(element) {
    var focusId = ''
    if(element.closest('li').next('li').length == 1){
      focusId = element.closest('li').next('li')
    }else if(element.closest('li').prev('li').length == 1){
      focusId = element.closest('li').prev('li')
    }
    return focusId
  }

  function setupAddAllReportLink() {
    $('#all_reports .add').off().on('click', function(e) {
      e.preventDefault();
      var reportName = $(this).closest('.reports_list').find('a.report_link:first').attr('data-report-name')
      $(this).closest('.reports_list').find('a.report_link').each(function(index, field) {
        addReportRow($(field));
      });
      $('.j-panel-status').text('Added all ' + reportName + ' reports to workgroup.')
      event.preventDefault? event.preventDefault() : event.returnValue = false;
    });
  }

  function setupRemoveAllReportLink() {
    $('#all_reports .remove').off().on('click', function(e) {
      e.preventDefault();
      var reportName = $(this).closest('.reports_list').find('a.report_link:first').attr('data-report-name')
      $(this).closest('.reports_list').find('a.report_link').each(function(index, panel) {
        var reportIdentifier = $(panel).attr('data-report-id');
        removeReport("#picked_report_" + reportIdentifier);
      });
      $('.j-panel-status').text('Removed all ' + reportName + ' reports from workgroup.')
      event.preventDefault? event.preventDefault() : event.returnValue = false;
    });
  }

  function rowTaken(element) {
    var elementClass = element.attr('class')
    return (elementClass.search("taken") !== -1)
  }

  function orderedReportRowInsert(content) {
    var myArray = $('.chosen_report_item');
    var contentCategoryLabel = findReportCategoryLabel(content);
    var contentSubcategoryLabel = findReportSubcategoryLabel(content);
    var inserted = false;

    for (var i = 0; i < myArray.length; i++) {
      if ((findReportCategoryLabel(myArray[i]) >= contentCategoryLabel) && (findReportSubcategoryLabel(myArray[i]) > contentSubcategoryLabel)) {
        $(content).insertBefore(myArray[i])
        inserted = true;
        i = myArray.length;
      }
    }

    if (!inserted) {
      enabledReports().append(content);
    }
  }

  function findReportCategoryLabel(element) {
    return ($(element).find(".category_label").text().trim())
  }

  function findReportSubcategoryLabel(element) {
    return ($(element).find(".field").text().trim())
  }

  function addReportRow(element) {
    if (rowTaken(element) == false) {
      $.mustacheLoader('report_row', function(template) {
        var content = $($.mustache(template, extractReportData(element)));
        orderedReportRowInsert(content);
        setupRemoveReportLink();
      });

      element.addClass('taken');
      $('.j-panel-status').text(element.text() + ' report added to workgroup.')
      element.off('click');
    }
  }

  $(document).ready(function() {
    setupReportLink();
    setupRemoveReportLink();
    setupAddAllReportLink();
    setupRemoveAllReportLink();
  });
});
