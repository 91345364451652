/* assignments.js */

function click_assignment_slot(self, unavailable_ids) {
  var select = $('#assignment_user_identity_id')
  var unavailable = {}
  var user_id = select.val()
  $.each(unavailable_ids, function(i) {unavailable[this] = true})
  $('#assignment_user_identity_id option').each(function(i) {
    var self = $(this)
    var value = self.val()
    if (value in unavailable) {
      if (user_id == value)
        select.val('none')
      self.attr('disabled', true)
    }
    else
      self.attr('disabled', false)
  })
}

window.show_assignment_dialog = function (self, assignment) {
  var form = $('#assignment_form')
  var method = form.find('input[name="_method"]')
  var url = '/' + assignment.type + 's'
  form.data('editing_row', $(self).closest('tr'))
  if ('id' in assignment) {
    form.attr('action', url + '/' + assignment.id)
    method.attr('disabled', false)
  }
  else {
    form.attr('action', url)
    method.attr('disabled', true)
  }
  $('#assignment_complete').val(assignment.complete)
  $('#assignment_due_date').val(assignment.due_date || '')
  form.find('.datepicker').datepicker()
  $.get('/' + assignment.type + '_types', {'program_ids[]': program_ids},
      function(data, textStatus, jqXHR) {
    var select = $('#assignment_assignment_type_base_id')
    select.empty()
    $.each(data, function(i) {
      var option = $('<option value="' + this.id + '">' + this.name + '</option>')
      option.data('assignment_type', this)
      select.append(option)
    })
    if ('id' in assignment.assignment_type)
      select.val(assignment.assignment_type.id)
    else
      select[0].selectedIndex = 0
    update_assignment_form(assignment, function() {
      var dialog = $('#assignment_form').dialog({
        autoOpen: false,
        draggable: true,
        resizable: true,
        position: { my: "top", at: "top+150" },
        title: assignment.assignment_type.name,
        minWidth: 300,
        maxWidth: 700,
        overflow: 'scroll'
      })
      dialog.dialog('open')
      dialog.parent().css('overflow', 'scroll');
      initializeToggleCheckboxes()
    })
  }, 'json')
  return false
}

window.update_assignment_form = function(assignment, callback) {
  var assignment_type = $('#assignment_assignment_type_base_id option:selected').
      data('assignment_type')
  if (assignment_type) {
    var due_date
    var location_name
    var program_ids = {}
    var slot_id
    var unavailable_ids
    var user_identity_id
    $('#assignment_type_base_id_label').text(assignment_type.use_schedule ? 'Schedule' : 'Assign');
    $('#assignment_assignment_type_base_id').attr('aria-label', 'Select assignment type');
    if (assignment) {
      assignment_type.due_date = assignment.parseable_due_date
      assignment_type.edit_record = assignment.edit_record || false;
      location_name = assignment.location ? assignment.location.name : null
      slot_id = assignment.slot_id
      user_identity_id = assignment.user_identity ? assignment.user_identity.id : null
    }
    else {
      assignment_type.due_date = $('#assignment_due_date').val()
      location_name = $('#assignment_location_id option:selected').text()
      user_identity_id = $('#assignment_user_identity_id').val()
      $('#assignment_program_ids input[name="program_ids[]"]').each(function (i, checkbox) {
        if (checkbox.checked)
          program_ids[checkbox.value] = true
      })
    }
    if (assignment_type.dates) {
      $.each(assignment_type.dates, function(i) {
        $.each(this.slots, function(j) {
          var selected = this.slot_id == slot_id
          this.slot_selected = selected
          if (selected) {
            unavailable_ids = {}
            $.each(this.unavailable_ids, function(k) {
              unavailable_ids[this] = true
            })
          }
        })
      })
    }
    if (assignment_type.locations) {
      $.each(assignment_type.locations, function(i) {
        this.selected = this.name == location_name
      })
    }
    $.each(assignment_type.programs, function(i) {
      this.selected = this.id in program_ids
    })
    $.each(assignment_type.user_identities, function(i) {
      this.selected = this.id == user_identity_id
      this.unavailable = unavailable_ids && this.id in unavailable_ids
    })
    $.mustacheLoader('assignment_options', function(template) {
      if (assignment_type['edit_record'] == undefined) {
        var edit_record = ($('#assignment_user_identity_id').hasClass('select_edit_assignment') || false && true);
	// merge 'edit_record' into the assignment type returned by the server
        assignment_type = jQuery.extend(assignment_type, {"edit_record": edit_record});
      }
      var assignment_options = $('#assignment_options').empty();
      $($.mustache(template, assignment_type)).appendTo(assignment_options);
      assignment_options.find('.datepicker').datepicker();
      if (callback) callback();
      if (assignment_type['edit_record'] !== true) {
        var data = {
          userIdentities: assignment_type.user_identities,
          selectableIds: assignment_type.user_identities.filter(function (ui) {
            return !ui.disabled;
          }).map(function (ui) {
            return ui.id;
          }),
          selectedIds: assignment_type.user_identities.filter(function (ui) {
            return ui.selected;
          }).map(function (ui) {
            return ui.id;
          })
        };
        Vue.createApp({ data() { return data; } })
           .component('multi-select-checkbox', MultiSelectCheckboxComponent)
           .mount($('#assignment-user-identities')[0]);
      };
    })
  }
}

window.show_assignment_detail_dialog = function(self, e, title) {
  var assignment_form_id = "#" + $(self.parentNode).attr('id') + "_form"
  var assignment_form_div = "#" + $(self.parentNode).attr('id')
  var dialog = $(assignment_form_id).dialog({
    beforeclose: function(event, ui) {
      for(i = 0; i < formValues.length; i++) {
        $( '#' + formValues[i].id ).val(formValues[i].value);
      }
      $('ul.errors li').remove();
      formValues = [];
    },
    autoOpen: false,
    autoResize: true,
    draggable: true,
    resizable: false,
    modal: false,
    open: function(event, ui) {
      var dsId = '';
      var dsValue = '';

      var dialogBody = $(assignment_form_id)
      var dialogForm = dialogBody.find('form.complete_assignment')
      var assignmentId = assignment_form_id.match(/[0-9]+/)[0]

      if ($('.assignment_detail_row_' + assignmentId).closest('table').attr('id') == 'assignment_table') {
        $.get(dialogForm.attr('action'), {}, function (data) {
          dialogForm.replaceWith($(data).find('form.complete_assignment'))
        })
      }

      $( "input" ).each(function( index ) {
        dsId = $(this).attr('id');
        dsValue = $(this).val();
        formValues.push( { id: dsId, value: dsValue } );
      });

      $( "textarea" ).each(function( index ) {
        dsId = $(this).attr('id');
        dsValue = $(this).val();
        formValues.push( { id: dsId, value: dsValue } );
      });
      setTimeout(function() {
        $('#' + event.target.id).find(':focusable').eq(1).focus();
      }, 500);
    },
    title: title,
    width: 'auto',
    height: 'auto',
    overflow: 'none'
  })

  dialog.resizable({
    start: function(event, ui) {
      dialog.css({
        position: "relative !important",
        top: "0 !important",
        left: "0 !important"
      });
    },
    stop: function(event, ui) {
      dialog.css({
        position: "",
        top: "",
        left: ""
      });
    }
  })

  $('.ui-dialog-content').dialog('close');
  dialog.dialog('open');
  dialog.parent().appendTo( $(assignment_form_div) )
  return false
}

jQuery(function() {
  window.formValues = [];
  $(document).on('change', '#assignment_assignment_type_base_id', function(event) {
    update_assignment_form(null, initializeToggleCheckboxes)
  })

  $(document).on('click', '.assignment_times .date a.icon_link', function(event) {
    $(this).closest('tr').remove()
    apply_alternate_row_color()
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
  })

  $(document).on('click', '.assignment_times .time a.icon_link', function(event) {
    var self = $(this)
    var th = self.closest('th')
    var column = th.index()
    self.closest('table').find('tbody tr').each(function(i) {
      $(this).children('td:eq(' + column + ')').remove()
    })
    th.remove()
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
  })

  $(document).on('click', '#cancel_assignment_form', function(event) {
    $('#assignment_form').dialog('close')
  })

  $(document).on('click', '.delete_assignment', function(event) {
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
    if (confirm('Are you sure you want to delete this?')) {
      var link = $(this)
      $.post(link.attr('href'), '_method=delete', function(data, text_status, xml_http_request) {
        var tbody = link.closest('tbody')
        link.closest('tr').remove();
        if (tbody.children().length){
          apply_alternate_row_color();
          tbody.find('a.action:first').focus();
        }
        else{
          tbody.closest('.state_pane').find('ul.buttons a:first').focus();
          tbody.closest('table').hide();
        }
      })
    }
  })

  /*
   * TODO: The submit button on the batch assignment dialog is not a child of
   *       the assignment form, so it can't tell which form to submit without a
   *       special event handler. There should be a way to move that button back
   *       into the form, where it belongs.
   */
  $(document).on('click', '#global_designations_controls .submit_form_link',
      function(event) {
    $('#assignment_form').submit()
  })

  $(document).on('click', '.reactivate_assignment', function(event) {
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
    if (confirm('Reactivate this assignment?')) {
      var link = $(this)
      $.post(link.attr('href'), '_method=put', function(data, text_status, xml_http_request) {
        var tbody = link.closest('tbody')
        link.siblings('.delete_assignment:first').removeClass('hide');
        link.addClass('hide');
        link.parent().prev().text("Yes");
        if (tbody.children().length)
          apply_alternate_row_color()
        else
          tbody.closest('table').hide()
      })
    }
  })

  $(document).on('click', '#new_assignment_button', function(event) {
    show_assignment_dialog(this, {
      assignment_type: {name: 'New Assignment'},
      complete: false,
      due_date: null,
      type: 'assignment',
      use_due_date: true,
      user: {id: 'none'}})
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
  })
});
