;(function ($, WEBADMIT) {
  WEBADMIT.DesignationAdditionalDetailsModal = function (designationId, applicantId, previousLocalStatusId, html) {
    this.designationId = designationId;
    this.applicantId = applicantId;
    this.previousLocalStatusId = previousLocalStatusId;
    this.$modal = $('#designation-additional-details');
    this._setContent(html);
  };

  WEBADMIT.DesignationAdditionalDetailsModal.prototype = {
    _onSubmitSuccess: function (data) {
      if (data.updated) {
        this._updatePage(data);
      } else {
        this._showResponseData(data);
      }
    },

    _updatePage: function (data) {
      this._updateDesignationPanel();
      WEBADMIT.Designations.automailDialog(data);
      WEBADMIT.localStatusHistory.updateLocalStatusHistoryPanel();
    },

    _showResponseData: function (data) {
      this.show();
      if (data.additional_details) {
        this._setContent(data.additional_details);
        this._errorFocus(this.$modal);
      } else if (data.errors) {
        this._setContent(this._formatErrors(data.errors));
      } else if (data.local_status_errors) {
        this.$modal.dialog('option', 'buttons', {
          Close: function () {
            location.reload();
          }
        });
        this._setContent(this._formatErrors(data.local_status_errors));
      }
    },

    _onSubmitError: function (data) {
      this._setContent(data.responseText);
      this.show();
    },

    _setContent: function (content) {
      this.$modal.html(content);
    },

    _resetLocalStatusSelect: function () {
      var $localStatus = $('.autosave_designation_additional_details[data-designation-id="' + this.designationId + '"]');
      var $option = $localStatus.find('option[value="' + this.previousLocalStatusId + '"]');

      $option.attr('selected', 'selected');
      $localStatus.val(this.previousLocalStatusId);
    },

    _formatErrors: function (errors) {
      var errorMessages = errors.map(function (error) {
        return '<li>' + error.title + ': ' + error.detail + '</li>';
      }).join('');
      return '<div id="errorExplanation"><h2>There was an error while saving your form:</h2><ul>' + errorMessages + '</ul></div>';
    },

    _updateDesignationPanel: function () {
      WEBADMIT.loadPanel($('#async_content_designations_pane'));
    },

    _errorFocus: function (ele) {
      var errorDiv = ele.find('#errorExplanation');
      if (errorDiv.length) {
        errorDiv.attr('tabindex', '0');
        setTimeout(function(){ errorDiv.focus() }, 200);
      }
    },

    show: function () {
      var self = this;
      this.$modal.dialog({
        modal: true,
        height: 400,
        width: 750,
        title: 'Change Local Status',
        beforeClose: function () {
          self._setContent('');
          self._resetLocalStatusSelect();
        },
        buttons: [
          {
            text: "Cancel",
            "class": "bordered_button",
            click: function () {
              self.hide();
            }
          },
          {
            text: "Save",
            "class": "primary_button",
            click: function () {
              self.submit();
            }
          }
        ]
      });
      $('.primary_button span').addClass('position_relative');
      var firstInput = $('#designation-additional-details-form').find('input[type=text],textarea,select').filter(':visible:first');
      if (firstInput.length) {
        setTimeout(function(){ firstInput[0].focus() }, 200);
      }
    },

    hide: function () {
      this.$modal.dialog('close');
      this.$modal.dialog('destroy');
    },

    submit: function () {
      var $form = this.$modal.find('#designation-additional-details-form');

      this.hide();
      $.loadingDialog.show('Changing local status');

      $.ajax({
        url: '/applicants/' + this.applicantId + '/designations/' + this.designationId + '/additional_designation_details.json',
        method: 'PUT',
        data: $form.serialize()
      }).fail(this._onSubmitError.bind(this))
        .always($.loadingDialog.hide)
        .then(this._onSubmitSuccess.bind(this));
    }
  };
})(jQuery, WEBADMIT);
