/* emails.js */

function add_email_row(self, prototype, advanced) {
  var tbody = $(self).parents('fieldset').find('tbody');
  prototype.advanced = advanced;
  $.mustacheLoader('email_address_row', function(template) {
    var row = $($.mustache(template, prototype));
    tbody.append(row);
    apply_alternate_row_color();
    row.find('input').first().focus();
  });
  return false;
}

function hide_ckeditor_popup(popup) {
  popup.hide();
  popup.data('command').setState(CKEDITOR.TRISTATE_OFF);
}

window.ckeditor_popup_handler = function (event, command, self) {
  var field = self.closest('li').data('field');
  var popup = self.closest('.pop_over_target');
  var editor = popup.data('editor');

  hide_ckeditor_popup(popup);
  if (editor.name == 'pdf_manager_template_file_name_pattern') {
    field.name = field.name.replace(/([<>:"|?*/\\])+/g, '');
  }
  editor.execCommand(command, field);
  event.preventDefault ? event.preventDefault() : event.returnValue = false;
}

function remove_email_row(self) {
  $(self).parents('tr').remove();
  return false;
}

window.toggle_hidden_recipients = function(self) {
  var toggle = $(self).closest('.recipient_toggle');
  toggle.hide();
  toggle.siblings('.hidden_recipient').fadeIn();
}

window.applicantEmailLoaded = function(iframe) {
  var doc = iframe.contentWindow.document;
  iframe.height = doc.body.scrollHeight;
}

window.toggle_application_email = function(self, id, subject) {
  var body = $('#email_' + id);
  if (self.hasClass('closed')) {
    if (body.attr('src') == undefined) body.attr('src', self[0].href)
    if (body.css('display') == 'none')
      body.slideDown();
    else {
      $('#email_' + id).load(self[0].href, null, function(xml_http_request) {
        apply_tipsy();
        body.slideDown();
        body.attr('tabindex', '0');
      });
    }
    self.removeClass('closed');
    self.addClass('open');
    self.attr('aria-label', subject + ' expanded');
    self.attr('aria-expanded', true);
  }
  else {
    body.slideUp();
    self.removeClass('open');
    self.addClass('closed');
    self.attr('aria-label', subject + ' collapsed');
    self.attr('aria-expanded', false);
  }
  return false;
}

$(document).on('click', '#custom_popup .pop_over_dismiss', function(event) {
  hide_ckeditor_popup($(this).closest('.pop_over_target'));
});

$(document).on('click', '#custom_popup .pickable_field', function(event) {
  ckeditor_popup_handler(event, 'mailmerge', $(this));
});

$(document).ready(function() {
  $('#email_email_template_id').change(function(event) {
    var select = $(event.currentTarget);
    var form = select.closest('form');
    var subject = form.find('[name="email[subject]"]');
    var value = select.val();

    if (value) {
      $.getJSON('/email_templates/' + value, function(data, textStatus) {
        CKEDITOR.instances.email_html_body.setData(data.email_template.html_body, {
          callback: function () {
            var body = this.document.getBody().$;
            body.setAttribute('role', 'document');
            body.removeAttribute('aria-readonly');
            body.removeAttribute('aria-multiline');
          }
        });
        subject.val(data.email_template.subject);
        emailAttachmentsWidget.setFiles(data.email_attachments);
      });
    }
    else {
      CKEDITOR.instances.email_html_body.setData('', {
        callback: function () {
          var body = this.document.getBody().$;
          CKEDITOR.helpers.fixAccessibilityAttrs(body);
        }
      });
      subject.val('');
      emailAttachmentsWidget.setFiles([]);
    }
  });
});
