WEBADMIT.ApplicantAttachment = function ApplicantAttachment() {
};

WEBADMIT.ApplicantAttachment.prototype = {
  init: function() {
    var self = this;
    self.bindEvents();
  },

  bindEvents: function() {
    var self = this;
    self.bindUploadCompleted();
    $('.upload_form input[type=file]').on('change', self.checkFileSize);
    $('.destroy_attachment').click(self.deleteInProgress);
    self.bindUploadFormSubmit();
    self.bindAttachmentDestroyed();
  },

  renderUploadedDocuments: function(applicantId, callback) {
    $.ajax({ url: '/applicants/' + applicantId + '/applicant_attachments/uploaded_documents',
      type: 'GET',
      dataType: 'JSON',
      success: function(data) {
        $("#documents_admissions_uploaded_documents_panel").html(data.html);
        callback();
        var waApplicantAttachment = new WEBADMIT.ApplicantAttachment;
        waApplicantAttachment.init();
      }
    });
  },

  bindUploadCompleted: function() {
    var self = this;
    $("#new_applicant_attachment").on('ajax:success', function(e) {
      var data = e.originalEvent.detail[0]
      self.renderUploadedDocuments(data.applicant_id, function () {
        self.showHideFlash(data.status, data.message);
        if(data.attachment_id) {
          setTimeout(function () {
            $("#documents_admissions_uploaded_documents_panel").find('.doc-upload-' + data.attachment_id).focus();
          }, 3000);
        }
      });
    });
  },

  bindAttachmentDestroyed: function() {
    var self = this;
    $('.destroy_attachment').on('ajax:success', function(e) {
      var data = e.originalEvent.detail[0]
      self.renderUploadedDocuments(data.applicant_id, function () {
        self.showHideFlash(data.status, data.message);
        if($("#documents_admissions_uploaded_documents_panel").find('.j-doc-attachment').length){
          setTimeout(function(){$("#documents_admissions_uploaded_documents_panel").find('.j-doc-attachment:first').focus();}, 3000);
        }else{
          setTimeout(function(){$('.j-doc-blank').focus();}, 3000);
        }
      });
    });
  },

  bindUploadFormSubmit: function() {
    var self = this;
    $('.upload_form form').on('submit', function (e) {
      if (self.checkFilePresence() || self.checkFileName()) {
        e.stopPropagation();
        return false;
      }
      else {
        $(".upload_form .inputs").hide();
        $(".upload_form .inprogress:first").show();
      }
    });
  },

  showHideFlash: function (status_class, message) {
    $("#doc-message").addClass(status_class);
    $("#doc-message").html(message);
    $("#doc-message").attr('tabindex', '0').show();
    $("#doc-message").focus();
    $("#doc-message").delay(6000).fadeOut();
  },

  uploadInProgress: function (e) {
    var self = this;
    if (self.checkFilePresence() || self.checkFileName()) {
      e.stopPropagation();
      return false;
    }
    else {
      $(".upload_form .inputs").hide();
      $(".upload_form .inprogress:first").show();
    }
  },

  checkFilePresence: function () {
    if ($('.upload_form input[type=file]').val()) {
      return false;
    }
    else {
      alert("Please choose a file");
      return true;
    }
  },

  checkFileName: function () {
    if ($('#applicant_attachment_description').val()) {
      return false;
    }
    else {
      alert("Please enter a name for the file");
      return true;
    }
  },

  deleteInProgress: function (e) {
    if (confirm('Are you sure you want to delete this file?')) {
      $(this).next('.delete.inprogress').show();
    }
    else {
      e.stopPropagation();
      return false;
    }
  },

  checkFileSize: function (event) {
    var maxSizeMB = 15, files, invalid;

    files = event.currentTarget.files;

    // Feature detection.  Falls back to the server-side check if the `files`
    // property is undefined.
    if (undefined !== files) {
      // We only expect one, but handling all of them leads to fewer
      // assumptions in the code.
      invalid = _(files).
        some(function (file) { return file.size > (1024 * 1024 * maxSizeMB); });

      if (invalid) {
        alert('Files cannot be larger than ' + maxSizeMB + 'MB');
        // Clear the field.
        event.currentTarget.value = '';
      }
    }
  }
};

$(document).ready(function() {
  $('#async_content_documents_pane').on('loaded', function(e) {
    var waApplicantAttachment = new WEBADMIT.ApplicantAttachment;
    waApplicantAttachment.init();
  });
});
