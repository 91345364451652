jQuery.fn.course_grouping_prep= function() {
  var group_by         = $('#courses_group_by');
  var filter           = $('#local_gpa_selector');
  var local_gpa_filter = $('#local_gpa_filter')

  adjustCourseDisplay();
  adjustCourseGrouping();
  updateCourseTemplate();

  filter.on('change', adjustCourses );
  group_by.on('change', adjustCourses );
  local_gpa_filter.on('change', adjustCourses );

  $('.pop_over').initPopOver();
  apply_tipsy();
}

function adjustCourseDisplay() {
  var val = $('#local_gpa_selector').val();

  $('.local_gpa_column').hide();
  if (val) {
    $('.local_gpa_column[data-template_course=' + val + ']').show();
  }
}

function adjustCourseFilter() {
  var filter = $('#local_gpa_filter');
  var courses_template_val = $('#local_gpa_selector').val();
  var is_local_gpa = $('#local_gpa_selector :selected').parent().hasClass('select_local_gpas')
  var courses = $('.duplicate');
  var valid_courses = $('.duplicate:visible td:visible input[type=checkbox]:checked').closest('tr');
  var stub_course_none = $('.empty_row')

  if (filter.is(':checked') && is_local_gpa) {
    courses.addClass('hide');
    valid_courses.removeClass('hide');
  } else {
    // does this need to be here?
    courses.removeClass('hide');
  }
}

function adjustCourseGrouping() {
  var local_gpa_filter    = $('#local_gpa_selector');
  var group_by            = $('#courses_group_by');
  var grouping_by_val     = group_by.val();
  var headers             = $('.header');
  var duplicated_courses  = $('.duplicate');

  // remove dupes
  duplicated_courses.remove();

  // hide headers
  headers.hide();

  // show correct headers
  var good_headers = $('.header[data-' + grouping_by_val + ']');
  good_headers.show();

  /*
  *  If selected element has a grouping use the showGrouping function
  *  otherwise use the showNoneGrouping function
  */

  groupingCaller(grouping_by_val, good_headers);
  local_gpa_filter.trigger("filter_courses");
}

function adjustCourses() {

  adjustCourseGrouping();
  adjustCourseDisplay()
  adjustCourseFilter();
  updateCourseTemplate();

  // courses
  highliteCourses();
  $('.elec-trans-status-text').text('Electronic Transcript table updated.');
  $('.pop_over').initPopOver();
  $('tr.opened-popup').each(function(){
    $(this).removeClass('opened-popup').addClass('closed-popup');
  });
  $('dt.dl-opened, dd.dl-opened').each(function(){
    $(this).removeClass('dl-opened').addClass('dl-closed');
  });
}

function groupingCaller(grouping_by_val, good_headers) {
  if (grouping_by_val  == "none"){
    showNoneGrouping();
  }
  else {
    showGrouping(good_headers, grouping_by_val);
  }
}

function highliteCourses() {
  var coursesTable = $('#transcript_detail_table')
  coursesTable.highliteTableRows();
}

function remove_duplicate_ids(cloned_courses){
  cloned_courses.each(function(){
    var pop_over_target = $(this).find('.pop_over_target');
    pop_over_target.attr('id', pop_over_target.data('id'));

    var checkbox = $(this).find('.local_gpa_checkbox');
    if (checkbox.length > 0) {
      for (var i = 0; i < checkbox.length; i++) {
        checkbox[i].id = checkbox[i].dataset.gpa;
      }
    }
  });
}

function showGrouping(good_headers, grouping_by_val ) {
  good_headers.each( function() {
    var header  = $(this);
    var courses = $('tr.course[data-' + grouping_by_val + '=' + header.data(grouping_by_val) + ']');
    var cloned_courses = courses.clone();
    remove_duplicate_ids(cloned_courses);
    cloned_courses.addClass("duplicate");
    cloned_courses.removeClass('hide');
    header.after(cloned_courses);
  });
}

function showNoneGrouping() {
  var courses = $('.course');
  var table = $('#transcript_detail_table');
  cloned_courses = courses.clone();
  remove_duplicate_ids(cloned_courses);
  cloned_courses.addClass("duplicate");
  cloned_courses.removeClass('hide');
  table.append(cloned_courses);
}

function show_academic_requirement() {
  var $optgroup = $select_box.find(":selected").parent();
  if ($optgroup.attr("label") == "Academic Requirement") {
    $.get("/gpa_templates/" + $select_box.val(), {}, function(data, status) {
      var $academic_requirement = $(".local_gpa_requirements");
      if($academic_requirement.length > 0) {
        $academic_requirement.replaceWith(data);
      } else {
        $("#transcript_detail_table").before(data);
      }
    }, "text");
  } else {
    $(".local_gpa_requirements").hide();
  }
}

function toggleCheckboxes(data) {
  // ONLY FOR THE TABLE ROW OF THE LOCALGPA
  var etCheckboxes = $('#transcript_detail_table td[data-template_course= ' + data.id +  ' ] input:checkbox')
  if (data.fulfilled) {
    etCheckboxes.each(function(){
      var checked = $(this).is(":checked")
      if(!checked) {
        $(this).attr('disabled', true)
      }
    });

  } else {
    etCheckboxes.removeAttr("disabled");
  }
}

function updateCourseTemplate() {
  $('.local_gpa_checkbox').on('click', function() {
    var checkbox       = $(this);
    var included       = checkbox.is(':checked');
    var course_id      = checkbox.val();
    var local_gpa_id   = $('#local_gpa_selector').val();
    var params         = {course_id: course_id, local_gpa_id: local_gpa_id, included: included, _method: 'put'};
    var checkbox_label = checkbox.attr('aria-label');
    var updated_state  = included ? checkbox_label.replace('Include', 'Included') : checkbox_label.replace('Include', 'Excluded');

    var url = included ? "/local_gpas/" + local_gpa_id + "/add_course" : "/local_gpas/" + local_gpa_id + "/remove_course"

    $.post(url, params, function(data) {
      updateGpaOverview(data);
      // some people have ET but cannot edit.
      // make sure to not let these people
      toggleCheckboxes(data)
      var checkboxes = $("." + checkbox.attr("class"));
      $('.elec-trans-status-text').text(updated_state);
      checkboxes.each(function() {
        if (!$(this).is(":disabled")) {
          $(this).attr("checked", checkbox.is(":checked"));
        }
      });
      checkbox.addRemoveCourseHighlite()
    }, "json");
    checkbox.focus();
  });
}

function updateGpaOverview(params) {
  var gpa_overview = $('#gpa_by_local table');
  var row_class = ".local_gpa_" + params.id + "_row";
  var row = gpa_overview.find(row_class);

  row.replaceWith(params.local_gpa);
  $(row_class).effect("highlight", {color: "#DFD"}, 1000);
}
