$(document).ready(function() {
  if ($('ul.sorted_fields').length) {

    var fields_list = $('ul.sorted_fields'),
        fields = fields_list.children('li');

    fields.sort(function(a,b){
      var field_order_a = Number(a.getAttribute('data-field-order')),
          field_order_b = Number(b.getAttribute('data-field-order')),
          field_category_order_a = Number(a.getAttribute('data-field-category-order')),
          field_category_order_b = Number(b.getAttribute('data-field-category-order'));

      if(field_category_order_a > field_category_order_b) {
        return 1;
      }
      if(field_category_order_a < field_category_order_b) {
        return -1;
      }
      if(field_order_a > field_order_b) {
        return 1;
      }
      if(field_order_a < field_order_b) {
        return -1;
      }
      return 0;
    });

    fields.detach().appendTo(fields_list);
  };
});
