/* standardized_tests.js */

$(document).on('change', 'form.test_receipt_control input', function(){
  var $receiptInput = $(this)
  var $form = $receiptInput.closest('form')
  var receiptToggleUrl = '/standardized_test_receipts/toggle'
  var $row = $receiptInput.closest('tr')

  var wasReceived = $receiptInput.is(':checked')
  var outgoingData = {
    'test_id': $form.find('.test_id').val(),
    'panel_id': $form.closest('.panel').prop('id')
  }

  if (wasReceived) {
    outgoingData['received'] = true
  }

  var onSuccessHandler = function(data){
    $receiptInput.inlineCheckboxFeedback('success')
    $row.replaceWith(data)
    $(".pop_over").initPopOver();
  };
  var onErrorHandler = function(){
    $receiptInput.inlineCheckboxFeedback('failure')
  }
  var onBeforeSendHandler = function(){
    $receiptInput.inlineCheckboxFeedback('sending')
  }

  $.ajax({
    type: 'POST',
    url: receiptToggleUrl,
    data: {'receipt': outgoingData},
    beforeSend: onBeforeSendHandler,
    success: onSuccessHandler,
    error: onErrorHandler
  })

});

$(function() {
    $(document).on('click', 'a[graph-popup]', function(e) {
      var graph_win = window.open($(this)[0].href, 'popup','resizable=yes,autosize=yes');
      if(graph_win){window.onfocus=function(){graph_win.close()}}
      e.preventDefault();})
});
