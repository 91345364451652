ScheduleInterview.InterviewTypeSection = function() {
  ScheduleInterview.ViewSection.call(this);
  this.render = function(options) {
    this.options = options;
    return {
      hasAssignmentTypes: this.buildListView(
	'assignmentTypes',
	options.interviewTypes,
	{ selectedId: (this.hasPath(options, 'interview', 'assignment_type', 'id') ?
		       options.interview.assignment_type.id : false) }),
      userIdentityFieldName: 'assignment_user_identity_ids[]'
    };
  };

  this.bind = function(context) {
    this.context = context;
    var __ = this;
    this.context.modal.find('form select.interview_type_select').on(
      'change',
      function(event) { __.onInterviewTypeSelectChanged(event); }
    );
    if (this.options.activityType) {
      if (this.options.activityType == 'ApplicantGateway::ScheduleInterviewActivity') {
	this.context.modal.find('form select.interview_type_select').parent().css('display', 'block');
      } else {
	this.context.modal.find('form select.interview_type_select').parent().css('display', 'none');
      }
    }
  };

  this.onInterviewTypeSelectChanged = function(event) {
    var current_interview = this.context.viewBuilder.interview;
    var interview = {
      id: (current_interview ? current_interview.id : false),
      assignment_type: { id: parseInt(event.target.value) },
      location: { location_id: false },
      user_identity: { id: false },
      slot_id: false
    };
    this.context.openModal({
      interview: interview,
      activityType: $('select.activity_select[name="activity_type"]').val()
    }, 'useCache');
  };
};
ScheduleInterview.InterviewTypeSection.prototype = Object.create(ScheduleInterview.ViewSection.prototype);
ScheduleInterview.InterviewTypeSection.prototype.constructor = ScheduleInterview.InterviewTypeSection;

