window.newModelComponent = function(url) {
  $.post(url, {}, function(data) {
    var tr = $(data).closest('tr').attr('id');
    $('#model_components').append(data);
    $('#no_scoring_components').hide();
    $('#model_components').find('#' + tr).find('.pop_over_toggle').focus();
  });
}

window.destroyModelComponent = function(url, domId, id) {
  if (!confirm("Are you sure you want to delete this component?"))
    return;

  removeModelComponentRow(domId);
  if(url != '') {
    $('.scoring_scoring_model').append('<input type="hidden" name="remove_component[]" value="' + id + '"/>')
  }
  $('.new_scoring_component').focus();
}

function removeModelComponentRow(domId) {
  $('#' + domId).hide(200, function() {
    $('#' + domId).remove();
  });
}

function positionScorableFieldPopup(relativeTo) {
  var elementToMove = $('#scorable_field_popup')
  var elementToMoveTab = elementToMove.find('.tab')
  elementToMove.css({'position':'static', 'left':'0', 'top':'0'});
  elementToMoveTab.css({'left': ((relativeTo.width() / 2) - 26) + 'px'});
  var detailsTr = $('<tr><td colspan="100%"></td></tr>');
  elementToMove.appendTo(detailsTr.find('td'));
  relativeTo.closest('tr').after(detailsTr);
}

function updateFieldLabels(row, field){
  row.find('.earliest_year').attr('aria-label','Please select earliest date range for ' + field.name)
  row.find('.latest_year').attr('aria-label','Please select present date range for ' + field.name)
  row.find('.multi-val-select').attr('aria-label','Please select multiple values for ' + field.name)
  row.find('.point-table-select').attr('aria-label','Please select point table for ' + field.name)
  row.find('.model_component_multiplier').attr('aria-label','Please enter multiplier value for ' + field.name)
  row.find('.delete-scoring-comp').attr('aria-label','Delete scoring component ' + field.name)
}

$(document).on('click', '#scorable_field_popup .pickable_field', function(event) {
  var self = $(this)
  var field = self.closest('li').data('field')
  var $model_component_row = $('#' + window.model_component_row_id)
  var $hidden_input = $model_component_row.find('input[name$="[field_id]"]')
  $hidden_input.val(field.id)
  $hidden_input.change()
  $model_component_row.find('.pop_over_toggle_wrapper_text').text(field.name)
  updateFieldLabels($model_component_row, field)
  $(this).closest('.pop_over_target').hide()
  $('.j-field-list-msg').text('Field ' + field.name + ' selected.');
  $model_component_row.find('a.pop_over_toggle').attr('aria-label', 'Selected field: ' + field.name + '. ' + 'Show the list of fields for the scoring component.');
  $model_component_row.find('td:nth-child(2) select:first').focus();
  $('#scorable_field_popup .pop_over_dismiss').trigger('click');
  event.preventDefault ? event.preventDefault() : event.returnValue = false;
})

$(document).on('click', '#model_components .pop_over_toggle', function(e) {
  e.preventDefault();
  if($('#scorable_field_popup').css('display') == 'block'){
    $('#scorable_field_popup .pop_over_dismiss').trigger('click');
    setToggleState($(this), 'Hide', 'Show');
  }else{
    $(this).uniqueId();
    window.model_component_row_id = $(this).closest('tr').attr('id');
    positionScorableFieldPopup($(this));
    $('#scorable_field_popup').show();
    var content = $('#scorable_field_popup');
    content.attr({'tabindex': 0, 'data-origin': $(this).attr('id')});
    content.focus();
    toggleScreenReaderHelptext($(this), content);
  }
})

$(document).on('keyup', '#scorable_field_popup', function(event){
  var pressed = event.keyCode;
  if(pressed === 27){
    var parentID = '#' + $(this).attr('data-origin');
    $('#scorable_field_popup .pop_over_dismiss').trigger('click');
    setToggleState($(parentID), 'Hide', 'Show');
    setTimeout(function(){$(parentID).focus();}, 500);
  }
});

$(document).on('click', '#scorable_field_popup .pop_over_dismiss', function(event) {
  var closestTr = $(this).closest('tr');
  $(this).closest('.pop_over_target').hide();
  var closestForm = $(this).closest('form');
  $(this).closest('#scorable_field_popup').appendTo(closestForm);
  closestTr.remove()
})

$(document).on('change', '#model_components input[name$="[field_id]"]', function(event) {
  // need to disable / deselect Multiple Values select box if chosen field is not allow_many
  var field_id = $(this).val()
  var allowances = Allowances[field_id]
  var allow_many = allowances.allow_many
  var multi_value_id = $(this).attr('id').replace('field_id', 'multi_value_handler')
  var $multi_value_handler_select = $("#" + multi_value_id)
  $multi_value_handler_select.prop('disabled', !allow_many)
  $multi_value_handler_select.val("");
  var has_scoring_date_field = allowances.has_scoring_date_field
  // enable/disable Most Recent option
  $multi_value_handler_select.find('option[value="MostRecent"]').prop('disabled', !has_scoring_date_field)

  // need to disable Date Rage when there is no scoring date field otherwise the
  // score will be discarded down the line when we check for the value of the
  // scoring_date_field_identifier attr
  var earliest_year_id = $(this).attr('id').replace('field_id', 'earliest_year')
  var earliest_year_select = $('#' + earliest_year_id)
  earliest_year_select.find('option:contains("Earliest")').prop("selected", true);
  earliest_year_select.prop('disabled', !has_scoring_date_field)

  var latest_year_id = $(this).attr('id').replace('field_id', 'latest_year')
  var latest_year_select = $('#' + latest_year_id)
  latest_year_select.find('option:contains("Present")').prop('selected', true);
  latest_year_select.prop('disabled', !has_scoring_date_field)

  // need to disable overall result when it is not listed in the filter yaml
  var has_overall_result = allowances.has_overall_result
  $multi_value_handler_select.find('option[value="HighestOverall"]').prop('disabled', !has_overall_result)

  // need to disable average, sum and maximum for multi value string or lookup input
  var field_type_lookup_or_string = allowances.has_type_string_or_lookup
  $.each(["Average", "Sum", "Maximum"], function(index, value) {
    $multi_value_handler_select.find('option[value="'+value+'"]').prop('disabled', field_type_lookup_or_string)
  })
})


function updateMultiplierState(pointTableSelect) {
  var point_table_id = $(pointTableSelect).val()
  var multiplier_id = $(pointTableSelect).attr('id').replace('point_table_id', 'multiplier')
  var $multiplier_input = $('#' + multiplier_id)
  // disable multiplier input unless Point Table selection is (None)
  if(point_table_id == '') {
    $multiplier_input.prop('disabled', false)
    $multiplier_input.effect('highlight', {color:'#BFB'}, 2000)
  }
  else {
    $multiplier_input.prop('disabled', true)
    $multiplier_input.val('1.0')
    $multiplier_input.effect('highlight', {color:'#FBB'}, 2000)
  }
}
$(document).on('change', '#model_components select[name$="[point_table_id]"]', function(event) {
  updateMultiplierState(this)
})

$(function() {
  $('#model_components select[name$="[point_table_id]"]').each(function(index) {
    updateMultiplierState(this)
  })
})

$(document).on('focusin', '.new-score-submit', function(event){
  var value = $('.help_note').text();
  $('#score-form-status').text(value);
});
