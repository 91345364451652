WEBADMIT.PdfManagerTemplate = {
  setup: function setup(documentSourceId, isFilteringEnabled) {
    WEBADMIT.PdfManagerTemplate.load_file_pattern_tree(documentSourceId);
    WEBADMIT.PdfManagerTemplate.toggle_subpanel_fields(documentSourceId);
    WEBADMIT.PdfManagerTemplate.setDateVisibility();
    WEBADMIT.PdfManagerTemplate.toggleFilteringEnabled(isFilteringEnabled);
    WEBADMIT.PdfManagerTemplate.toggleListCriteriaInput();

    $(document).on("click", "#custom_popup .pickable_field", function(event) {
      ckeditor_popup_handler(event, "filepattern", $(this));
      $('#custom_popup').dialog('close');
    });
    $(document).on("change", "#pdf_manager_template_filter_identifier", WEBADMIT.PdfManagerTemplate.setDateVisibility);
    $(document).on("change", "#pdf_manager_template_filtering_enabled", WEBADMIT.PdfManagerTemplate.filteringEnabledChanged);
    $(document).on("change", "#pdf_manager_template_document_source_id", WEBADMIT.PdfManagerTemplate.toggleListCriteriaInput);
    $(document).on("change", "#pdf_manager_template_list_id", WEBADMIT.PdfManagerTemplate.toggleListCriteriaInput);
  },

  toggleListCriteriaInput: function () {
    let isUsingList = function() {
      let list = $('#pdf_manager_template_list_id');
      let referenceIndex = list.find('option[value="------------"]').index();
      if(referenceIndex == -1) {
        return false;
      }
      return list.prop('selectedIndex') > referenceIndex;
    };
    let isSupportedDocumentTypeSelected = function(){
      return $("#pdf_manager_template_document_source_id option:selected").data("list-criteria-supported");
    };
    let isListCriteriaSupported = function(){
      return isUsingList() && isSupportedDocumentTypeSelected()
    };
    if (isListCriteriaSupported()) {
      $("#list-criteria").show();
    } else {
      $("#pdf_manager_template_use_list_criteria").find("option:selected").prop("selected", false);
      $("#list-criteria").hide();
    }
  },

  set_ckeditor_data: function set_ckeditor_data(data) {
    var ckinstance = CKEDITOR.instances["pdf_manager_template_file_name_pattern"];

    ckinstance.setData(data, function () {
      var read_data = WEBADMIT.PdfManagerTemplate.decodeEntities(ckinstance.getData().replace("<p>", "").replace("</p>",""));
      if (read_data == data) {
        $(".cke_button__filepattern").addClass("editor_ready");
      } else {
        WEBADMIT.PdfManagerTemplate.set_ckeditor_data(data);
      }
      var body = ckinstance.document.getBody().$;
      CKEDITOR.helpers.fixAccessibilityAttrs(body);
    });
  },

  set_file_name_pattern_value: function set_file_name_pattern_value(file_name_patterns) {
    $(".cke_button__filepattern").removeClass("editor_ready");

    var document_source_id = $("#pdf_manager_template_document_source_id").val();
    var pattern_content = file_name_patterns[document_source_id].replace("<p>", "").replace("</p>","");

    WEBADMIT.PdfManagerTemplate.set_ckeditor_data(pattern_content);
    WEBADMIT.PdfManagerTemplate.load_file_pattern_tree(document_source_id);
    WEBADMIT.PdfManagerTemplate.toggle_subpanel_fields(document_source_id);
  },

  setDateVisibility: function setDateVisibility() {
    var filterIdentifierField = $("#pdf_manager_template_filter_identifier");
    var requireStartDate = filterIdentifierField.find(":selected").data("require-start-date");
    var requireEndDate = filterIdentifierField.find(":selected").data("require-end-date");
    var lastPrintedStartDate = $("#pdf_manager_template_last_printed_start_date");
    var lastPrintedEndDate = $(".last_printed_end_date");

    lastPrintedStartDate.toggle(requireStartDate);
    lastPrintedEndDate.toggle(requireEndDate);
  },

  filteringEnabledChanged: function filteringEnabledChanged(event) {
    WEBADMIT.PdfManagerTemplate.toggleFilteringEnabled(event.target.checked);
  },

  toggleFilteringEnabled: function toggleFilteringEnabled(enable) {
    var filterIdentifierField = $("#pdf_manager_template_filter_identifier");

    if (enable) {
      filterIdentifierField.enable();
    } else {
      filterIdentifierField.val("null").trigger("change").disable();
    }
  },

  load_file_pattern_tree: function load_file_pattern_tree(document_source_id) {
    $(".cke_button__filepattern").removeClass("tree_ready");

    var data = { document_source_id: document_source_id };
    $.getJSON("/pdf_manager/field_tree", data, function(json) {
      categories = json.nodes;

      $(".last-printed-date-section").toggle(json.date_filters_enabled);

      WEBADMIT.load_field_tree($)
      $("#custom_popup .tree_node.root_node").treeView();
      $("#custom_popup .tree_node_title").attr("document_source_id", document_source_id);
      $(".cke_button__filepattern").addClass("tree_ready");
    });
  },

  decodeEntities: function decodeEntities(encodedString) {
    var textArea = document.createElement("textarea");
    textArea.innerHTML = encodedString;
    return textArea.value;
  },

  toggle_subpanel_fields: function toggle_subpanel_fields(document_source_id) {
    var full_application_pdf_document_source_id = $(".pdf_settings").data("fullapplicationpdf");
    if (document_source_id == full_application_pdf_document_source_id) {
      $(".subpanel_fields").show();
    } else {
      $(".subpanel_fields").hide();
    }
  }
};




