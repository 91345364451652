WEBADMIT.localStatusHistory = {};

;(function ($, WEBADMIT) {
  WEBADMIT.localStatusHistory.paginationPage = function () {
    var currentPagination = $('#local_status_changes_panel .pagination .current');
    var paginationPage;
    if (currentPagination.length) {
      paginationPage = currentPagination.text();
    } else {
      paginationPage = '1';
    }
    return paginationPage;
  };

  WEBADMIT.localStatusHistory.refreshPanelData = function (data) {
    WEBADMIT.loadPanel($('#async_content_designations_pane'));
    WEBADMIT.localStatusHistory.updateLocalStatusHistoryPanel();
    if (data.error) {
      $('#local-status-history-flash').html(data.error);
    }
  };

  WEBADMIT.localStatusHistory.currentPanelLink = function () {
    if ($('#local_status_changes_panel').length) {
      var applicantId = $('#local_status_changes_panel').data('applicant-id');
      var paginationHref = '/applicants/' + applicantId;
      paginationHref += '/history?active_tab=local_status_changes&async_include=true&local_status_events_page=';
      paginationHref += WEBADMIT.localStatusHistory.paginationPage();
      return paginationHref;
    } else {
      return '';
    }
  };

  WEBADMIT.localStatusHistory.updateLocalStatusHistoryPanel = function () {
    var element = $('#async_content_history_pane');
    if (element.find('#local_status_changes_panel').length > 0) {
      element.attr('data-async-url', WEBADMIT.localStatusHistory.currentPanelLink());
      WEBADMIT.loadPanel(element);
    }
  };

  function localStatusRevertRequest(revertButton, method) {
    var applicantId = revertButton.data('applicant-id');
    var designationId = revertButton.data('designation-id');
    var eventId = revertButton.data('event-id');
    var actionUrl = applicantId + '/designations/' + designationId + '/local_status_reverts';

    return $.ajax({
      type: method,
      url: actionUrl,
      data: { event_id: eventId }
    });
  }

  function getLocalStatusRevertSummary(revertButton) {
    return localStatusRevertRequest(revertButton, 'GET');
  }

  function performLocalStatusRevert(revertButton) {
    return localStatusRevertRequest(revertButton, 'PUT');
  }

  function longRunningTask(loadingTitle, task) {
    $.loadingDialog.show(loadingTitle);
    return task()
      .fail(WEBADMIT.LocalStatusRevertModal.fail)
      .always($.loadingDialog.hide);
  }

  WEBADMIT.localStatusHistory.confirm = function (revertButton) {
    return longRunningTask('Revert Local Status change', function () {
      return getLocalStatusRevertSummary(revertButton);
    }).then(WEBADMIT.LocalStatusRevertModal.show);
  };

  WEBADMIT.localStatusHistory.revertStatusChange = function (revertButton) {
    return longRunningTask('Reverting in progress...', function () {
      return performLocalStatusRevert(revertButton);
    }).then(WEBADMIT.localStatusHistory.refreshPanelData);
  };
})(jQuery, WEBADMIT);

$(document).ready(function () {
  $(document.body).on('click', '.rollback-button', function (event) {
    var $button = $(this);
    event.preventDefault();
    $button.prop('disabled', true);

    WEBADMIT.localStatusHistory.confirm($button)
      .then(function () {
        WEBADMIT.localStatusHistory.revertStatusChange($button);
      })
      .always(function () {
        $button.prop('disabled', false);
      });
  });
});
