$( document ).ready(function() {

  var showHideCheckboxes = $('.show-hide-check-box');

  showHideInputsForCheckboxes(showHideCheckboxes);

  $(showHideCheckboxes).click(function() {
    showHideInputsForCheckbox( this );
  });

  function showHideInputsForCheckboxes ( checkboxes ) {
    $.each(checkboxes, function( index, checkbox ) {
      showHideInputsForCheckbox(checkbox);
    });
  }

  function showHideInputsForCheckbox(checkbox) {
    var allTextInputs = $( "input[type='text']" );
    var relatedField = $(checkbox).attr("related_field");

    $.each(allTextInputs, function( index, textInput ) {
      if ($(textInput).attr('id').indexOf(relatedField) >= 0) {
        if ($(checkbox).is(":checked")) {
          $(textInput).closest('li').show();
        } else {
          $(textInput).closest('li').hide();
          $(textInput).val("");
        }
      }
    });

  }

});
