// -- ScheduleInterview.SimpleModal ^
ScheduleInterview.SimpleModal = function(applicantId, programIds) {
  ScheduleInterview.Modal.call(this, {applicantId: applicantId, programIds:  programIds, title: 'New Interview'});

  this.beforeOpen = function () {
    $(document).off('click.new_interview', '#new_interview_button');
  };

  this.afterClose = function () {
    ScheduleInterview.bindNewInterviewButton();
  };

  this.onSuccess = function (data, status, xhr) {
    var __ = this;
    var hasErrors = false;
    for (var index = 0; index < data.length; ++index) {
      var interview = data[index];
      if (interview && (!interview.errors || interview.errors.length == 0)) {
        __.loadInterviewRow(interview);
      } else if (interview && interview.errors.length > 0) {
        hasErrors = true;
        var errors = interview.errors;
        $('span.error').remove();
        for (var index = 0; index < errors.length; ++index) {
          var error = errors[index];
          $('<span class="error">'+error.message+'&nbsp;&nbsp;</span>')
            .prependTo('div.c-schedule_interview-error');
        }
        $('.c-schedule_interview-error').focus();
      }
    }
    return !hasErrors;
  };

  this.loadInterviewRow = function(interview) {
    $.mustacheLoader('assignment_row', function(template) {
      $('#interview_table').removeAttr('style');
      var tbody = $('#interview_table tbody.interview_table_body');
      var rowHTML = $.mustache(template, interview);
      if (tbody.length == 0) {
        $.mustacheLoader(
          'interview_table',
          function(template) {
            var table = $($.mustache(template, {}));
            table.find('tbody.interview_table_body').append(rowHTML);
            table.find('.pop_over').initPopOver();
            $('#async_content_interviews_pane').prepend(table);
          }
        );
      } else {
        $('.assignment_detail_row_'+interview.id).remove();
        $(rowHTML).appendTo(tbody);
        tbody.find('.pop_over').initPopOver();
      }
      //Don't remove timeout, for screen reader
      setTimeout(function(){$('.assignment_detail_row_'+interview.id).find('a.action').eq(0).focus()}, 20);
    });
  };

  this.getViewBuilder = function() {
    return (this.viewBuilder || (this.viewBuilder = new ScheduleInterview.ViewBuilder(
      {
	applicantId: this.options.applicantId,
	interviewTypes: this.interviewTypes,
	viewSections: [
	  new ScheduleInterview.SlotSection(),
	  new ScheduleInterview.InterviewerSection(),
	  new ScheduleInterview.LocationSection(),
	  new ScheduleInterview.InterviewTypeSection(),
	  new ScheduleInterview.ApplicantSection()
	]
      })));
  };

  this.getSubmitOptions = function() {
    var __ = this;
    return $.extend(
      __.defaultSubmitOptions(),
      {
        httpMethod: function() { return __.modal.find('form').attr('method'); },
        url: function() {
          var interviewInput = __.modal.find('form input[name="assignment[id]"]');
          return (interviewInput.length ? '/interviews/' + interviewInput.val() : '/interviews');
        },
        data: function () { return __.modal.find('form').serialize(); },
        onSuccess: function (data, status, xhr) { return __.onSuccess(data, status, xhr); }
      }
    );
  };
};
ScheduleInterview.SimpleModal.prototype = Object.create(ScheduleInterview.Modal.prototype);
ScheduleInterview.SimpleModal.prototype.constructor = ScheduleInterview.SimpleModal;
// -- ScheduleInterview.SimpleModal $
