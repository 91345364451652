/*
 * Allows the user to select and interact with only a subset of items from a
 * (possibly multi-page) list. The user can choose to include or exclude all
 * items to start with, and can then either deselect or select the exceptions
 * individually.
 *
 * The selection state of each item will be represented by a checkbox of class
 * 'toggle_item_selection', and its value will equal the item's ID. The
 * selection mode of the entire list (either include or exclude all by default)
 * is controlled by a checkbox of class 'toggle_all_selection'. The selection
 * checkboxes may be contained by an element of class 'selectable_list'; if this
 * element is present, the name of the selection is determined by its DOM id. If
 * this element is not present, the name of the selection will be 'selection'.
 *
 * Links which should be affected by the selection must have class
 * 'selection_link' or descend from an element of class 'selection_links', and
 * must also descend from the 'selectable_list' element, if present.
 *
 * The selection is passed back to the server as two parameters per selectable
 * list. 'selection[items]' is a hyphen-separated list of the item IDs selected
 * by the user. (It is hyphen-separated to cut down on the size of the Request
 * URI; for this reason, item IDs may not contain hyphens.) 'selection[mode]'
 * will equal 'include' if the item list contains all of the items that should
 * be included in the request; it will equal 'exclude' if the selection contains
 * all items except those listed. If the selection is named by a
 * 'selectable_list' element, the parameters will be named after the selectable
 * list instead (ie, users[items] and users[mode]).
 *
 * <div class='selectable_list' id='users'>
 *   <!-- Optional, names & groups the selection. -->
 *
 *   <a href='/'>Home</a>
 *     <!-- Not affected by the selection. -->
 *
 *   <a class='selection_link' href='?page=1'>First</a>
 *     <!-- Params will be merged with 'users[items]' and 'users[mode]'. -->
 *
 *   <span class='selection_links'>
 *     <!-- All links will have params merged. -->
 *     <a href='?page=2'>2</a>
 *     <a href='?page=3'>3</a>
 *   </span>
 *
 *   <form class='selection_form'>
 *     <!-- Will submit selection params with form. -->
 *     <select name='sort'>
 *       <option value='first'>First Name
 *       <option value='last'>Last Name
 *     </select>
 *   </form>
 *
 *   <input class='toggle_all_selection' type='checkbox'> Select All
 *     <!-- Controls the mode of the selection (include/exclude by default). -->
 *
 *   <input class='toggle_item_selection' type='checkbox'> Doe, Jane
 *   <input class='toggle_item_selection' type='checkbox'> Doe, John
 *     <!-- Controls individual item selection. -->
 * </div>
 */
WEBADMIT.SelectableList = {};

;(function($, WEBADMIT) {
  WEBADMIT.SelectableList.updateHref = function(link) {
    var href_parts = split_url(link.href);
    var href = href_parts[0];
    var list = $(link).selectable_list();
    var params = href_parts[1];
    var selection = list.list_selection();
    var selection_name = list.selectable_list_id();
    for(var i = params.length - 1; i >= 0; --i) {
      if (params[i].name.match('^' + selection_name + '\\['))
        params.splice(i, 1);
    }
    if (selection.items.length) {
      params.push({
        name: selection_name + '[items]',
        value: selection.items.sort().join('-')
      });
    }
    params.push({
      name: selection_name + '[mode]',
      value: selection.mode
    });
    if ($(link).data("status")) {
      params.push({
        name: selection_name + '[status]',
        value: $(link).data("status")
      });
    }
    href = href + '?' + $.param(params);
    link.href = href;
  };

  /*
   * Contains state and off-screen item IDs for all selections on the page.
   */
  var selections = {}

  /*
   * Returns a description of the list selection containing the jQuery result
   * set. This is an object containing a string named 'mode', which will be
   * either 'include' or 'exclude'; and an array called 'items', which lists all
   * the item IDs that are included or excluded from the selection.
   */
  $.fn.list_selection = function() {
    var list = this.selectable_list()
    var list_id = list.selectable_list_id()
    var selection = selections[list_id] || {}
    var items = selection.items ? selection.items.slice() : []
    var exclude = selection.mode != 'include'
    var mode = exclude ? 'exclude' : 'include'
    list.selectable_item_toggles().each(function(i, item) {
      if (exclude != item.checked)
        items.push($(item).val())
    })
    return {items: items, mode: mode}
  }

  /*
   * Finds the checkbox which determines whether a selectable list is include or
   * exclude by default.
   */
  $.fn.selectable_toggle_all = function() {
    return this.selectable_list().find('.toggle_all_selection')
  }

  /*
   * Finds all checkboxes which determine whether individual items are selected.
   */
  $.fn.selectable_item_toggles = function() {
    return this.selectable_list().find('.toggle_item_selection')
  }

  /*
   * Returns the selectable list which contains a particular element. This will
   * be the innermost element of class 'selectable_list' which contains the
   * element, or the document node itself.
   */
  $.fn.selectable_list = function() {
    var result = this.closest('.selectable_list')
    if (!result.length)
      result = $(document)
    return result
  }

  /*
   * Returns the name of the selectable list which contains a particular
   * element. This will be the DOM id of the innermost element of class
   * 'selectable_list' which contains the element, or 'selection' by default.
   */
  $.fn.selectable_list_id = function() {
    return this.selectable_list()[0].id || 'selection'
  }

  $.fn.selectable_submit = function () {
    var form = this;
    var selection = form.list_selection()
    var selection_name = form.selectable_list_id()
    var items_input = form.find('[name="' + selection_name + '[items]"]')
    var mode_input = form.find('[name="' + selection_name + '[mode]"]')
    if (selection.items.length) {
      if (items_input.length == 0) {
        items_input =
            $('<input name="' + selection_name + '[items]" type="hidden">')
        form.append(items_input)
      }
      items_input.val(selection.items.sort().join('-'))
    }
    else
      items_input.remove()
    if (mode_input.length == 0) {
      mode_input =
          $('<input name="' + selection_name + '[mode]" type="hidden">')
      form.append(mode_input)
    }
    mode_input.val(selection.mode)
  };

  /*
   * Adds selection values to a form before being submitted.
   */
  $(document).on('submit', '.selection_form', function(event) { $(this).selectable_submit(); });

  /*
   * Changes the selection mode in response to a click on the selection mode
   * checkbox.
   */
  $(document).on('click', '.toggle_all_selection', function(event) {
    if (this.checked) {
      var confirmation = confirm('Check all applicants?')
    } else {
      var confirmation = confirm('Uncheck all applicants?')
    }
    if (confirmation){
      var exclude = this.checked
      var list = $(this).selectable_list()
      var list_id = list.selectable_list_id()
      var selection = selections[list_id]
      if (!selection)
        selection = selections[list_id] = {}
      selection.items = []
      selection.mode = exclude ? 'exclude' : 'include'
      list.selectable_item_toggles().each(function(i, toggle) {
        toggle.checked = exclude
      })
    } else{
      $(this).attr("checked", !this.checked)
    }
  })

  /*
   * Changes the state of the selection mode checkbox in response to a click on
   * an item select checkbox.
   */
  $(document).on('click', '.toggle_item_selection', function(event) {
    $(this).selectable_toggle_all()[0].checked = true
  })

  /*
   * Sets up list selection checkboxes and internal state on page load, based on
   * the request's query params.
   */
  $(document).ready(function() {
    var params = split_url(location.href)[1]
    var selection_regex = /^(.*)\[(items|mode)\]$/
    $.each(params, function(i, pair) {
      var match = selection_regex.exec(pair.name)
      if (match) {
        var list = match[1]
        var selection = selections[list]
        var value = pair.value
        if (!selection)
          selection = selections[list] = {items: [], mode: 'exclude'}
        if (match[2] == 'mode')
          selection.mode = (value != 'include' ? 'exclude' : 'include')
        else
          selection.items = value.split('-')
      }
    })
    $('.toggle_all_selection').each(function(i, toggle) {
      var selection = selections[$(toggle).selectable_list_id()]
      toggle.checked = !selection || selection.items.length || selection.mode == 'exclude'
    })
    $('.toggle_item_selection').each(function(i, toggle) {
      var list = $(toggle).selectable_list()
      var selection = selections[list.selectable_list_id()]
      if (selection) {
        var exclude = selection.mode == 'exclude'
        var items = selection.items
        var index = items.indexOf(toggle.value)
        var in_list = index != -1
        toggle.checked = exclude != in_list
        if (in_list)
          items.splice(index, 1)
      }
    })
  })

  function split_url(url) {
    var url_parts = url.split('?', 2)
    var params = []
    if (url_parts.length > 1) {
      $.each(url_parts[1].split('&'), function(i, param) {
        var param_parts = param.split('=', 2)
        var name = param_parts[0];
        var value = param_parts[1];

        if (value != undefined) {
          value = value.replace(/\+/g, '%20');
        }

        params.push({
          name: decodeURIComponent(name),
          value: decodeURIComponent(value)
        });
      })
    }
    return [url_parts[0], params]
  }
})(jQuery, WEBADMIT);

$(document).on('click', '.selection_links a, .selection_link', function(_e) {
  WEBADMIT.SelectableList.updateHref(this);
});
