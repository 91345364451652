function proper_url (url, replace_with) {
  url = url.replace(/\/0\//, "/"+replace_with+"/");
  return url;
}

function is_form(jquery_response) {
  return jquery_response[0].tagName.toLowerCase() == "form" ? true : false;
}

window.activateColorForRequirementsToggles = function (reqId) {
  $(".requirement_column").find(".pop_over_toggle").removeClass('activated')
  var $markedRequirements = $("input[requirement_id="+ reqId+"]:checked")
  $markedRequirements.closest('.pop_over').find('.pop_over_toggle').addClass('activated')
}

function initialize_requirements() {
  $("#requirements_list").children(".blank_pane").remove();
}

window.afterLoadRequirementsSetup = function(){
  $("#requirements_add_course, #requirement_new, #criterion_new")
    .dialog({
      autoOpen: false,
      position: { my: "top", at: "top+150" },
      draggable: false,
      resizable: false,
      title: 'Add Course to Criterion'
    });
}

function removeComments() {
  $('.remove_comment').on('click', function(){
    var row = $(this).parents('tr:first');
    row.remove();
    $('.new_comment_button').focus();
    if (!row.hasClass('new_comment'))
      var id = $(this).attr('id').split('_').pop();
      var url = "/criteria/" + id + "/delete_comment";
      $.post(
        url,
        {
          ajax_data: id
        },
        function(data){
        }, "json");
  });
}

var $grip       = "<img class='icon grip' src='/images/icons/grip-16x16.png' alt='grip icon'/>";
var $spinner    = $("<p><img src='/images/ajax-black-spinner.gif' /> <span class='spinner_text'>Loading</span></p>");
var $remove     = "<a href='#' class='remove_comment' role='button'><img alt='Deactivate' class='icon delete' src='/images/icons/delete-red-16x16.png' title='Deactivate'></a>";

jQuery(document).ready(function($) {

  function updateColSpan(element){
    element.find('th.requirement_title').each(function(){
      var col_length = $(this).closest('tr').next().children('th').length;
      $(this).attr('colspan', col_length);
      $(this).closest('table').find('td.j-pre-req-td').attr('colspan', col_length);
    })
  }

  $(document).on('focus', '#async_content_requirements_pane, #async_content_prerequisites_pane', function(){
    var elm = $(this);
    var verifyLoading = setInterval(checkLoading, 500);
    function checkLoading(){
      if(elm.html() != ""){
        updateColSpan(elm);
        clearInterval(verifyLoading);
      }
    }
  })

  $(document).on('keydown', '.max_width, .default_comment', function(){
    var row = $(this).parents('tr:first');
    var row_index = row.index();
    var comment_value = row.find('.max_width').val();
    var total_count = $(this).closest('table').find('tbody tr:visible').length;
    var count_label = ' - Comment ' + row_index + ' of ' +  total_count;

    if(comment_value){
      var remove_label = 'Remove comment ' + comment_value + count_label;
    }else{
      var remove_label = 'Remove comment of row ' + row_index + count_label;
    }

    $('.new_comment_button').attr('aria-label', 'Remove comment');
    row.find('.remove_comment').attr('aria-label', remove_label);
  });

  $(document).on('focus', '.default_comment', function(){
    var row = $(this).parents('tr:first');
    var row_index = row.index();
    var comment_value = row.find('.max_width').val();
    var total_count = $(this).closest('table.ordered').find('tbody tr:visible').length;
    var count_label = ' - Comment ' + row_index + ' of ' +  total_count;

    if(comment_value){
      var default_comment_label = 'Make comment with description ' + comment_value + ' as default' + count_label;
    }else{
      var default_comment_label = 'Make comment of row ' + row_index + ' as default' + count_label;
    }

    $(this).attr('aria-label', default_comment_label);
  });

  $(document).on('focus', '.max_width', function(){
    var row = $(this).parents('tr:first');
    var row_index = row.index();
    var comment_value = $(this).val();
    var total_count = $(this).closest('table.ordered').find('tbody tr:visible').length;
    var count_label = ' - Comment ' + row_index + ' of ' +  total_count;

    if(comment_value){
      var descr_label = 'Comment description ' + comment_value + count_label;
    }else{
      var descr_label = 'Please enter comment description in row ' + row_index + count_label;
    }

    $(this).attr('aria-label', descr_label);
  });

  $(document).on('focus', '.new_comment_button', function(){
    $('.new_comment_button').attr('aria-label', 'Add comment row');
  });

  $(document).on('change', '.requirement_select', function(event) {
    var selectbox = $(this);
    var selectbox_id = $(this).attr('id');
    var applicantId = selectbox.data('applicant-id');
    var criterionId = selectbox.data('criterion-id');
    var applicantCriterionId = selectbox.data('applicant-criterion-id');
    var controller = selectbox.hasClass('criterion_status_select') ? '/set_fulfillment' : '/set_comment';
    var value = selectbox.val();
    var criterion_name = selectbox.attr('data-name');
    if(selectbox.hasClass('criterion_status_select')){
      var updated_state = selectbox.children("option").filter(":selected").text() == 'No' ? 'Criterion ' + criterion_name + ' unfulfilled.' : 'Criterion ' + criterion_name + ' force fulfilled.';
    }else{
      var updated_state = selectbox.val() == "" ? 'Comments of criterion ' + criterion_name + ' removed.' : 'Comments of criterion ' + criterion_name + ' updated.';
    }

    selectbox.prop('disabled', true);

    $.post('/criteria/' + criterionId + controller, {
      _method: 'put',
      applicant_criterion_id: applicantCriterionId,
      ajax_data: value,
      applicant_id: applicantId,
    }, function(data) {
      selectbox.prop('disabled', false);

      if (data.requirement) {
        selectbox.parents('tr.criterion:first').toggleClass('forced');
        $('#requirement_' + data.requirement_id).replaceWith(data.requirement);
        $('tr#criterion_' + criterionId + ' td').effect('highlight', { color: '#dfd' }, 2000);
        $('#requirement_' + data.requirement_id).find('.auto_submit').autoSubmitInput();
        //Status message for NVDA
        $('.req-status-text').text(updated_state);
        setTimeout(function(){$('#' + selectbox_id).focus()}, 200);
      }
    }, 'json');
  });

  $(document).on("change", ".criterion_force_done", function() {
      var $checkbox = $(this);
      var criterion_id = $checkbox.val();
      var applicant_id = $checkbox.attr("applicant_id");
      var checkbox_name = $checkbox.attr('name');
      var criterion_name = $checkbox.attr('data-name');
      var updated_state = $checkbox.prop('checked') ? 'force fulfilled' : 'unfulfilled'
      $.post("/criteria/"+criterion_id+"/force_fulfill", {_method: "put", applicant_id: applicant_id, applicant_criterion: {done: $checkbox.is(":checked")} }, function(data) {
        if (data.requirement) {
          $checkbox.parents("tr.criterion:first").toggleClass("forced");
          $("#requirement_" + data.requirement_id).replaceWith(data.requirement);
          apply_alternate_row_color();
          //Status message for NVDA
          $('.pre-status-text').text('Criterion ' + criterion_name + ' ' + updated_state)
          $("#requirement_" + data.requirement_id).find('#' + checkbox_name).focus();
        }
      }, "json");
  });

  $('.requirement_column').on('closedPopOver', '.pop_over', function () {
    var activeRequirement = $('#local_gpa_selector').val()
    activateColorForRequirementsToggles(activeRequirement)
  });

  $(document).on('click', "input.pop_up_add_course_to_criteria", function() {

    var checkbox = $(this)
    var criterion_id = checkbox.val();
    var requirement_id = checkbox.attr("requirement_id");
    var course_id = checkbox.attr("course_id");
    var checkbox_active = checkbox.is(':checked')

    if (checkbox_active) {
      var action = "add";
      var row = checkbox.closest('tr').prev('tr.course');
      row.addClass('prerequisite_highlite prerequisite_course');
      var original = $('.course.hide[data-course="' +  row.data('course') +'"]');
      original.addClass('prerequisite_highlite');
      var updated_state = $(this).attr('aria-label').replace('Apply', 'Applied');
    } else {
      var action = "remove";
      var row = checkbox.closest('tr').prev('tr.course');
      row.removeClass('prerequisite_highlite prerequisite_course');
      var original = $('.course.hide[data-course="' +  row.data('course') +'"]');
      original.removeClass('prerequisite_highlite');
      var updated_state = $(this).attr('aria-label').replace('Apply', 'Unapplied');
    }

    var $dismiss = checkbox.parents(".pop_over.opened:first");
    var url = "/criteria/"+criterion_id+"/"+action+"_course";
    var spinner = $spinner.insertBefore($(this).parents("ul:first"));

    $.post(url, {course_id: course_id, criterion_id: criterion_id}, function(data) {
      $dismiss.click();
      if (data.requirement) {
        $("#requirement_"+requirement_id).replaceWith(data.requirement);
      }
      if (data.applicant_requirements){
        $("#gpas_by_requirements").replaceWith(data.applicant_requirements);
      }

      // Display error message when the operation is not successful
      spinner.remove();
      if (data.status == false) {
        $("<p class='warning' tabindex='0'>" + data.error + "</p>").insertBefore( $('.pop_up_requirement_' + requirement_id) );
        $('p.warning').focus();
      } else {
        checkbox.addRemoveCourseHighlite();
        //Status message for NVDA
        $('.elec-trans-status-text').text(updated_state);
      }

    }, "json");

  });

  $(document).on("click", ".criterion_cell", function() {
    $(this).detailCells({activeClass: "criterion_cells_active", singleRecord: false, stackable: true, show: true});
  });

  // switch to using .toggle();
  $(document).on('click', "#requirements_criteria_expand", function () {
    if ($(this).text().toLowerCase() == "expand all") {
    $(this).text("Collapse All");
    $(".criterion_cell").each(function () {
      if (!$(this).hasClass("criterion_cells_active")) { $(this).click(); }
      });
    } else {
      $(this).text("Expand All");
      $(".criterion_cell").each(function () {
      if ($(this).hasClass("criterion_cells_active")) { $(this).click(); }
      });
    }
    return false;
  });

  var requirement_id = null;

  $(document).on('click', ".new_criterion_link", function () {
    requirement_id = $(this).attr("requirement_id");
    var dialog = $("#criterion_new");
    var title = $(this).attr("title");
    dialog.dialog("option", "title", title)
    dialog.dialog('open', { position: { my: "top", at: "top+150" } });
    $("input#criterion_requirement_id").val(requirement_id);
    return false;
  });

  var criterion_id = null;
  $(document).on('click', 'a.criterion_add_course', function() {
    criterion_id = $(this).attr('criterion_id');
    $('#requirements_add_course').dialog('open');
    return false;
  });

  $(document).on("click", ".toggle_criterion_details_list, .toggle_requirement_details", function(e){
    var toggle = $(this).attr("toggle");
    $(toggle).toggleClass("hide");
    if(e.target.className == "pre-action toggle_criterion_details_list"){
      var pre_action_key = $(this).uniqueId().attr('id');
      var target_element = $(toggle).find('.j-pre-req-det-content');
      target_element.attr({'tabindex': 0, 'data-origin': pre_action_key});
      toggleScreenReaderHelptext($(this), target_element);
      if($(toggle).hasClass('hide')){
        setToggleState($(this), 'Hide', 'Show');
      }else{
        setToggleState($(this), 'Show', 'Hide');
      }
      target_element.focus();
    }
    return false;
  });

  $(document).on('keyup', '.j-pre-req-det-content', function(event){
    var pressed = event.keyCode;
    if(pressed === 27){
      var parentID = '#' + $(this).attr('data-origin');
      setToggleState($(parentID), 'Hide', 'Show');
      $(parentID).trigger('click').focus();
    }
  });

  $('.new_comment_button').click(function(){

    var table           = $(this).parents('table:first');

    var using_default   = table.hasClass("using_default");
    var checkbox_val    = $('.toggle_default').prop('checked');
    var klass           = "";
    if (!checkbox_val){
      klass = "hide"
    }

    var row_index        = table.find('tr:last').index();
    var new_row          = $("<tr class='new_comment icon-column reorder_widget' tabindex='0'/>")
    var grip_cell        = $("<td>" + $grip + "</td>")
    var description_cell = $("<td><input class='max_width' type='text' name='criterion[criteria_comments_attributes]["+ row_index +"][text]' /></td>")
    var default_cell     = $("<td class='default_column " + klass + "'><fieldset><legend title='Comment Default' class='fieldset-legend'></legend><input value='"+ row_index +"' type='radio' name='criterion[criteria_comments_attributes][default]' class='default_comment' aria-label='default comment' /></fieldset></td>")
    var remove_cell      = $("<td>"  + $remove  + "</td>")

    new_row.append(grip_cell).append(description_cell).append(default_cell).append(remove_cell)

    table.append(new_row)

    new_row.find('.max_width').focus();
  removeComments();
});

  $('.toggle_default').click(function(){
    $('.default_column').toggleClass('hide');
  });

  $('.comments_enabled').click(function(){
    $('#comments').toggle();
  });

  $('.defaults_enabled').click(function(){
    var $attr      = $(this).prop('checked');
    var $defaults  = $('.default_column');
    $attr ? $defaults.removeClass('hide') : $defaults.addClass('hide');
  });
  removeComments();
  afterLoadRequirementsSetup();
});
