/* jquery.html5shiv.js */

// fix for IE missing elements
// IE won't style elements it doesn't know about

; (function(){
  var newElements = [
    'section', 'aside', 'footer', 'header',
    'nav', 'article', 'abbr', 'figure',
    'dialog', 'hgroup', 'mark', 'meter', 'time'
  ];

  $.each(newElements, function(){
    document.createElement(this);
  });

})();
