/* Author: Bill Chapman
Desc: Turn a select box in to an multi stage select
Requirements: jquery
Usage: Works on a select box with options groups, converts it to multistage
Output: html and bound objects for a multi stage select box

Usage: $('element').accordionSelect({options},callBackMethod = function(id,value) );
The call back method will have an id and a value passed in synononmous to value and text
for a select box entry
*/

//Should allow noConflict compatibility
//jQuery( function( $ ) {
$.fn.toMultiStage = function(options){
  //all options are initialized here for reference, options provided externally will take precedence
  options = $.extend( {
    containerId: "container_for_multi_stage",
    linkText: "Click here to select an item",
    stageTwoLabel:"Select source table to populate",
    stageOneMirrors: [],  //also update these on the page to the first stage value
    stageOneNameMirrors: [] //set these the name value instead of the id of the first stage selector
  }, options || {});

  var stageOneSelector = $('<select/>');
  var stageOneBlank = $("<option value=''/>-</option>");
      stageOneSelector.append(stageOneBlank);
  var stageTwoSelector = $(this);
  var stageOneId = $(this).attr("id") + "stage_one";
      stageOneSelector.attr("id",stageOneId);

  var optgroups      = stageTwoSelector.children('optgroup');
  var selectOptions  = new Object;
  var current_option = stageTwoSelector.val();

  optgroups.each(function(){
    var group_label = $(this).attr('label');
    var option_for_group = $("<option/>");
    option_for_group.attr("value",group_label).html(group_label);
    stageOneSelector.append(option_for_group);
    selectOptions[group_label] = $(this).html();

    //if the selected item is in the opt group we select it
    if( $(this).children('option:selected').length > 0 ){
      stageOneSelector.val($(this).attr("label"));
    }
  });

  stageOneSelector.on("change",function(){
    stageTwoSelector.html($(selectOptions[$(this).val()]));

    //also set the value of any items that we are specifiying as "should match"
    $.each(options.stageOneMirrors,function(){
        $(this).val(stageOneSelector.val());
    });

    //also set the value of any items that we are specifiying as "should match"
    $.each(options.stageOneNameMirrors,function(){
        $(this).val(stageOneSelector.text());
    });

  });

  stageTwoSelector.before(stageOneSelector);

};

//})( jQuery );
