WEBADMIT.LocalStatusRevertModal = {};

;(function ($, WEBADMIT) {
  WEBADMIT.LocalStatusRevertModal.show = function (data) {
    if (data && data.error) {
      return displayErrorModal(data.error);
    } else if (data && data.revert_summary) {
      return displayConfirmationModal(data.revert_summary);
    } else {
      return WEBADMIT.LocalStatusRevertModal.fail();
    }
  };

  WEBADMIT.LocalStatusRevertModal.fail = function () {
    return displayErrorModal('An error prevented the Local Status revert. Please, try again in a moment.');
  };

  function displayModal(content, buttons) {
    var $dialog = $('<div id="local-status-revert-modal"></div>');
    var defer = $.Deferred();
    $dialog.html(content);
    $dialog.data('accepted', false);
    $dialog.dialog({
      resizable: false,
      height: 'auto',
      width: 640,
      modal: true,
      title: 'Revert Local Status change',
      close: function () {
        var accepted = $dialog.data('accepted');
        $dialog.remove();
        if (accepted) {
          defer.resolve();
        } else {
          defer.reject();
        }
      },
      buttons: buttons
    });
    return defer.promise();
  }

  function displayConfirmationModal(content) {
    return displayModal(content, [
      {
        text: "Yes",
        "class": "primary_button",
        click: function () {
          $(this).data('accepted', true)
            .dialog('close');
        }
      },
      {
        text: "No",
        "class": "bordered_button",
        click: function () {
            $(this).dialog('close');
        }
      }
    ]);
  }

  function displayErrorModal(content) {
    return displayModal(content, {
      Close: function () {
        $(this).dialog('close');
      }
    });
  }
})(jQuery, WEBADMIT);
