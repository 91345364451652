/* course row highlite */
jQuery.fn.addRemoveCourseHighlite = function () {

  var checkbox = $(this)
  var siblings = checkbox.siblings('input')
  var checked = checkbox.is(':checked')

  var view_type = $('#local_gpa_selector option:selected')
  var view_type_id = view_type.val()
  var is_local_gpa = view_type.parent('optgroup').hasClass('select_local_gpas')
  var is_prerequisite = view_type.parent('optgroup').hasClass('select_requirements')

  // select the courses that are relevant
  var row = checkbox.parents('tr')

  if (is_local_gpa) {
    var courses = $('.course[data-course_id="' + row.data('course_id') + '"]')
  } else if(is_prerequisite) {
    var courses = $('.course[data-course_id="' + checkbox.attr('course_id') + '"]')
  }

  // only relevant for prereqs
  var checkboxes_active = checkbox.parents('ul').find('input:checked')

  if (is_local_gpa){
    var selector = "data-local_gpa_" + view_type_id
  } else if (is_prerequisite){
    var selector = "data-prerequisite_" + view_type_id
  }

  if (checked){
    addRowStyle(is_local_gpa, selector, courses)
  } else if (checkboxes_active.length == 0) {
    removeRowStyle(selector, courses)
  }
  // sync_rows
  var source = courses.first().clone().addClass('hide').removeClass('duplicate')
  source.find('.pop_over_target, .local_gpa_checkbox, .action, .right').attr('id', "");

  // make it like the others
  source.find('.pop_over_target').hide()
  source.find('.pop_up_requirement p').remove()

  // if (!checked && checked_siblings == 0) {
  // how is this working. srsly.
    source.find('.pop_over_toggle').removeClass('activated')
    source.find('.right.pop_over').removeClass('opened').addClass('closed')
  // }
  var original = courses.last();
  //Make checkbox checked explicitly and copy the updated content.
  checkbox.attr('checked', checked);
  source.find('.pop_over_target').html(checkbox.closest('tr.opened-popup td').html());
  source.find('.details').html(original.find('.details').html());
  source.find('p.screen-reader-only').attr('id', "");
  source.find('.content').removeAttr('aria-describedby');
  original.replaceWith(source)
}

function addRowStyle (is_local_gpa, selector, courses){
  var is_duplicate = courses.first().hasClass('duplicate_course')
  var prerequisite_feature = $('#highlite_prerequisites').is(':checked')
  var local_gpa_feature = $('#highlite_local_gpas').is(':checked')
  var duplicates_feature = $('#highlite_duplicate_courses').is(':checked')

  if (is_local_gpa && local_gpa_feature) {
    if (is_duplicate && duplicates_feature) {
      courses.css({'background': 'pink'})
    } else {
      courses.animate({backgroundColor: '#bbffff'}, 800)
    }
  } else if(!is_local_gpa && prerequisite_feature) {
    if (is_duplicate && duplicates_feature) {
      courses.css({'background': 'pink'})
    } else {
      courses.animate({backgroundColor: '#bbffbb'}, 800)
    }
  }
  // add the data element to all courses relevant
  // this should be removed when synced_courses is taken into account
  courses.attr(selector, true)
}

function removeRowStyle(selector, courses) {
  courses.animate({backgroundColor: "#fff"}, {duration: 800, specialEasing: {
     width: 'linear',
     height: 'easeOutBounce'
   }
  })
  courses.removeAttr(selector)
  var is_duplicate = courses.first().hasClass("duplicate_course")
  var has_duplicate_feature = $("#highlite_duplicate_courses").is(":checked")

  if (is_duplicate && has_duplicate_feature) {
    courses.animate({backgroundColor: '#ffff98'})
  } else {
    courses.animate({backgroundColor: '#fff'})
  }
}
