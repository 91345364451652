ScheduleInterview.ProgramSection = function() {
  ScheduleInterview.ViewSection.call(this);
  this.render = function(options) {
    return { hasPrograms: { programs: this.scopePrograms(options) }};
  };
  this.scopePrograms = function(options) {
    var results;
    var isScheduleInterviewActivity = options.activityType == 'ApplicantGateway::ScheduleInterviewActivity';
    if (options.programs) {
      results = options.programs.reduce(
        function(r, p) {
          if (!options.activityType || (-1 < p.activity_configurations.indexOf(options.activityType))) r.push(p);
            return r;
        },
        []
      );
    } else {
      results = options.interviewType.programs;
    }
    results = results.reduce(
      function(r, p) {
        if (!isScheduleInterviewActivity || (options.interviewType && options.interviewType.programs.find(function(e, i, a) { return p.id == e.id; }))) {
          r.push(p);
        }
        return r;
      },
      []
    );
    return results;
  };
  this.bind = function(context) {
    this.context = context;
    var __ = this;
    this.context.modal.find('a.programs_check_all').on(
      'click',
      function(event) {
        __.context.modal.find('form input.programs_checkbox').prop('checked', true);
      }
    );
    this.context.modal.find('a.programs_uncheck_all').on(
      'click',
      function(event) {
        __.context.modal.find('form input.programs_checkbox').prop('checked', false);
      }
    );
  };

};
ScheduleInterview.ProgramSection.prototype = Object.create(ScheduleInterview.ViewSection.prototype);
ScheduleInterview.ProgramSection.prototype.constructor = ScheduleInterview.ProgramSection;
