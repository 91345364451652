ScheduleInterview.ApplicantSection = function() {
  ScheduleInterview.ViewSection.call(this);
  this.render = function(options) {
    return {
      editRecord: options.editRecord,
      applicant: (options.applicantId ? { id: options.applicantId } : false),
      userIdentityFieldName: (options.editRecord ? 'assignment[user_identity_id]' : 'assignment_user_identity_ids[]')
    };
  };
};
ScheduleInterview.ApplicantSection.prototype = Object.create(ScheduleInterview.ViewSection.prototype);
ScheduleInterview.ApplicantSection.prototype.constructor = ScheduleInterview.ApplicantSection;
