ApplicantGateway.active = {};
ApplicantGateway.ActivityTypeSection = function() {
  ScheduleInterview.ViewSection.call(this);
  this.render = function(options) {
    return {
      hasActivityTypes: this.buildListView(
	'activityTypes',
	options.activityTypes,
	{ idKey: 'activity_type', nameKey: 'title', selectedId: options.activityType })
    };
  };
  this.bind = function(context) {
    this.context = context;
    if (this.lastActivity == undefined) this.lastActivity = 'ApplicantGateway::ScheduleInterviewActivity';
    var __ = this;
    this.context.modal.find('form select.activity_select').on(
      'change',
      function(event) { __.onActivityTypeSelectChanged(event); }
    );
    this.context.modal.find('form select.activity_select').trigger('change');
  };
 
  this.onActivityTypeSelectChanged = function(event) {
    if (event.target.value == this.lastActivity) return;
    if (event.target.value == 'ApplicantGateway::ScheduleInterviewActivity') {
      this.lastActivity = event.target.value;
      this.context.modal.find('form select.interview_type_select').trigger('change');
    } else {
      this.lastActivity = event.target.value;
      this.context.openModal({activityType: event.target.value}, 'useCache');
    }
  };
};
ApplicantGateway.ActivityTypeSection.prototype = Object.create(ScheduleInterview.ViewSection.prototype);
ApplicantGateway.ActivityTypeSection.prototype.constructor = ApplicantGateway.ActivityTypeSection;
