jQuery(document).ready(function ($) {
  $('#user_identity_switcher').change(function () {
    var user_identity_id = $(this).val();

    if (user_identity_id) {
      $(location).attr('href', '/user_identity_switcher/' + user_identity_id);
    }
  });

  var silhouette = $('#user_switcher_icon');
  var arrow = $('#cycle_switcher_hint_arrow');

  silhouette.click(function () {
    var atag = $(this);
    var user_switcher_container = $('#user_switcher_container');
    user_switcher_container.toggle();
    if (user_switcher_container.is(':visible')) {
      atag.attr('aria-label', atag.attr('title') + ' expanded');
      atag.attr('aria-pressed', true);
    } else {
      atag.attr('aria-label', atag.attr('title') + ' collapsed');
      atag.attr('aria-pressed', false);
    }
  });

  if (arrow.length !== 0) {
    var screen_width = $(document).width();
    var silhouette_right = screen_width - silhouette.offset().left;
    var icon_right = screen_width - arrow.offset().left;
    var icon_width = arrow.width();
    var required_change = icon_width + silhouette_right - icon_right;
    var current_padding = parseInt(arrow.css("padding-right"), 10);

    arrow.css({ paddingRight: current_padding + required_change - 12 });
  }
});
