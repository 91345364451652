$(document).on('click', '.applicant_details_header_field .pop_over_toggle', function() {
  if($('#applicant_details_header_field_popup').css('display') == 'block'){
    $('#applicant_details_header_field_popup').hide();
    setToggleState($(this), 'Hide', 'Show');
  }else{
    $(this).uniqueId();
    $('#applicant_details_header_field_popup').show();
    var content = $('#applicant_details_header_field_popup');
    content.attr({'tabindex': 0, 'data-origin': $(this).attr('id')});
    content.focus();
    toggleScreenReaderHelptext($(this), content);
  }
})

$(document).on('keyup', '#applicant_details_header_field_popup', function(event){
  var pressed = event.keyCode;
  if(pressed === 27){
    var parentID = '#' + $(this).attr('data-origin');
    setToggleState($(parentID), 'Hide', 'Show');
    $('#applicant_details_header_field_popup .pop_over_dismiss').trigger('click');
    $('a.pop_over_toggle').focus();
  }
});

$(document).on('click', '#applicant_details_header_field_popup .pickable_field', function(event) {
  var self = $(this)
  var field = self.closest('li').data('field')
  var $hidden_input = $('#applicant_details_header_field_id')
  $hidden_input.val(field.id)
  $hidden_input.change()
  $('.pop_over_toggle_wrapper').text(field.name)
  $(this).closest('.pop_over_target').hide()
  $('.j-field-list-msg').text('Field ' + field.name + ' selected.');
  $('a.pop_over_toggle').attr('aria-label', 'Selected field: ' + field.name + '. ' + 'Show the list of fields for the applicant header.');
  $('fieldset.form').find('input:first').focus();
  event.preventDefault ? event.preventDefault() : event.returnValue = false;
})

$(document).on('click', '#applicant_details_header_field_popup .pop_over_dismiss', function(event) {
  $(this).closest('.pop_over_target').hide()
})

$(document).on('change', 'input#applicant_photo', function(event) {
  var filepath = $(this).val();
  var regex = new RegExp('(\.jpg|\.jpeg|\.png|\.gif)$', 'i');
  var fileIsValid = regex.test(filepath);

  var helpText = $('form#applicant_photo_upload li[data-role="filetypes-help"]');

  if (!fileIsValid) {
    $(this).val(null);
    helpText.show();
  } else {
    helpText.hide();
  }
})
