WEBADMIT.getValidLocalStatuses = function(formParams, onSuccess) {
  var COMPLETED_STATUS = "complete";
  var LOCAL_STATUS_URI = "/lists/valid_local_statuses";
  var POLL_INTERVAL = 250;

  $.post(LOCAL_STATUS_URI, formParams, function(postResponse) {
    getLocalStatusesOrRetry(postResponse.id);
  });

  function getLocalStatusesOrRetry(jobId) {
    $.get(LOCAL_STATUS_URI + "/" + jobId.toString() + "?async_include=true", function(response) {
      if (response.status === COMPLETED_STATUS) {
        onSuccess(response.local_statuses, jobId);
      } else {
        setTimeout(function() {
          getLocalStatusesOrRetry(jobId);
        }, POLL_INTERVAL);
      }
    });
  }
};
