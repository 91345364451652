const ScheduleInterview = { active: {}, initializers: {} };

export default ScheduleInterview;

window.ScheduleInterview = ScheduleInterview

ScheduleInterview.hasPath = function() {
  var value = arguments[0];
  for (var index = 1; index < arguments.length; ++index) {
    var prop = arguments[index];
    if (value && value.hasOwnProperty(prop)) {
      value = value[prop];
    } else {
      return false;
    }
  }
  return true;
};

ScheduleInterview.initializers.SimpleModal = function() {
  ScheduleInterview.bindNewInterviewButton();
};

ScheduleInterview.initializers.BatchModal = function() {
  ScheduleInterview.bindBatchInterviewButton();
};

ScheduleInterview.initializers.ApplicantGatewayModal = function() {
  ScheduleInterview.bindApplicantGatewayInterviewButton();
};

ScheduleInterview.bindBatchInterviewButton = function() {
  $(document).on('click.batch_interview', 'a.interview_applicants_link', function (event) {
    event.preventDefault();
    if (!ScheduleInterview.active.BatchModal) ScheduleInterview.active.BatchModal = new ScheduleInterview.BatchModal();
    ScheduleInterview.active.BatchModal.openModal();
  });
};

ScheduleInterview.bindNewInterviewButton = function() {
  $(document).on('click.new_interview', '#new_interview_button', function () {
    ScheduleInterview.active.SimpleModal = new ScheduleInterview.SimpleModal(applicant_id, program_ids);
    ScheduleInterview.active.SimpleModal.openModal();
  });
};

ScheduleInterview.bindApplicantGatewayInterviewButton = function() {
  $(document).on(
    'submit.interview_activity',
    'form.applicant_gateway_schedule_interview_activity',
    function (event) {
      event.preventDefault();
      var programId = $(event.target).data("programid");
      var method = $(event.target).data("method");
      var url = event.target.action;
      ScheduleInterview.active.ApplicantGatewayModal = new ScheduleInterview.ApplicantGatewayModal(applicant_id, programId, method, url);
      ScheduleInterview.active.ApplicantGatewayModal.openModal();
      return false;
  });
}

window.show_interview_dialog = function(sender, interview) {
  if (!ScheduleInterview.active.SimpleModal) ScheduleInterview.active.SimpleModal = new ScheduleInterview.SimpleModal(applicant_id, program_ids);
  ScheduleInterview.active.SimpleModal.openModal({interview: interview});
  return false;
}
