ScheduleInterview.InterviewerSection = function() {
  ScheduleInterview.ViewSection.call(this);

  this.getAvailability = function (slotId, slots) {
    for (var index = 0;index < slots.length; ++index) {
      if (slots[index].id == slotId) return slots[index].unavailable_ids;
    }
    return [];
  };

  this.mapAvailability = function (slotId, userIdentities, slots) {
    for (var index = 0;index < userIdentities.length; ++index) {
      var value = userIdentities[index];
      value['unavailable'] = (
	$.inArray(value.id, this.getAvailability(slotId, slots)) > /*NOT FOUND*/ -1);
    }
    return userIdentities;
  };

  this.render = function(options) {
    var mappedValues = this.mapAvailability(
      (this.hasPath(options, 'interview', 'slot_id') ? options.interview.slot_id : false),
      options.interviewType.user_identities,
      options.interviewType.slots);
    return {
      hasUserIdentities: this.buildListView(
	'userIdentities',
	mappedValues,
	{
          selectedId: (this.hasPath(options, 'interview', 'user_identity', 'id') ?
		       options.interview.user_identity.id : 'none'),
          resultBuilder: function (value) {
            return {
              selected: value.selected,
              id: value.id,
              name: value.name,
              disabled: value.unavailable
            };
          }
	}
      )
    };
  };
};
ScheduleInterview.InterviewerSection.prototype = Object.create(ScheduleInterview.ViewSection.prototype);
ScheduleInterview.InterviewerSection.prototype.constructor = ScheduleInterview.InterviewerSection;
