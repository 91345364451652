$(document).ready(function() {

  var master_college_select = $('#advisor_request_master_college');
  var master_college_url = "/master_colleges";
  var state_select = $('#advisor_request_state_id');

  if (typeof state_select.val() !== "undefined") {
    update_master_colleges(state_select.val());
  }

  function append_master_college_option(index, master_college) {
    master_college_select.append($('<option>', {
      value: master_college.id,
      text: master_college.name
    }));
  }

  function update_master_colleges(state_id) {
    $.getJSON(master_college_url, { state_id: state_id }, update_master_college_select);
  }

  function update_master_college_select(master_colleges) {
    var original_value;

    master_college_select.empty();
    $.each(master_colleges, append_master_college_option);

    if (!master_college_select.data('original-value-seen')) {
      original_value = master_college_select.data('original-value');
      master_college_select.val(original_value);
      master_college_select.find('option[value="' + original_value + '"]').attr('selected', true);
      master_college_select.data('original-value-seen', true);
    }
  }

  state_select.change(function(eventData) {
    update_master_colleges(eventData.target.value);
  });

});
