$(function() {

  function enabledSubpanels() {
    return $('#fields_target');
  }

  function extractSubpanelData(element) {
    return {
      panel_name: element.data('panel-name'),
      prefix: element.data('prefix'),
      subpanel_name: element.text(),
      subpanel_id: element.data('subpanel-id'),
      page_type: element.data('page-type')
    }
  }

  function removeSubpanel(subpanelSelector) {
    var containerToRemove = $(subpanelSelector);
    var subpanelToReenable = $('#subpanel_' + containerToRemove.attr('data-subpanel-id'));
    subpanelToReenable.removeClass('taken');
    containerToRemove.remove();
    setupSubpanelLink();
  }

  function setupSubpanelLink() {
    $('.subpanel_link').off().on('click', function() {
      addSubpanelRow($(this));
    });
    $(".subpanel_link.taken").off("click");
  }

  function setupRemovePanelLink() {
    $('.remove_subpanel').off().on('click', function(event) {
      var element = $(this);
      var focusElement =  findfocusableElement(element);
      removeSubpanel("#picked_subpanel_" + element.attr("data-subpanel-id"));
      $('.j-panel-status').text(element.attr('data-subpanel-name') + ' subpanel removed from ' + element.attr('data-page-type') + '.')
      if(focusElement.length > 0){
        $('.panelIconStatus').text($(focusElement).find('a:first').attr('aria-label'))
        setTimeout(function(){ $(focusElement).find('a:first').focus() }, 200);
      }else{
        setTimeout(function(){ $('#all_subpanels').find('.tree_node_title:first a').focus()}, 200);
      }
    });
  }

  function findfocusableElement(element) {
    var focusId = ''
    if(element.closest('li').next('li').length == 1){
      focusId = element.closest('li').next('li')
    }else if(element.closest('li').prev('li').length == 1){
      focusId = element.closest('li').prev('li')
    }
    return focusId
  }

  function setupAddAllSubpanelLink() {
    $('#all_subpanels .add').off().on('click', function(e) {
      e.preventDefault();
      var panelName = $(this).closest('.subpanels_list').find('a.subpanel_link:first').attr('data-panel-name')
      var pageType = $(this).closest('.subpanels_list').find('a.subpanel_link:first').attr('data-page-type')
      $(this).closest('.subpanels_list').find('a.subpanel_link').each(function(index, field) {
        addSubpanelRow($(field));
      });
      $('.j-panel-status').text('Added all subpanels of ' + panelName + ' panel to ' + pageType + '.')

      event.preventDefault? event.preventDefault() : event.returnValue = false;
    });
  }

  function setupRemoveAllSubpanelLink() {
    $('#all_subpanels .remove').off().on('click', function(e) {
      e.preventDefault();
      var panelName = $(this).closest('.subpanels_list').find('a.subpanel_link:first').attr('data-panel-name')
      var pageType = $(this).closest('.subpanels_list').find('a.subpanel_link:first').attr('data-page-type')
      $(this).closest('.subpanels_list').find('a.subpanel_link').each(function(index, panel) {
        var subpanelId = $(panel).attr('data-subpanel-id');
        removeSubpanel("#picked_subpanel_" + subpanelId);
      });
      $('.j-panel-status').text('Removed all subpanels of ' + panelName + ' panel from ' + pageType + '.')

      event.preventDefault? event.preventDefault() : event.returnValue = false;
    });
  }

  function rowTaken(element) {
    var elementClass = element.attr('class')
    return (elementClass.search("taken") !== -1)
  }

  function orderedSubpanelRowInsert(content) {
    var myArray = $('.chosen_item');
    var contentCategoryLabel = findCategoryLabel(content);
    var contentSubcategoryLabel = findSubcategoryLabel(content);
    var inserted = false;

    for (var i = 0; i < myArray.length; i++) {
      if ((findCategoryLabel(myArray[i]) >= contentCategoryLabel) && (findSubcategoryLabel(myArray[i]) > contentSubcategoryLabel)) {
        $(content).insertBefore(myArray[i])
        inserted = true
        i = myArray.length
      }
    }

    if (!inserted) {
      enabledSubpanels().append(content)
    }
  }

  function findCategoryLabel(element) {
    return ($(element).find(".category_label").text().trim())
  }

  function findSubcategoryLabel(element) {
    return ($(element).find(".field").text().trim())
  }

  function addSubpanelRow(element) {
    if (rowTaken(element) == false) {
      $.mustacheLoader('subpanel_row', function(template) {
        var content = $($.mustache(template, extractSubpanelData(element)));
        orderedSubpanelRowInsert(content);
        setupRemovePanelLink();
      });

      element.addClass('taken');
      $('.j-panel-status').text(element.text() + ' subpanel added to ' + element.attr('data-page-type') + '.')
      element.off('click');
    }
  }

  $(document).ready(function() {
    setupSubpanelLink();
    setupRemovePanelLink();
    setupAddAllSubpanelLink();
    setupRemoveAllSubpanelLink();
  });
});
