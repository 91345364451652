// Extends Bloodhound data engine to provide a default configuration
// for fetching field lookup values
function FieldLookupDatasource(field, options) {
  var opts = $.extend({}, WEBADMIT.FieldLookupDatasource.defaults, options);
  var engineOptions = {
    initialize: false,
    limit: opts.limit,
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace("name"),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    identify: function (obj) {
      return obj.value;
    }
  };

  engineOptions.prefetch = {
    cache: opts.prefetch.cache,
    url: '/fields/{{field_id}}?for_clause_value_list=true'.replace('{{field_id}}', field.id),
    transform: function (response) {
      return response.field_values;
    }
  };
  Bloodhound.call(this, engineOptions);
}

// WEBADMIT.FieldLookupDatasource extends Bloodhound
$(document).on('bloodhound-loaded', function() {
  WEBADMIT.FieldLookupDatasource = FieldLookupDatasource;
  WEBADMIT.FieldLookupDatasource.prototype = Object.create(Bloodhound.prototype);
  WEBADMIT.FieldLookupDatasource.prototype.constructor = FieldLookupDatasource;
  WEBADMIT.FieldLookupDatasource.defaults = {
    limit: 1000,
    prefetch: {
      cache: false
    }
  };
})
