(function ($) {
  $.fn.linkDatepickerMinDateTo = function (linkedDateInput, enforceMinDateOffset) {
    enforceMinDateOffset = typeof enforceMinDateOffset !== 'undefined' ? enforceMinDateOffset : true;
    var $el = this;
    var $linkedDate = $(linkedDateInput);
    var dateFormat = $linkedDate.datepicker('option', 'dateFormat');
    var minDateOffset = $el.data('min-date');
    $linkedDate.datepicker('option', 'onSelect', function () {
      $linkedDate.change();
    }).change(function () {
      var configMinDate = new Date();
      var dateText = $linkedDate.val();
      var newMin, nextDayAfterSelectedDate;

      if ('' !== dateText && isDate('dateText')) {
        configMinDate.setDate(configMinDate.getDate() + minDateOffset);

        var nextDayAfterSelectedDate = $.datepicker.parseDate(dateFormat, dateText);
        nextDayAfterSelectedDate.setDate(nextDayAfterSelectedDate.getDate() + 1);

        if (!enforceMinDateOffset || (nextDayAfterSelectedDate > configMinDate)) {
          var newMin = $.datepicker.formatDate(dateFormat, nextDayAfterSelectedDate);
        } else {
          var newMin = minDateOffset;
        }

        $el.datepicker('option', 'minDate', newMin);
      }
      $el.val('');
    });

    return this;
  };
}(jQuery));
