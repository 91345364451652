// -- ScheduleInterview.Modal ^
ScheduleInterview.Modal = function (options) {
  if (!options) options = {};
  this.interviewTypesUrl = '/interview_types';
  this.status = 'uninitialized';
  this.modal = false;
  this.interview = false;

  this.applicantId = options.applicantId;
  this.programIds = options.programIds;
  this.options = options;
  this.title = options.title

  this.getData = function(callback, useCache) {
    var __ = this;
    if (useCache && callback) {
      callback();
    } else {
      $.getJSON(
        this.interviewTypesUrl,
        $.extend({ program_ids: __.programIds }, options.interviewTypeOptions),
        function (data, status, xhr) {
          __.interviewTypes = data;
          __.viewBuilder = __.getViewBuilder();
          __.status = status;
          if (callback) callback();
        }
      );
    }
  };

  this.injectModalDialog = function(dialogElement) {
    var __ = this;
    $('body').append(__.modal);
    var pos = { my: 'top+10%', at: 'top', collision: 'flipfit' };
    if (__.lastTop && __.lastLeft) {
      pos.my = 'left+' + __.lastLeft + ' top+' + __.lastTop;
      pos.at = 'left top';
    }
    __.modal.dialog({
      autoOpen: true,
      modal: true,
      draggable: true,
      resizable: true,
      minWidth: 400,
      minHeight: 50,
      maxHeight: 768,
      maxWidth: 600,
      title: this.title,
      height: __.lastHeight,
      width: __.lastWidth,
      position: pos,
      close: function (e, ui) {
        if(__.modal.dialog().find('input[name="assignment[id]"]').length == 0){
          var closeId = '#new_interview_button';
        }else{
          var closeId = '.entity-interview-' + __.modal.dialog().find('input[name="assignment[id]"]').val();
        }
        if (__.status == 'waiting') {
          e.preventDefault();
          return false;
        }
        __.modal.dialog('destroy').remove();
        if (__.afterClose) __.afterClose();
        $(closeId).focus();
      },
      dragStop: function (e, ui) {
        __.lastTop = ui.position.top;
        __.lastLeft = ui.position.left;
      },
      resizeStop: function (e, ui) {
        __.lastWidth = ui.size.width;
        __.lastHeight = ui.size.height;
      }
    });
  };

  this.getViewBuilder = function() { console.log('ScheduleInterview.Modal.getViewBuilder UNIMPLEMENTED!') };

  this.beforeOpen = function() { };

  this.openModal = function(renderData, useCache) {
    var __ = this;
    this.beforeOpen();
    __.getData(function() {
      $.mustacheLoader(
        'interview_activity_modal_dialog',
        function (template) {
          var view = __.getViewBuilder().buildView(renderData);
          var modalHTML = $.mustache(template, view);
          if (__.modal && __.modal.hasClass('ui-dialog-content')) {
            __.modal.dialog('destroy').remove();
          }
          __.modal = $(modalHTML);
          __.getViewBuilder().bindSections(__);
          __.modal.find('button.dialog_submit_button').on('click', function(event) { __.submit(event); });
          __.modal.find('button.dialog_cancel_button').on('click', function(event) { __.closeModal(event); });
          __.injectModalDialog(__.modal);
          if(view.hasUserIdentities && !view.editRecord) {
            __.setupUserIdentitySelection(view.hasUserIdentities.userIdentities.filter(function(ui) {
              return ui.id !== 'none';
            }));
          }
        }
      );
    }, useCache);
  };

  this.closeModal = function(event) {
    if (this.modal) this.modal.dialog('close');
  };

  this.defaultSubmitOptions = function() {
    return {
      onSuccess: function() { console.log('WARNING: options.onSuccess is not implemented!'); return true; },
      onError: function() {},
      beforeSubmit: function() {},
      url: function() { console.log('WARNING: options.url is not implemented!'); return '/'; },
      httpMethod: function() { console.log('WARNING: options.httpMethod is not implemented!'); return 'POST'; },
      data: function() { console.log('WARNING: options.data is not implemented!'); return { }; },
      dataType: function() { return 'json' },
      contentType: function() { return 'application/x-www-form-urlencoded'; }
    };
  };

  this.getSubmitOptions = function() {
    return __.defaultSubmitOptions();
  };

  this.submit = function () {
    var __ = this;
    if (__.status == 'waiting') return;
    __.status = 'waiting';
    var options = __.getSubmitOptions();
    options.beforeSubmit();
    $.ajax({
      url: options.url(),
      type: options.httpMethod(),
      dataType: options.dataType(),
      contentType: options.contentType(),
      data: options.data(),
      success: function(data, status, xhr) {
        __.status = 'ready';
        if (options.onSuccess(data, status, xhr)) {
          __.closeModal();
        }
      },
      error: function(xhr, status, error) {
        __.status = 'ready';
        console.log(JSON.stringify(xhr));
        options.onError(xhr, status, error);
        alert('ERROR: ' + status + ' -> ' + error);
        document.write(xhr.responseText);
      }
    });
  };

  this.setupUserIdentitySelection = function (userIdentities) {
    var data = {
      userIdentities: userIdentities,
      selectableIds: userIdentities.filter(function (ui) {
        return !ui.disabled;
      }).map(function (ui) {
        return ui.id;
      }),
      selectedIds: userIdentities.filter(function (ui) {
        return ui.selected;
      }).map(function (ui) {
        return ui.id;
      })
    };
    Vue.createApp({ data() { return data; } })
       .component('multi-select-checkbox', MultiSelectCheckboxComponent)
       .mount(this.modal.find('#user-identities-selection')[0]);
  };
};
// -- ScheduleInterview.Modal $
