ScheduleInterview.ViewSection = function() {
  this.hasPath = ScheduleInterview.hasPath;

  this.render = function(options) {
    return {};
  };

  this.bind = function(context) {
  };

  this.buildListView = function(listName, itemCollection, options) {
    if (!itemCollection || !itemCollection.length) return [];
    if (!options) options = {};
    var selectedId = (options.selectedId || 'none');
    var idKey = (options.idKey || 'id');
    var nameKey = (options.nameKey || 'name');
    var selected = (options.selectedName || 'selected');
    var resultBuilder = (options.resultBuilder || function (value, idKey, nameKey) {
      return {
        id: value[idKey],
        name: value[nameKey],
        selected: value.selected
      };
    });
    var listView = {};
    listView[listName] = [];
    for (var index = 0; index < itemCollection.length; ++index) {
      // Extend here to create shallow copy since we modify 'value'.
      var value = $.extend({}, itemCollection[index]);
      if (value[idKey] == selectedId) value.selected = selected;
      listView[listName].push(resultBuilder(value, idKey, nameKey));
    }
    return listView;
  };
};
