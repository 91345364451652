WEBADMIT.datePickerMinimumDate = {};

;(function ($, WEBADMIT) {
  WEBADMIT.datePickerMinimumDate.initializeDatePickers = function (startDateId, endDateId, minDateOffset) {
    $('.datepicker').each(function () {
      var $dateInput = $(this);
      var datePickerMinDate = $dateInput.data('min-date');
      var datePickerOptions = {
        minDate: datePickerMinDate,
        changeMonth: true,
        changeYear: true
      };
      $dateInput.datepicker(datePickerOptions);
    });

    $(endDateId).linkDatepickerMinDateTo(startDateId, minDateOffset);
  };
})(jQuery, WEBADMIT);
