WEBADMIT.ListStatus = function ListStatus(listId, statusUri, showUri, pollingInterval, location) {
  this.pollingInterval = pollingInterval;
  this.listId = listId;
  this.statusUri = statusUri;
  this.showUri = showUri;
  this.location = location || window.location;
};

WEBADMIT.ListStatus.prototype = {
  refreshStatus: function() {
    var that = this;
    setTimeout( function() {
      that.retrieveListStatus();
    }, this.pollingInterval);
  },

  handleListAvailability: function(data) {
    if (data.ready == true) {
      this.location.assign(this.showUri);
    } else {
      this.refreshStatus();
    }
  },

  retrieveListStatus: function() {
    var that = this;
    $.get(this.statusUri)
      .done( function(data) {
        that.handleListAvailability(data);
      });
  },
};
