var PageUtils = {};
window.PageUtils = PageUtils;

PageUtils.jsonFlashMessage = function(xhr) {
  var flashJSON = JSON.parse(xhr.getResponseHeader('X-Flash-Messages'));
  var sections = ['positive', 'negative', 'notice'];
  for (var index = 0;index < sections.length; ++index) {
    var name = sections[index];
    var element = $('div.'+name);
    element.attr('style', 'display: none;');
    if (flashJSON[name]) {
      element.text(flashJSON[name]);
      element.attr('style','');
    }
  };
};

if (!Array.prototype.find) {
  Array.prototype.find = function(predicate) {
    if (this == null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length >>> 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
	return value;
      }
    }
    return undefined;
  };
}
