;(function ($) {
  WEBADMIT.nestedlistform = {
    typeahead: {
      // use typeahead widget for lookup fields
      active: false,
      // lookup value number must be greater than the minimum to activate the widget
      minimum: 10
    }
  };

  var InputHelper = {
    decimal: function(input) {
      input.numeric();
      input.attr('placeholder', '<Enter Numeric Values Only>');
      input.attr('aria-label', 'Field value');
    },
    integer: function(input) {
      input.numeric({ decimal: false });
      input.attr('placeholder', '<Enter Integer Numeric Values Only>');
      input.attr('aria-label', 'Field value');
    },
    datetime: function(input) {
      input.attr('placeholder', '<Required>');
      input.attr('required', '');
      input.attr('aria-label', 'Field value');
      input.addClass('date_picker');
    },
    string: function(input) {
      input.attr('placeholder', '');
      input.attr('aria-label', 'Field value');
    },
    lookup: function(input, field, value) {
      var source = new WEBADMIT.FieldLookupDatasource(field);
      var promise = source.initialize();
      promise.done(function () {
        var values = source.all().sort(function (itemA, itemB) {
          if (itemA.name < itemB.name) {
            return -1;
          } else if (itemA.name > itemB.name) {
            return 1;
          } else {
            return 0;
          }
        });

        if (WEBADMIT.nestedlistform.typeahead.active && values.length > WEBADMIT.nestedlistform.typeahead.minimum) {
          input.listLookupValueField(field, source, value);
        } else {
          var select = $('<select class="clause_option clause_value valid" aria-label="list" name="list[clauses][][value]"><option selected>Select Value to Match</option></select>');
          $.each(values, function (idx, f) {
            select.append($('<option value="' + f.value + '">' + f.name + '</option>'));
          });
          input.replaceWith(select);
          if (typeof value !== 'undefined') select.val(value);
        }
      });
      return promise;
    }
  };

  var query_row_id = 0

  function add_clause_row(clause) {

    $.mustacheLoader('query_builder_row', function(data) {
      var aria_label = 'Show the list of fields for making criteria/rule.'
      var clause_row = $($.mustache(data, {
        clause_id: (clause ? clause.id : null),
        field_id: (clause ? clause.field_id : null),
        pop_id: 'pop_over_row_' + (clause ? clause.id : ++query_row_id),
        toggle_text: clause && clause.field ? clause.field.name : 'Click to Select Field',
        label_text: clause && clause.field ? 'Selected field: ' + clause.field.name + '. ' + aria_label  : aria_label
      }))
      if (clause) {
        clause_row.data('field', clause.field);
        if(clause.field) {
          clause_row.find('a.pop_over_toggle').attr('title', clause.field_category_name + ' - ' + clause.field.name);
        }
        update_operator_select(clause_row.find('.clause_op'), clause.field_operator, clause.value);
      }
      $('#clauses').append(clause_row)
      clause_row.find('.pop_over').initPopOver()
      clause_row.find('a.pop_over_toggle.tipsy-top').tipsy({gravity: 's'});
    })
  }

  function update_operator_select(clause_op, default_operator, value) {
    var clause_row = clause_op.closest('.clause_row')
    var field = clause_row.data('field')
    if (field && field.field_type) {
      var operators = field.field_type.field_operators
      default_operator = default_operator || field.field_type.default_field_operator
      clause_op.empty()
      for (var i = 0; i < operators.length; ++i) {
        var field_operator = operators[i]
        var selected = (field_operator == default_operator)
        var selected_attribute = selected ? ' selected' : ''
        var option = $('<option' + selected_attribute + ' value="' + field_operator.id + '">' + field_operator.name + '</option>')
        option.data('field_operator', field_operator)
        clause_op.append(option)
        if (selected || operators.length == 1)
          update_value_field(option, value)
      }
    }
  }

  function lastDaysOrHoursOperator(fieldOperator) {
    var WITHIN_LAST_DAYS = 13;
    var WITHIN_LAST_HOURS = 16;

    return (fieldOperator.id == WITHIN_LAST_DAYS || fieldOperator.id == WITHIN_LAST_HOURS);
  }

  WEBADMIT.nestedlistform.update_value_field = update_value_field;
  function update_value_field(option, value) {
    var clause_row = option.closest('.clause_row')
    var field = clause_row.data('field')
    var field_value_container = clause_row.find('.field_value_container')
    var field_operator = option.data('field_operator')
    var comparison_type = field_operator.comparison_type
    var input
    var previous_comparison_type = clause_row.data('comparison_type')
    if (!comparison_type) {
      comparison_type = field.field_type
    }

    if ((previous_comparison_type != comparison_type) || lastDaysOrHoursOperator(field_operator)) {
      var comparison_type_name = comparison_type.name;
      field_value_container.empty();
      input = $('<input class="clause_option clause_value" placeholder="loading data" name="list[clauses][][value]">');
      field_value_container.append(input);
      clause_row.data('comparison_type', comparison_type);
      if(field_operator.id == 14 || field_operator.id == 15) {
        input.empty();
        input.hide();
        field.field_values = ''
      } else if(field_operator.id == 13 || comparison_type_name == 'float' || comparison_type_name == 'numeric') {
        InputHelper.decimal(input);
      } else if(comparison_type_name == 'date' || comparison_type_name == 'datetime') {
        InputHelper.datetime(input);
      } else if(comparison_type_name == 'integer') {
        InputHelper.integer(input);
      } else if(comparison_type_name == 'string') {
        InputHelper.string(input);
      }
      if(field.field_values || comparison_type_name == 'lookup') {
        return InputHelper.lookup(input, field, value);
      }
      input.val(value);
    }
  }

  $(document).on('click', '.add_clause',
    function(event) {
      add_clause_row()
      event.preventDefault ? event.preventDefault() : event.returnValue = false;
    }
  )

  $(document).on('click', '.remove_clause',
    function(event) {
      var clause_row = $(this).closest('.clause_row')
      if (clause_row.siblings().length) {
        var hidden_tree_for_fields = clause_row.find('#hidden_tree_for_fields')
        if (hidden_tree_for_fields) {
          hidden_tree_for_fields.addClass('hide')
          $('#query_builder').append(hidden_tree_for_fields)
        }
        clause_row.remove()
      }
      else
        alert('Nothing left to remove')
      event.preventDefault ? event.preventDefault() : event.returnValue = false;
    }
  )

  $(document).on($.browser.msie ? 'click' : 'change', '.clause_op',
    function(event) {
      update_value_field($(this.options.item(this.selectedIndex)));
    }
  );

  $(document).on('click', '.pop_over_field_picker_container .pickable_field',
    function(event) {
      var self = $(this)
      var li = self.closest('li')
      var clause_row = li.closest('.clause_row')
      var field = li.data('field')
      var field_category = $(this).closest('.opened').find('.tree_node_title').text()
      var pop_over = self.closest('.pop_over')
      clause_row.find('.field_id_input').val(field.id)
      clause_row.data('comparison_type', null)
      clause_row.data('field', field)
      update_operator_select(clause_row.find('.clause_op'))
      pop_over.find('.pop_over_toggle_wrapper').text(field.name)
      $.closePop(pop_over, pop_over.find('.pop_over_target'),
          pop_over.find('.pop_over_toggle .action'))

      $('.j-field-list-msg').text('Field ' + field.name + ' selected.');
      var field_operator = clause_row.find('.field_operator_container').children();
      var field_value = clause_row.find('.field_value_container').children();
      field_operator.attr('aria-label', 'Please select an operator for the field ' + field.name);
      field_value.attr('aria-label', 'Please enter the value for the field ' + field.name);
      if(field_value.hasClass('date_picker')){
        field_value.attr('aria-label', field_value.attr('aria-label') + ' in mm/dd/yyyy format');
        field_value.attr('data-picker-status', 'list-picker-alert');
      }
      clause_row.find('.remove_clause').attr('aria-label', 'Remove criteria/rule of the field ' + field.name);
      clause_row.find('a.pop_over_toggle').attr('aria-label', 'Selected field: ' + field.name + '. ' + 'Show the list of fields for making criteria/rule.');
      clause_row.find('a.pop_over_toggle').attr('original-title', field_category + ' - ' + field.name);
      field_operator.focus();
      event.preventDefault ? event.preventDefault() : event.returnValue = false;
    }
  )

  $(document).on('click', '.pop_over_field_picker_toggle .pop_over_toggle',
    function(event) {
      var contents = $('#hidden_tree_for_fields')
      var other_pops = $()
      var pop_over = $(this).closest('.pop_over')
      var container = pop_over.find('.pop_over_field_picker_container')
      $('.pop_over').each(function() {
        if (this != pop_over[0])
          other_pops = other_pops.add(this)
      })
      $.closeAllPop(other_pops)
      contents.removeClass('hide')
      container.append(contents)
    }
  )

  $(document).on('click', '#hidden_tree_for_fields .toggle_tree',
    function(event) {
      var li = $(this).closest('li')
      var fields_list = li.find('.fields_list')
      var tree = $('#hidden_tree_for_fields')
      var params = ''
      var firstClass = true;
      $.each(['mergeable', 'searchable', 'scorable', 'addable'], function(idx, klass) {
        if(tree.hasClass(klass)) {
          params += (firstClass ? "?" : "&") + klass + '=true'
          firstClass = false
        }
      })
      if (!fields_list.children().length) {
        var category = li.data('category')
        if (category.fields)
          update_field_category(li)
        else {
          $.getJSON('/field_categories/' + category.id + params,
              function(data, textStatus) {
            var fields = data.fields
            category.fields = fields
            update_fields(fields)
            update_field_category(li)
          })
        }
      }
      event.preventDefault ? event.preventDefault() : event.returnValue = false;
    }
  )

  function update_field_category(li) {
    var category = li.data('category')
    var fields = category.fields
    var fields_list = li.find('.fields_list')
    var field_length = fields.length;
    for (var i = 0; i < field_length; ++i) {
      var field = fields[i]
      var field_li = $('<li><div class="child_node closed depth_2 tree_node"><div class="tree_leaf"><a class="available pickable_field string" href="#" role="button" aria-label="Press enter to select the field ' + field.name + ' of category ' + category.name + '. ' + (i + 1) + ' of ' + field_length + '">' + field.name + '</a></div></div></li>')
      field_li.data('field', field)
      fields_list.append(field_li)
    }
  }

  function update_fields(fields) {
    var field_types_by_id = {}
    var i
    for (i = 0; i < field_types.length; ++i) {
      var field_type = field_types[i]
      field_types_by_id[field_type.id] = field_type
    }
    for (i = 0; i < fields.length; ++i) {
      var field = fields[i]
      field.field_type = field_types_by_id[field.field_type_id]
      if ((typeof field.field_type !== 'undefined') && field.field_type.name === 'boolean') {
        field.field_values = [
          {name: 'Yes', value: 'true'},
          {name: 'No', value: 'false'}
        ]
      }
    }
  }

  WEBADMIT.load_field_tree = function load_field_tree($) {
    if (window.field_types) {
      var field_operators_by_id = {}
      var field_type
      var field_types_by_id = {}
      var fields_by_id = {}
      var i
      var tree = $('#hidden_tree_for_fields ul')
      tree.empty();
      for (i = 0; i < field_types.length; ++i) {
        field_type = field_types[i]
        field_types_by_id[field_type.id] = field_type
      }
      for (i = 0; i < field_operators.length; ++i) {
        var field_operator = field_operators[i]
        if (field_operator.comparison_type_id) {
          field_operator.comparison_type =
              field_types_by_id[field_operator.comparison_type_id]
        }
        field_operators_by_id[field_operator.id] = field_operator
      }
      for (i = 0; i < field_types.length; ++i) {
        var field_type_operators = []
        field_type = field_types[i]
        for (var j = 0; j < field_type.field_operator_ids.length; ++j) {
          field_type_operators.push(
              field_operators_by_id[field_type.field_operator_ids[j]])
        }
        field_type.default_field_operator =
            field_operators_by_id[field_type.default_field_operator_id]
        field_type.field_operators = field_type_operators
      }
      for (i = 0; i < categories.length; ++i) {
        var category = categories[i]
        var fields = category.fields
        var li_str = '<li>' +
          '<div class="child_node closed depth_1 tree_node">' +
          '<div class="toggle_tree tree_display tree_node_title">' +
          '<div class="arrow"></div>' +
          '<a href="javascript:void(0)" data-role="select-node" role="button" aria-expanded="false" aria-label="Press enter to open the category ' + category.name + '">' +
          category.name +
          '</a>' +
          '</div>' +
          '<ul class="fields_list" data-role="fields-list"></ul>' +
          '</div>' +
          '</li>'
        var li = $(li_str)
        if (fields) {
          for (var j = 0; j < fields.length; ++j) {
            var field = fields[j]
            fields_by_id[field.id] = field
          }
          update_fields(fields)
        }
        li.data('category', category)
        tree.append(li)
      }
      if (list_clause.length) {
        for (i = 0; i < list_clause.length; ++i) {
          var clause = list_clause[i]
          clause.field = fields_by_id[clause.field_id]
          clause.field_operator = field_operators_by_id[clause.field_operator_id]
          add_clause_row(clause)
        }
      }
      else
        add_clause_row()
    }
  }

  $(document).ready(WEBADMIT.load_field_tree);
}(jQuery));

// Datepicker function
$(document).ready(function() {
  $(document).on('click', '.date_picker', function () {
    var picker = $(this);
    var targetRow = picker.closest('div');
    picker.append('<div class="picker"></div>');
    var dialog = $('.picker').dialog({
    modal: true,
    autoResize: true,
    draggable: true,
    closeOnEscape: false,
    open: function(event, ui) {
      $('.ui-widget-overlay').on('click', function(event,ui) {
        $('.picker').dialog('close');
      });
      $(this).datepicker({
      onSelect: function(selectedDate){
        targetRow.find('.date_picker').val(selectedDate);
        $(".picker").dialog('close');
      }}).datepicker( 'show' );
    },
    width: 'auto',
    height: 'auto',
    overflow: 'none',
    showButtonPanel: true,
    close: function(event, ui) {
      $(this).closest('.ui-dialog').remove();
      $(this).remove();
    }
    });
  });
});
