function setForceSso(value){
  var check = value === true ? 1 : 0
  $('.assign_force_sso').find('input[type=checkbox]').prop( { checked: value, disabled: value } );
  $('#user_use_sso').val(check);
}

$(document).ready(function() {
  $(".reset_search_criteria").click(function(e) {
    e.preventDefault();
    reset_search_criteria(e);
  });

  $('#user_user_identities_work_group_id').change(function(event) {
    var value = $(event.target).val();

    if (value) {
      $('#role_assignments').hide();
    } else {
      $('#role_assignments').show();
    }
  });

  function toggleForceSso(association, select) {
    if (association.force_sso) {
      select.attr("cas-sso", true);
      setForceSso(true);
    } else {
      select.attr("cas-sso", false);
      setForceSso(false);
    }
  }

  $('select#user_user_identities_cas_id').on('change', function() {
    var select = $(this);
    var association_id = select.val();
    var form = select.closest('form');
    var organizations = form.find('#user_user_identities_organization_id');
    var dd = organizations.closest('dd');
    var dt = dd.prev('dt');
    var userType = $('#user_user_identities_type').val();
    organizations.empty();
    form.find('.programs').empty();
    $('#no_organization_message').show();

    if (association_id && association_id != 'none') {
      $.getJSON('/associations/' + association_id, null, function(association, textStatus) {
        association.has_organizations = !!association.organizations.length;
        association.admin_selectable = true;
        association.nullable = true;
        $.mustacheLoader('organization_options', function(template) {
          organizations.append($($.mustache(template, association)));
        })
        if (userType == 'Organization') {
          toggleForceSso(association, select)
        }
      })

      $('#organization_id_spinner').hide();
    } else {
      dd.hide();
      dt.hide();
    }
  });

  $('select#user_user_identities_organization_id').change(function() {
    var select = $(this);
    var form = select.parents('form');
    var organization_id = select.val();
    var organization_user = form.find('.organization_user');
    var programs = form.find('.programs');
    programs.empty();

    if (organization_id && organization_id != 'none') {
      $.getJSON('/organizations/' + organization_id, null, function(organization, textStatus) {
        $.mustacheLoader('program_checkboxes', function(template) {
          programs.append($($.mustache(template, organization)));
          organization_user.show();
          $('#no_organization_message').hide();
        })
        if($('select#user_user_identities_cas_id').attr('cas-sso') === 'false') {
          if (organization.force_sso) {
            setForceSso(true);
          } else {
            setForceSso(false);
          }
        }
      })
    } else {
      organization_user.hide();
    }
  });

  $('#user_user_identities_type').change(update_association_and_organization_selects);
  update_association_and_organization_selects({target: $('#user_user_identities_type')});

  $('#assignment_organization_select').change(redirect_to_edit_user_identity);

  $('table#cas_user_identity').on('click', '.create_cas_user_identity_link', create_cas_user_identity);

  $('.user_identity_cas_identifier_select').on('change', load_cycles);
});

function edit_user_path(user_identity_id) {
  return "/users/" + user_identity_id + "/edit";
}

function create_cas_user_identity() {
  var $this = $(this);
  var cas_identifier_id = $this.closest('tr').find(".user_identity_cas_identifier_select").val();
  var cycle_id = $this.closest('tr').find(".user_identity_cycle_select").val();
  var url = $this.attr('href');
  var user_identity_type = $('#user_user_identities_type').val();
  var params = {
    cas_identifier_id: cas_identifier_id,
    cycle_id: cycle_id,
    user_identity_type: user_identity_type
  };

  $('#flash .negative').hide().text('');
  $.post(url, params).done(function (response) {
    window.location = edit_user_path(response.user_identity_id);
  }).fail(function (jqXHR) {
    $('#flash .negative').show().text(jqXHR.responseJSON.error);
    document.getElementById('flash').scrollIntoView();
  });

  return false;
}

function load_cycles() {
  var $this = $(this);
  var cas_identifier_id = $this.val();
  var cycle_cell = $this.closest('tr').find('.cas_identifier_cycle_cell');
  var user_identity_type = $('#user_user_identities_type').val();
  var params = {
    cas_identifier_id: cas_identifier_id,
    user_identity_type: user_identity_type
  };

  $.get('/user_identity_cases', params, function(data) {
    cycle_cell.html(data);
  });
}

function update_association_and_organization_selects(event) {
  var value = $(event.target).val();
  switch(value)
  {
    case 'Liaison':
      $('#organization_id_spinner').hide();
      $('.institutions-input').hide();
      $('.assign_institution_manager').show();
      $('.association_user')       .hide();
      $('.select_cas_id')          .hide();
      $('.select_organization_id') .hide();
      $('.organization_user')      .hide();
      $('.check_approve_advisor').hide();
      $('.check_impersonate_applicant_gateway').hide();
      $('#work_group_select').hide();
      $('.assign_force_sso').hide();
      $('[data-role="primary-user-section"]').show();
      $('[data-role="institution-manager-section"]').show();
      $('[data-role="super-admin-section"]').show();
      $('[data-role="staff-role-section"]').hide();
      break;
    case 'Cas':
      $('#organization_id_spinner').hide();
      $('.institutions-input').hide();
      $('.assign_institution_manager').show();
      $('.association_user')       .show();
      $('.select_cas_id')          .show();
      $('.select_organization_id') .hide();
      $('.organization_user')      .hide();
      $('.check_approve_advisor').show();
      $('.check_impersonate_applicant_gateway').show();
      $('#work_group_select').hide();
      $('.assign_force_sso').show();
      $('[data-role="primary-user-section"]').show();
      $('[data-role="institution-manager-section"]').show();
      $('[data-role="super-admin-section"]').show();
      $('[data-role="staff-role-section"]').hide();
      break;
    case 'Organization':
      $('#organization_id_spinner').show();
      $('.institutions-input').hide();
      $('.assign_institution_manager').show();
      $('.association_user')       .hide();
      $('.select_cas_id')          .show();
      $('.select_organization_id') .show();
      $('.organization_user')      .show();
      $('.check_approve_advisor').hide();
      $('.check_impersonate_applicant_gateway').hide();
      $('#work_group_select').show();
      $('.assign_force_sso').show();
      $('[data-role="primary-user-section"]').show();
      $('[data-role="institution-manager-section"]').show();
      $('[data-role="super-admin-section"]').show();
      $('[data-role="staff-role-section"]').hide();
      break;
    case 'Advisor':
      $('#organization_id_spinner').hide();
      $('.institutions-input').hide();
      $('.assign_institution_manager').hide();
      $('.association_user')       .show();
      $('.select_cas_id')          .hide();
      $('.select_organization_id') .hide();
      $('.organization_user')      .hide();
      $('.check_approve_advisor').hide();
      $('.check_impersonate_applicant_gateway').hide();
      $('#work_group_select').hide();
      $('.assign_force_sso').show();
      $('[data-role="primary-user-section"]').show();
      $('[data-role="institution-manager-section"]').show();
      $('[data-role="super-admin-section"]').show();
      $('[data-role="staff-role-section"]').hide();
      break;
    case 'Institution':
      $('#organization_id_spinner').hide();
      $('.institutions-input').show();
      $('.assign_institution_manager').hide();
      $('.association_user').hide();
      $('.select_cas_id').hide();
      $('.select_organization_id').hide();
      $('.organization_user').hide();
      $('.check_approve_advisor').hide();
      $('.check_impersonate_applicant_gateway').hide();
      $('#work_group_select').hide();
      $('.assign_force_sso').show();
      $('[data-role="primary-user-section"]').show();
      $('[data-role="institution-manager-section"]').show();
      $('[data-role="super-admin-section"]').show();
      $('[data-role="staff-role-section"]').hide();
      break;
    case 'LiaisonStaff':
      $('#organization_id_spinner').hide();
      $('.institutions-input').hide();
      $('.assign_institution_manager').show();
      $('.association_user')       .hide();
      $('.select_cas_id')          .hide();
      $('.select_organization_id') .hide();
      $('.organization_user')      .hide();
      $('.check_approve_advisor').hide();
      $('.check_impersonate_applicant_gateway').hide();
      $('#work_group_select').hide();
      $('.assign_force_sso').hide();
      $('[data-role="primary-user-section"]').hide();
      $('[data-role="institution-manager-section"]').hide();
      $('[data-role="super-admin-section"]').hide();
      $('[data-role="staff-role-section"]').show();
  }

  var userType = $('#user_user_identities_type').val();
  var selectedCas = $('#user_user_identities_cas_id').attr('cas-sso');
  if(selectedCas != undefined){
    if(userType === 'Organization' && selectedCas === 'true') {
      setForceSso(true);
    }else{
      setForceSso(false);
    }
  }
}

function redirect_to_edit_user_identity(event) {
  var value = $(event.target).val();

  window.location.replace(edit_user_path(value));
}

function reset_search_criteria() {
  $('#filter_form input[type=text], input[type=number], #filter_form select').val(function() {
    var default_value = $(this).data("default");
    if (default_value) {
      return default_value;
    } else {
      return '';
    }
  }).each(function() {
    this.dispatchEvent(new Event('change'));
  });
}

function update_admin_note(self, id) {
  var url = $(self).data('url');
  var current_note = $(self).data('current-note');
  var new_note = prompt("Enter a note", current_note);
  var note_cell = $('#admin_note_' + id);

  if (new_note == null || new_note == current_note) return;

  $(self).data('current-note', new_note);

  if (note_cell.length > 0) {
    if (new_note == "") {
      note_cell.hide();
      note_cell.html(new_note);
    } else {
      note_cell.html(new_note);
      note_cell.show();
    }
  }

  $.post(url, { note: new_note, _method: "PUT" });
}

// Update aria-label - check all and uncheck all button for programs selection
$(document).ready(function() {
  $('#program_check').attr('aria-label', 'Check All Program Assignments')
  $('#program_uncheck').attr('aria-label', 'Uncheck All Program Assignments')
});
