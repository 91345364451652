// Source: http://www.jankoatwarpspeed.com/turn-any-webform-into-a-powerful-wizard-with-jquery-formtowizard-plugin/
// Tweaked to:
// 1) Use 1 based index instead of 0
// 2) Add lastPrev class to finall Previous button
// 3) Cosmetic changes like tab spacing
// 4) Related css file: app/assets/stylesheets/_form_to_wizard.sass
/* Created by jankoatwarpspeed.com  */

(function($) {
  $.fn.formToWizard = function(options) {
    options = $.extend({ submitButtonSelector: "" }, options);

    var steps = this.find("fieldset");
    var count = steps.length;
    var submitButtonSelector = options.submitButtonSelector;
    $(submitButtonSelector).hide();

    this.before("<ul id='steps'></ul><hr/>");

    steps.each(function(i) {
      i = i + 1;
      $(this).wrap("<div id='step" + i + "'></div>");
      $(this).append("<p id='step" + i + "commands'></p>");

      var name = $(this).find("legend").html();
      $("#steps").append("<li id='stepsDesc" + i + "'>Step " + (i) + "<span>" + name + "</span></li>");

      if (1 === i) {
          createNextButton(i);
          selectStep(i);
      }
      else if (i === count) {
        $("#step" + i).hide();
        createPrevButton(i);
      }
      else {
        $("#step" + i).hide();
        createPrevButton(i);
        createNextButton(i);
      }
    });

    function createPrevButton(i) {
      var stepName = "step" + i;
      $("#" + stepName + "commands").append("<a href='#' id='" +
        stepName +
        "Prev' class='prev" +
        ( i === count ? " lastPrev " : " " ) +
        "button' role='button'>Previous</a>");

      $("#" + stepName + "Prev").on("click", function() {
        $("#" + stepName).hide();
        $("#step" + (i - 1)).show();
        $(submitButtonSelector).hide();
        selectStep(i - 1);
      });
    }

    function createNextButton(i) {
      var stepName = "step" + i;
      $("#" + stepName + "commands").append("<a href='#' id='" + stepName + "Next' class='next button' role='button'>Next</a>");

      $("#" + stepName + "Next").on("click", function() {
        $("#" + stepName).hide();
        $("#step" + (i + 1)).show();
        if (i + 1 === count)
            $(submitButtonSelector).show();
        selectStep(i + 1);
      });
    }

    function selectStep(i) {
      $("#steps li").removeClass("current");
      $("#stepsDesc" + i).addClass("current");
    }
  }
})(jQuery);
