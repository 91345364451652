//This function handles the popup that occurs 3 minutes before the session timeout.

WEBADMIT.popupMonitor = function (listener, options) {
  this.listener = listener;
  this.options = $.extend({}, WEBADMIT.popupMonitor.defaultOptions, options);
};

WEBADMIT.popupMonitor.defaultOptions = {
  url: '/session'
};

WEBADMIT.popupMonitor.prototype = {

  //
  // Updates the popup timeout that will call the `listener`
  // after `sessionRemainingTime` seconds has passed.
  // Takes care of reset a previous time if there was one,
  // it does nothing if the listener is not a valid function or
  // if the sessionRemainingTime is not > 0.
  //
  updateTimeout: function (sessionRemainingTime) {
    var self = this;
    this.sessionRemainingTime = sessionRemainingTime;
    this._resetTimeout();
    if (this.sessionRemainingTime <= 0) {
      return;
    }
    this.timeout = setTimeout(function() {
      self._fetchTimeout();
    }, this.sessionRemainingTime * 1000);
  },

  //
  // Resets the current popup  timeout if there's one set up.
  //
  _resetTimeout: function () {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  },

  //
  // Callback called when the popup timeout is over.
  // It will call the current listener if it's a valid function.
  //
  _onPopupTimeout: function (time_left) {
    if (this.listener && typeof this.listener == 'function') {
      this.time_left = time_left;
      this.listener.call(this);
    }
  },

  //
  // Fetches latest timeout value from the server.
  // If it has a new value it updates the timeout counter.
  // Otherwise it triggers the popup timeout callback.
  //
  _fetchTimeout: function () {
    var self = this;
    $.getJSON(this.options.url)
      .then(this._parseSessionTimeLeft)
      .then(function (sessionTimeLeft) {
        if(sessionTimeLeft <= 180 ){
          self._onPopupTimeout(sessionTimeLeft);
        }else{
          self.updateTimeout((sessionTimeLeft - 180));
        }
      })
      .fail(function () {
      });
  },

  //
  // Parses the backend data for a `session_time_left` value.
  // Returns a promise for chaining async behaviour.
  //
  _parseSessionTimeLeft: function (data) {
    var defer = $.Deferred();
    if (data && data.session_time_left) {
      defer.resolve(data.session_time_left);
    } else {
      defer.reject(null, 'error', 'No valid session_time_left provided');
    }
    return defer.promise();
  }
};

//
// Monitors the session validity given a session timeout duration in seconds.
// Then when we reach that time, it will fetch the backend to verify that the
// timeout is still valid and it will call a provided session timeout callback (e.g. a page reload
// to have the login page displayed).
//
// If the sessionTimeout is less than or equal to 0,
// it won't trigger the callback.
//
WEBADMIT.SessionMonitor = function (listener, options) {
  this.listener = listener;
  this.options = $.extend({}, WEBADMIT.SessionMonitor.defaultOptions, options);
};

WEBADMIT.SessionMonitor.defaultOptions = {
  url: '/session'
};

WEBADMIT.SessionMonitor.prototype = {

  //
  // Updates the timeout that will call the `listener`
  // after `sessionRemainingTime` seconds has passed.
  // Takes care of reset a previous time if there was one,
  // it does nothing if the listener is not a valid function or
  // if the sessionRemainingTime is not > 0.
  //
  updateTimeout: function (sessionRemainingTime) {
    var self = this;
    this.sessionRemainingTime = sessionRemainingTime;
    this._resetTimeout();
    if (this.sessionRemainingTime <= 0) {
      return;
    }
    this.timeout = setTimeout(function() {
      self._fetchTimeout();
    }, this.sessionRemainingTime * 1000);
  },

  //
  // Resets the current timeout if there's one set up.
  //
  _resetTimeout: function () {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  },

  //
  // Callback called when the timeout is over.
  // It will call the current listener if it's a valid function.
  //
  _onSessionTimeout: function () {
    if (this.listener && typeof this.listener == 'function') {
      this.listener.call();
    }
  },

  //
  // Fetches latest timeout value from the server.
  // If it has a new value it updates the timeout counter.
  // Otherwise it triggers the timeout callback.
  //
  _fetchTimeout: function () {
    var self = this;
    $.getJSON(this.options.url)
      .then(this._parseSessionTimeLeft)
      .then(function (sessionTimeLeft) {
        self.updateTimeout(sessionTimeLeft);
      })
      .fail(function () {
        self._onSessionTimeout();
      });
  },

  //
  // Parses the backend data for a `session_time_left` value.
  // Returns a promise for chaining async behaviour.
  //
  _parseSessionTimeLeft: function (data) {
    var defer = $.Deferred();
    if (data && data.session_time_left) {
      defer.resolve(data.session_time_left);
    } else {
      defer.reject(null, 'error', 'No valid session_time_left provided');
    }
    return defer.promise();
  }
};
