$(document).ready(function () {
  $(document).on('click', '.date-datepicker', function(){
    $('#ui-datepicker-div').attr('role', 'dialog');
    $('.ui-datepicker-month').attr('aria-label', 'Select Month');
    $('.ui-datepicker-year').attr('aria-label', 'Select Year');
  });
  WEBADMIT.globalUpdates.initializeDatePickers = function() {
    $('.date-datepicker').each(function () {
      var $dateInput = $(this);
      var datePickerMinDate = $dateInput.data('min-date');
      var datePickerOptions = {
        minDate: datePickerMinDate,
        changeMonth: true,
        changeYear: true
      };
      $dateInput.datepicker(datePickerOptions);
    });

    $('#designation_projected_graduation_date').linkDatepickerMinDateTo('#designation_effective_date');
    $('#ui-datepicker-div').attr('role', 'dialog');
    $('.ui-datepicker-month').attr('aria-label', 'Select Month');
    $('.ui-datepicker-year').attr('aria-label', 'Select Year');
  };

  WEBADMIT.globalUpdates.additionalDetailsInputs = function() {
    var acceptedProgramType = '.additional-details #accepted_program_type_id';
    var campusId = '.additional-details #campus_id';
    var effectiveDate = '.additional-details #designation_effective_date';
    var projectedGraduationDate = '.additional-details #designation_projected_graduation_date';

    return [acceptedProgramType, effectiveDate, campusId, projectedGraduationDate];
  };

  $(document).on('change', WEBADMIT.globalUpdates.additionalDetailsInputs().join(), function () {
    var inputs = WEBADMIT.globalUpdates.additionalDetailsInputs();
    var state = ($(inputs[0]).val() !== '' && $(inputs[1]).val() !== '' &&
      $(inputs[2]).val() !== '' && $(inputs[3]).val() !== '');
    $(WEBADMIT.globalUpdates.submitButtonSelector()).toggle(state);
  });
});
