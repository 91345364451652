WEBADMIT.Competencies = {};

;(function ($, WEBADMIT) {
  WEBADMIT.Competencies.updateExperienceCompetencyMatching = function (checkbox) {
    var $checkbox = $(checkbox);
    var $container = $checkbox.parents('.content');
    var $spinner = $container.find('.spinner');
    var $warning = $container.find('.warning');

    var competencyId = $checkbox.val();
    var experienceId = $checkbox.attr('data-experience-id');
    var applicantId = $checkbox.attr('data-applicant-id');

    var previousMatchingState = $checkbox.attr('data-is-matched') === 'true';
    var currentMatchingState = checkbox.checked;

    if (previousMatchingState != currentMatchingState) {
      $spinner.show();
      $warning.hide();

      saveExperienceCompetencyMatching(currentMatchingState, applicantId, competencyId, experienceId).done(function (response) {
        $checkbox.updateCompetencyCheckboxState(currentMatchingState);
        if (response.competencies) {
          updateCompetenciesPanel(response.competencies);
        }
      }).fail(function () {
        $checkbox.updateCompetencyCheckboxState(previousMatchingState);
        $warning.text('The experience could not be matched.');
        $warning.show();
      }).always(function () {
        $spinner.hide();
      });
    }
  };

  $.fn.updateCompetencyCheckboxState = function (state) {
    this.prop('checked', state);
    this.attr('data-is-matched', state.toString());
  };

  function saveExperienceCompetencyMatching(isMatched, applicantId, competencyId, experienceId) {
    var action = isMatched ? 'add' : 'remove';
    var url = '/applicants/' + applicantId + '/competencies/' + competencyId + '/' + action + '_experience';
    return $.post(url, {experience_id: experienceId});
  }

  function updateCompetenciesPanel(htmlContent) {
    $('#async_content_competencies_pane').html(htmlContent);
  }
})(jQuery, WEBADMIT);

$(document).on('click', '.toggle-competency-details', function () {
  var competencyTables = $(this).closest('.competency-table');
  var experienceHolders = competencyTables.find('.competency-experience-holder');
  var detailColumn = competencyTables.find('.competency-holder').find('.toggle_detail');

  experienceHolders.removeClass('hide');
  detailColumn.removeClass('closed');
  detailColumn.addClass('opened');
  return false;
});

$(document).on('keyup', '.details-info', function(event){
    var pressed = event.keyCode;
    if(pressed === 27){
      var parentID = '#' + $(this).attr('data-origin');
      $(parentID).attr({ 'aria-label': $(parentID).attr('aria-label').replace('Hide', 'Show') });
      $(parentID).trigger('click').focus();
    }
  });

$(document).on('click', '.toggle-experience-details', function (event) {
  var toggleAction  = $(this);
  var exportRow = $(this).closest('tr').next('tr');
  var displayColumn = $(this).closest('td');
  var displayClass = displayColumn.attr('class');
  var key = toggleAction.uniqueId().attr('id');

  exportRow.toggleClass('hide');

  if (displayClass == 'toggle_detail opened') {
    displayColumn.removeClass('opened');
    displayColumn.addClass('closed');
    if($(this).attr('aria-label') !== undefined){
      setToggleState(toggleAction, 'Hide', 'Show');
    }
  } else {
    displayColumn.removeClass('closed');
    displayColumn.addClass('opened');
    var tdFirstChild = exportRow.find('td').children().first();
    tdFirstChild.attr({'tabindex': 0, 'data-origin': key});
    tdFirstChild.focus();
    if($(this).attr('aria-label') !== undefined){
      toggleScreenReaderHelptext(toggleAction, tdFirstChild);
    }
  }

  event.stopImmediatePropagation();

  return false;
});

$(document).on('change', '.force-fulfill-competency', function () {
  var form = $(this).closest('form')
  $.post(form.attr('action'), form.serializeArray(), function (data) {
    $("#categories-sections").html(data.html);
    $("#force-fulfill-" + data.competency_id).focus();
    if(data.success_status_msg != undefined) {
      $("#competencies-status").text(data.success_status_msg)
    }
  }, 'json')
});

$(document).on('change', '.match_experience_to_competency', function () {
  WEBADMIT.Competencies.updateExperienceCompetencyMatching(this);
});
