jQuery(document).ready(function($) {

  $(".right_check_box").change( function() {
    var feature_id = $(this).attr('feature_id');
    var right_count = $('#feature_block_' + feature_id + " .right_check_box").length
    var checked_right_count = $('#feature_block_' + feature_id + " .right_check_box:checked").length

    var header = $('#feature_header_' + feature_id);
    var new_class = "";

    if (checked_right_count == 0) {
      new_class = "no_rights_selected"
    } else if (checked_right_count == right_count) {
      new_class = "all_rights_selected"
    } else {
      new_class = "some_rights_selected"
    }

    header.attr('class', new_class);
  });

  $('.right_label').tipsy({ gravity: 'e'});

});

function select_all_rights(feature_id) {
  $('#feature_block_' + feature_id + " .right_check_box").prop("checked", true);
  var header = $('#feature_header_' + feature_id);
  header.attr('class', "all_rights_selected");
}

function select_no_rights(feature_id) {
  $('#feature_block_' + feature_id + " .right_check_box").prop("checked", false);
  var header = $('#feature_header_' + feature_id);
  header.attr('class', "no_rights_selected");
}