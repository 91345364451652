/* application.js */

// Include CSRF forgery protection token
// in every request
$(document).ajaxSend(function(e, xhr, options) {
  var token = $("meta[name='csrf-token']").attr("content");
  xhr.setRequestHeader("X-CSRF-Token", token);
});

jQuery.fn.clipboard = function (action) {

  var clipboardTarget = $('#clipboard_action');

  clipboardTarget.stop();
  switch(action) {
    case "add":
      clipboardTarget.animate({
        backgroundColor: "#AAFFAA"
      }, 100).animate({
        backgroundColor: "#EEEEEE"
      }, 2000, function(){
        $(this).css({'background-color': ''});
      })
      break;
    case "remove":
      clipboardTarget.animate({
        backgroundColor: "#FFFF33"
      }, 300).animate({
        backgroundColor: "#EEEEEE"
      }, 1000, function(){
        $(this).css({'background-color': ''});
      });
      break;
  }
};

jQuery.fn.task = function () {
  this.click( function() {
    jQuery.post("/tasks/" + this.value, { id: this.value, _method: 'put' }, function (data) {
    }, "script" );
    if (this.checked) {
      jQuery(this).parent().prependTo(".finished");
    } else {
      jQuery(this).parent().appendTo(".unfinished");
    }
  });
};

$.fn.alternateRowColors = function(){
  var alternateRowColor = "#ffffff";
  $(this).find('tr:nth-child(even)').not('.no-alternate-bg').css('background-color', alternateRowColor);
  $(this).find('tr:nth-child(odd)').not('.no-alternate-bg').css('background-color', '');
  $(this).find('.alternate_bg > *:nth-child(even)').css('background-color', alternateRowColor);
  $(this).find('.alternate_bg > *:nth-child(odd)').css('background-color', '');
  return this;
}

$.fn.highliteTableRows = function() {
  var local_gpa_selector = $('#local_gpa_selector :selected').closest('optgroup')
  var local_gpa_filter_value = $("#local_gpa_selector").val();
  var yellow = '#ffff98' // duplicate
  var green = '#bbffbb'  // prerequisite
  var blue = '#bbffff' // local gpa
  var pink = '#ffc0cb' // duplicate and prerequisite or local gpa
  window.duplicate_feature_active = $('#highlite_duplicate_courses').is(':checked')

  // First turn all courses to white (no highlight)
  $('.course.duplicate').css('background', '#ffffff');

  // Next turn all duplicate courses yellow
  if(duplicate_feature_active)
    $('.duplicate_course').css('background', yellow);

  // Next set custom colors for local_gpa if current view is local_gpa
  if(local_gpa_selector.hasClass('select_local_gpas'))
    apply_course_color_for_local_prerequisite(local_gpa_filter_value, 'local_gpa', blue);

  // Next set custom colors for prerequisite if current view is prerequisite
  if(local_gpa_selector.hasClass('select_requirements'))
    apply_course_color_for_local_prerequisite(local_gpa_filter_value, 'prerequisite', green);
}

function apply_course_color_for_local_prerequisite(local_gpa_filter_value, gpa_type, color_code) {
  if(local_gpa_filter_value == '')
    $(".duplicate." + gpa_type + "_course").css('background', color_code);
  else {
    $(".duplicate[data-"+ gpa_type + "_" + local_gpa_filter_value + "]").css('background', color_code);
    // update any local/prerequisite courses that are also duplicate_courses
    if(duplicate_feature_active)
      var pink = '#ffc0cb' // duplicate and prerequisite or local gpa
      $(".duplicate_course."+ gpa_type +"_course[data-"+ gpa_type + "_" + local_gpa_filter_value + "]").css('background', pink);
  }
}

window.apply_alternate_row_color = function() {
  var alternateRowColor = "#ffffff";
  $('table tr:nth-child(even)').not('.no-alternate-bg').css('background-color', alternateRowColor);
  $('table tr:nth-child(odd)').not('.no-alternate-bg').css('background-color', '');
  $('.alternate_bg > *:nth-child(even)').css('background-color', alternateRowColor);
  $('.alternate_bg > *:nth-child(odd)').css('background-color', '');
}

window.apply_tipsy = function () {
  $('.tipsy-top'   ).tipsy({gravity: 's'})
  $('.tipsy-bottom').tipsy({gravity: 'n'})
  $('.tipsy-right' ).tipsy({gravity: 'e'})
  $('.tipsy-left'  ).tipsy({gravity: 'w'})
  $('.tipsy-bottom-html').tipsy({gravity: 'n', html: true})
}

function clipboarded(action) {
  $("#clipboard_link").clipboard(action);
}

$(document).on('click', '.show_on_menubar_toggle', function(){
   var ul_id = 'toolbar_' + $(this).data('wrapper');
   var data_type = $(this).data('type');
   var data_entity = $(this).data('entity');
   var update = $(this).is(':checked') ? 'added to' : 'removed from';
   var name = $(this).data('name');
   var data = {};
   $.post($(this).data('url'), $.extend(data, {id: $(this).data('id'), type: data_type, show_on_menubar: $(this).is(':checked')}),
    function(data, textStatus, XMLHttpRequest) {
     var $container = $('#' + ul_id).parent();
     $container.html(data.html);
     apply_tipsy();
     $('.screen-reader-only#show-on-menubar-status').text(data_entity + ' ' + name + ' ' + update + ' the menu bar.');
   }, "json")
})

window.initializeToggleCheckboxes = function(){
  $('button.toggle_checkboxes, a.toggle_checkboxes').click(function(){
    var toggleClass = "." + $(this).attr('toggle');
    var checkedState = ($(this).attr('state') === "true");
    $(toggleClass).prop('checked', checkedState);
    return (undefined !== $(this).attr('global_update_toggle'));
  });
}

function collapseContent(){
  $('div.collapse-content').each(function(){
    if ($(this).height() > 80) {
      $(this).addClass('collapsed')
    }
  })
}

$.fn.injectError = function(data){
  var $container = this
  var $errorControls = $('<a class="injected-error-toggle"> [Show Error] </a>')
  $container.append($errorControls)
  $errorControls.click(function(){
    $container.replaceWith(data.responseText)
  })
  return this;
}

window.datePickerDialog = function (currentObj,targetTag,targetClass,pickerProp) {
  if (pickerProp === undefined) {
    pickerProp = {};
  }
  var picker = currentObj
  var targetEle = picker.closest(targetTag);
  picker.append('<div class="picker"></div>');
  var dialog = $('.picker').dialog({
  modal: true,
  autoResize: true,
  draggable: true,
  closeOnEscape: false,
  resizable: false,
  title: 'Select a date',
  open: function(event, ui) {
    $('.ui-widget-overlay').on('click', function(event,ui) {
      $('.picker').dialog('close');
    });
    $.extend(pickerProp, {onSelect: function(selectedDate){
        targetEle.find('.'+targetClass).val(selectedDate);
        $(".picker").dialog('close');
       }})
    $(this).datepicker(pickerProp).datepicker( 'show' );
  },
  width: 'auto',
  height: 'auto',
  overflow: 'none',
  close: function(event, ui) {
    $(this).closest('.ui-dialog').remove();
    $(this).remove();
  }
  });
}

function removeWhiteSpace() {
  // Remove trailing whitespace from input fields
  // helps with copy/pasted text
  $('input:not([type=file])').focusout(function(){
    var $el = $(this);
    // This caused a
    // Uncaught DOMException: Failed to set the 'value' property on 'HTMLInputElement':
    // File input element accepts a filename, which may only be programmatically set to the empty string.
    $el.val($el.val().trim());
  });
}

$(document).ready(function(){

  $('#ui-datepicker-div').attr('role', 'dialog');

  var retrieveAsyncUrl = function(asyncWrapper, asyncUrl){
    $.ajax({
      url: asyncUrl,
      success: function(data){
        asyncWrapper.html(data)
        asyncWrapper.removeClass('pending')
        asyncWrapper.trigger('loaded')
        asyncWrapper.trigger('content-loaded')
        asyncWrapper.find('.pop_over').initPopOver()
        // this is a problem but for now it will stay :/
        asyncWrapper.alternateRowColors()
        asyncWrapper.find('transcript_detail_table').highliteTableRows();
        asyncWrapper.find('.auto_submit').autoSubmitInput();
        // default ot first sub-tab
        var switcher = asyncWrapper.find("ul.switch")
        if (switcher.length) {
          asyncWrapper.removeAttr('tabindex');
          asyncWrapper.find('ul.switch li a').removeAttr('href');
          if(asyncWrapper.find('ul.switch li').length === 0 && asyncWrapper.find('ul.switch').attr('role') !== undefined){
            asyncWrapper.find('ul.switch').removeAttr('role');
          }
          if (!switcher.find('li.active').length) {
            var clickedId = '#' + switcher.find('li:first').attr('id');
            $.switchTab(clickedId)
          }
          if(switcher.find('li.active').attr('aria-selected') !== undefined){
            var tabArray = [];
            asyncWrapper.find('ul.switch li a').each(function(){
              tabArray.push($(this).text() + ' Tab');
            })
            setScreenReaderHelpText(tabArray, asyncWrapper);
            asyncWrapper.find('ul.switch li.active').attr({'aria-describedby': asyncWrapper.find('p.screen-reader-only').attr('id')});
          }
        }

      },
      error: function(data){
        // if page is closing don't bother showing error
        if (! window.pageIsClosing) {
          asyncWrapper.removeClass('pending')
          $msg = $('<div/>').addClass('async-failed')
          $msg.text("Could not load content.")
          $msg.injectError(data)
          asyncWrapper.html($msg)
        }
      }
    })
  }

  $('#async_content_designations_pane').on('content-loaded',function(){
    $('.expand_all').on('click', function(event){
      var button = $(this).toggleClass('active');
      var button_start_state = button.hasClass('active');
      var rows = $('.drill_down_row');
      event.preventDefault();
      designationDisplayStateToggle(button_start_state, button, rows);
    });
  })

  WEBADMIT.loadPanel = function(element) {
    var asyncWrapper = $(element)
    var asyncTargetUrl = asyncWrapper.attr('data-async-url')
    var asyncDelayedTargetUrl = asyncWrapper.attr('data-delayed-async-url')

    if (asyncWrapper.length > 0 && !asyncWrapper.hasClass('pending')) {
      asyncWrapper.html('');
      asyncWrapper.addClass('pending');
    }

    if (asyncTargetUrl) {
      retrieveAsyncUrl(asyncWrapper, asyncTargetUrl)
    }
    if (asyncDelayedTargetUrl) {
      asyncWrapper.attr('data-on-open', 'load-async')
      asyncWrapper.off('load-async').on('load-async', function() {
        retrieveAsyncUrl(asyncWrapper, asyncDelayedTargetUrl)
      })
    }
  }

  $('.async-load').each(function(index,element) {
    WEBADMIT.loadPanel(element);
  });

  $(document).on('submit', '.async-dialog form', function(event){
    var $form = $(this)
    var $container = $form.parent()

    var url = $form.attr('action')

    $.ajax({
      type: "POST",
      url: url,
      data: $form.serialize(),
      complete: function(data){
        $container.html(data.responseText)
        $container.find('#form_flash').delay(2000).fadeOut()
      }
    })

    $form.find('input').attr('disabled', true)

    event.preventDefault ? event.preventDefault() : event.returnValue = false;
    return false
  })

  $(document).on('dialogbeforeclose', '.ui-dialog', function(){
    $(this).find('#form_flash').remove()
  })

  $(document).on('click', '.state_pane .pagination a', function(){

    var $paginationLink = $(this)
    var newContentUrl = $paginationLink.attr('href')
    var $statePane = $paginationLink.closest('.state_pane')

    // Disable current content
    $statePane.children().click(function(){
      return false // don't allow clicks on old content
    }).css('opacity', 0.5)
    $statePane.addClass('pending-replace') // ajax icon

    // Get new content
    $.get(newContentUrl, function(data){
      $statePane.html(data)
      $statePane.find('ul.switch li a').removeAttr('href');
      $statePane.removeClass('pending-replace');
    })

    return false;
  })

  $(document).on('loaded', '#async_content_transcript_pane', function(){
    var selector = $("#local_gpa_selector")
    selector.local_gpa_selector('#transcript_detail_table', '#gpa_by_local table')
    $("#courses_group_by").course_grouping_prep('#gpa_by_local table')
    $(".requirement_column").hide()

    selector.change(function () {
      var $option = $(this).find(":selected");
      if ($option.parent("optgroup").hasClass("select_requirements")) {
        $(".requirement_column").show();
        activateColorForRequirementsToggles($option.val())
      } else {
        $(".requirement_column").hide();
      }
    });
  })

  $(document).on('loaded', '#async_content_requirements_pane', function(){
    afterLoadRequirementsSetup()
  })

  $(document).on('loaded', '#async_content_custom_fields_pane', function(){
    $(".datepicker").datepicker()
  })

  $("#local_gpa_selector").local_gpa_selector('#transcript_detail_table', '#gpa_by_local table');

  $('.task input[type="checkbox"]').task();

  $('.filter_toggle').click(function() {
    targetClass = $(this).attr('data-type');
    $(this).parent().fadeOut();
    $(targetClass).fadeIn();
    $(".filter_submit").show();
  });

  $('.toggle_id').click(function(event) {
    var self = $(this)
    var action = self.find('span.action')
    var target = $(self.attr('data-target-id'))
    if (action.text() == 'Hide') {
      target.hide()
      setToggleState(self, 'Hide', 'Show')
      action.text('Show')
    }
    else {
      target.show()
      setToggleState(self, 'Show', 'Hide')
      action.text('Hide')
    }
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
  })

  $(document).on('click', '.display_id', function(){
    var $clicked = $(this)
    var targetId = $clicked.attr('data_display_id')
    $('#' + targetId).show()
    $clicked.hide()
  })

  $('.hide_parent').click(function(){
    $(this).parent().fadeOut();
  });

  $('.unhide.closest.li').click(function() {
    $('.unhide.closest.li').attr('aria-expanded', false);
    var answer = $(this).closest('li').find('.answer');
    $(this).closest('ul').find('.answer').not(answer).hide();
    if(answer.css('display') == 'none') {
      answer.show('fast', function(){ answer.focus() });
      $(this).attr('aria-expanded', true);
    } else {
      answer.hide();
      $(this).attr('aria-expanded', false);
    }
  });

  $('.on_hover').hoverIntent(
    function(){
      $(this).find('.on_hover_controls').fadeIn();
    },
    function(){
        $(this).find('.on_hover_controls').fadeOut();
    }
  )

  $(document).on('click', '.widget a.button.cancel', function(){
    var toggle_widget = $(this).closest('.widget').parent().find('.hide_widget')
    toggle_widget.trigger('click');
    $(this).closest('form').find('textarea').val(null);
    toggle_widget.find("[role='button']").focus();
    return false;
  });

  $('.controls').closest('h2').hoverIntent(
    function(){
      $(this).find('.controls').fadeIn();
    },
    function(){
      $(this).find('.controls').fadeOut();
    }
  );

  $('.notyet').hoverIntent(
    function(){
      $(this).addClass('blackout');
    },
    function(){
      $(this).removeClass('blackout');
    }
  );

  $(document).on('click', '.state_toggle_sub_menu', function(e){
    var control = $(this);
    var controlId = '#' + control.attr('id');
    var atag = $(this).find('a');
    if (control.hasClass('opened')) {
      atag.attr('aria-expanded', false);
      control.removeClass('opened');
      control.addClass('closed');
      control.parent().find('.state_pane_sub_menu').slideUp(300);
      $.postUiState(controlId, 'closed');
    }
    else if (control.hasClass('closed')) {
      atag.attr('aria-expanded', true);
      control.removeClass('closed');
      control.addClass('opened');
      control.parent().find('.state_pane_sub_menu').slideDown(300);
      $.postUiState(controlId, 'opened');
      control.parent().find('.state_pane_sub_menu').focus();
    }
  });

  $(document).on('click', '.state_toggle', function(e){
    var control = $(this);
    var controlId = '#' + control.attr('id');
    var atag = control.find('a');
    if (control.hasClass("opened")) {
      atag.attr('aria-expanded', false);
      $.addClosedState(controlId);
    }
    else if (control.hasClass("closed")) {
      atag.attr('aria-expanded', true);
      $.addOpenedState(controlId);
      if (e.screenX == 0 && e.screenY == 0) {
        var verifyStatePane = setInterval(checkStatePane, 500);
        var statePane = control.closest(".panel").find(".state_pane");
        function checkStatePane(){
          if(statePane.html() != ""){
            if(statePane.find('ul.switch').length && statePane.find('ul.switch li.active').attr('aria-selected') !== undefined){
              statePane.removeAttr('tabindex');
              statePane.find('ul.switch li a').removeAttr('href');
              if(!statePane.find('p.screen-reader-only')){
                var tabArray = [];
                statePane.find('ul.switch li a').each(function(){
                  tabArray.push($(this).text() + ' Tab');
                })
                setScreenReaderHelpText(tabArray, statePane);
              }
              statePane.find('ul.switch li.active').attr({'aria-describedby': statePane.find('p.screen-reader-only').attr('id')});
              statePane.find('ul.switch li.active').focus();
            }else if(statePane.find('ul.switch li').length === 0 && statePane.find('ul.switch').parent().attr('role') !== undefined){
              statePane.find('ul.switch').parent().removeAttr('role');
            }else{
              statePane.focus();
            }
            clearInterval(verifyStatePane);
          }
        }
      }
    }
    else {
      $.addClosedState(controlId);
    }
  });

  $('abbr.humane_time').timeago();

  $("form.validate").validate({
    errorElement: "em"
  });

  $(".datepicker").datepicker();

  $(document).on('click', '.pickerdialog', function () {
    $('.pickerenddate').val('');
    datePickerDialog($(this),'div','pickerdialog');
  });

  $(document).on('click', '.assignment_date', function () {
    datePickerDialog($(this),'td','assignment_date');
  });

  $(document).on('click', '.pickerenddate', function () {
    var startdate = new Date($('.pickerdialog').val());
    startdate.setDate(startdate.getDate() +  1);
    datePickerDialog($(this),'div','pickerenddate', { minDate: startdate });
  });

  //This will fix the 508 landmark issue of datepicker in modal/popup.
  $(document).on('click', '#assignment_due_date, .custom_field_answer', function(){
    $('#ui-datepicker-div').attr('role', 'dialog');
  });

  $(document).on('click', '.datepicker_format', function () {
    datePickerDialog($(this),'li','datepicker_format',{ dateFormat: 'MM dd, yy'});
  });
  $(".datepicker_min_today").datepicker({ dateFormat: 'MM dd, yy', minDate: 0});

  apply_alternate_row_color()

  $(document).on('click', 'ul.switch li', function(){
    var switchElement = $(this);
    var clickedId = '#' + switchElement.attr('id');
    var parentUl = switchElement.closest('ul');
    if(! parentUl.attr('id')){
      alert('missing id on switch parent!');
      parent.higlight('slow');
    } else {
      switchElement.blur();
      //AQ - Ticket #10212 - Below line causes page to jump. Uncomment to revert.
      // window.location.href=clickedId;
      $.switchTab(clickedId, true);
    }
    return false;
  });

  $(document).on('keyup', 'ul.switch li', function(event){
    var current = $(this);
    if(event.keyCode == 37){
      current.closest('ul').find('li').removeAttr('aria-describedby');
      var prevtab = current.closest('li').prev('li');
      if(prevtab.length == 1){
        prevtab.focus();
      }else{
        current.closest('ul').find('li:last-child').focus();
      }
    }else if(event.keyCode == 39){
      current.closest('ul').find('li').removeAttr('aria-describedby');
      var nexttab = current.closest('li').next('li');
      if(nexttab.length == 1){
        nexttab.focus();
      }else{
        current.closest('ul').find('li:first-child').focus();
      }
    }else if(event.keyCode == 13){
      var clickedId = '#' + current.attr('id');
      var parentUl = current.closest('ul');
      if(! parentUl.attr('id')){
        alert('missing id on switch parent!');
        parent.higlight('slow');
      } else {
        current.blur();
        //AQ - Ticket #10212 - Below line causes page to jump. Uncomment to revert.
        // window.location.href=clickedId;
        $.switchTab(clickedId, true);
      }
    }
  });

  $(document).on('click', 'a.submit_form_link', function(){
    var closest_form = $(this).closest('form')
    //in order to emulate teh behavior of a standard form button we will pass the name attribute assigned to the
    //link as a parameter in the form ( if you 'name' the link 'submit_one' than you'll have a blank param called submit_one)
    var link_name = $(this).attr('data-form-submit');
    var submit_value = $(this).attr('data-submit-value') || '1'
    $('.fake_submit_value').remove();
    $(this).before("<input type='hidden' name='" + link_name + "' value='" + submit_value + "' class='fake_submit_value'/>");
    closest_form.submit();
    return false;
  });

  $('.splash_dismiss').click(function(event){
    event.stopPropagation;
    event.preventDefault ? event.preventDefault() : event.returnValue = false;
    var $splashFrame        = $('#splash_frame');
    var $splash_container   = $(this).parents('.splash');
    $splash_container.remove();
    var $splash_count           = $('.splash').length;
    var $splash_id              = $(this).parents('p').attr('id');
    if ($splash_count <= 0) {
      $splashFrame.slideUp();
    }
    $.get(
      '/ui_state/seen_splash',
      {splash_id: $splash_id},
      function(){

      }
    );
    return false;
  });

removeWhiteSpace();

  $(document).on('keyup', '.details_info', function(event){
    var pressed = event.keyCode;
    if(pressed === 27){
      var parentID = '#' + $(this).attr('data-origin');
      $(parentID).trigger('click');
      //Delay is given as aria label should be updated from hide to show
      setTimeout(function(){$(parentID).focus()}, 500);
    }
  });

  $(document).on('keyup', '.content', function(event){
    var pressed = event.keyCode;
    if(pressed === 27){
      var parentID = '#' + $(this).attr('data-origin') + ' a.pop_over_toggle';
      $(parentID).trigger('click');
      //Delay is given as aria label should be updated from hide to show
      setTimeout(function(){$(parentID).focus()}, 500);
    }
  });

  $(document).on('click', '.toggle_detail .action', function(event){
    event.preventDefault();
    var toggleAction  = $(this);
    var toggleTd      = toggleAction.parent();
    var toggleDetails = toggleTd.find('.details');
    var targetRow     = toggleTd.closest('tr');
    var rowBackground = targetRow.css('background-color');
    var key = toggleAction.uniqueId().attr('id');

    var leftPosition = $(this).offset().left;
    var fullWidth = $(window).width();
    var alignment = leftPosition > (fullWidth / 2) ? "customized-float-right" : "customized-float-left";

    closeOtherPopups(key);

    if(targetRow.length) {
      var closestTd = toggleAction.closest('td');
      var detailsTr = $('tr.' + key);
      var header = closestTd.closest('table').find('th').eq(closestTd.index());
      if(header.length){
        var header_id = header.uniqueId().attr('id');
        var header_html = 'headers="' + header_id + '"'
      }
      else{
        var header_html = ''
      }

      if(detailsTr.length && detailsTr.hasClass('opened-popup')) {
        detailsTr.removeClass('opened-popup').addClass('closed-popup');
        $(this).removeClass('opened').addClass('closed');
        if($(this).attr('aria-label') !== undefined){
          setToggleState(toggleAction, 'Hide', 'Show');
        }
      }
      else if(detailsTr.length && detailsTr.hasClass('closed-popup')){
        detailsTr.removeClass('closed-popup').addClass('opened-popup');
        detailsTr.find('td').children().first().focus();
        $(this).removeClass('closed').addClass('opened');
        if($(this).attr('aria-label') !== undefined){
          setToggleState(toggleAction, 'Show', 'Hide');
        }
      }
      else {
        var content = toggleDetails.html();
        toggleDetails.empty();
        if (content != undefined) {
          detailsTr = $('<tr class="opened-popup ' + key + '"><td class="padding5" colspan="100%"' + header_html + '>' + content + '</td></tr>');
          $(this).removeClass('closed').addClass('opened');
          checkRowSpan(targetRow, detailsTr);
          var tdFirstChild = $('tr.' + key + ' td').children().first();
          tdFirstChild.attr({'tabindex': 0, 'data-origin': toggleAction.attr('id')});
          tdFirstChild.addClass(alignment).focus();
          if($(this).attr('aria-label') !== undefined){
            toggleScreenReaderHelptext(toggleAction, tdFirstChild);
          }
        }
      }
    } else {
      var targetDd = toggleTd.closest('dd');

      if(targetDd.length) {
        var detailsDt = $('dt.' + key);
        var detailsDd = $('dd.' + key);

        if(detailsDt.length && detailsDt.hasClass('dl-opened') && detailsDd.length && detailsDd.hasClass('dl-opened')) {
          detailsDt.removeClass('dl-opened').addClass('dl-closed');
          detailsDd.removeClass('dl-opened').addClass('dl-closed');
          $(this).removeClass('opened').addClass('closed');
          if($(this).attr('aria-label') !== undefined){
            setToggleState(toggleAction, 'Hide', 'Show');
          }
        }
        else if(detailsDt.length && detailsDt.hasClass('dl-closed') && detailsDd.length && detailsDd.hasClass('dl-closed')){
          detailsDt.removeClass('dl-closed').addClass('dl-opened');
          detailsDd.removeClass('dl-closed').addClass('dl-opened');
          detailsDd.children().first().focus();
          $(this).removeClass('closed').addClass('opened');
          if($(this).attr('aria-label') !== undefined){
            setToggleState(toggleAction, 'Show', 'Hide');
          }
        } else {
          var content = toggleDetails.html();
          toggleDetails.empty();
          if (content != undefined) {
            detailsDt = $('<dt class="dl-opened width0 ' + key + '">&nbsp;</dt>');
            detailsDd = $('<dd class="dl-opened margin-left0 ' + key + '">' + content + '</dd>');
            $(this).removeClass('closed').addClass('opened');
            targetDd.after(detailsDd);
            targetDd.after(detailsDt);
            var ddFirstChild = $('dd.' + key).children().first();
            ddFirstChild.attr({'tabindex': 0, 'data-origin': toggleAction.attr('id')});
            ddFirstChild.focus();
            if($(this).attr('aria-label') !== undefined){
              toggleScreenReaderHelptext(toggleAction, ddFirstChild);
            }
          }
        }
      } else {
        console.error("Can't find a <tr> or a <dd>");
      }
    }
  });

  $(document).on('click', '.close-college-refernece, .close-school-reference', function(){
    let originId = $(this).closest('.details_info').attr('data-origin');
    $('#' + originId).trigger('click');
    $('#' + originId).focus();
  })

  $('.tree_node.root_node').treeView();

  $(document).on('export-fields-tree-loaded', function(event){
    $('.tree_node.root_node').treeView();
    exportFiledsTreeLeafsLoadAsync();
    if($('#export_export_format_id').attr('data-multiple-files') === 'true'){
      exportTreePickerHideSingleFileOnlyItems();
    };
  });

  (function(){
    var $sideBar = $('#sidebar');
    var $mainPage = $('#main');
    var sidebarHeight = $sideBar.height();
    var mainHeight = $mainPage.height();
    if($mainPage && $mainPage.children() && $sideBar) {
      if(mainHeight < sidebarHeight) {
        var firstChild = $mainPage.children()[0];
        if(firstChild){
          $(firstChild).css({'min-height': (sidebarHeight - 16) + 'px'});
        }
      }
    }
  })();

  $(".mark_test_official").click( function(event){
    var $checkbox = $(this);
    var $form = $(this).parent("form:first");
    var $row = $form.parents("tr:first");
    $.post($form.attr("action"), {applicant_standardized_test: {official: $checkbox.is(":checked")}, _method: "put"}, function (data) {
      $row.effect("highlight", {color:'#DFD'}, 2000);
      return false;
    });
  });

  $.fn.inlineCheckboxFeedback = function(state) {
    var $element = $(this)
    var $parent = $element.parent()
    var $container = $parent.closest('div, tr')

    var stateColor = '#FFF'
    var stateChanges = function(){}

    if (state == 'sending' ) {
      stateColor = "#FFD"
      stateChanges = function(){
        $element.attr('disabled', true)
        $parent.find('.inline_loading').removeClass('inactive')
        $parent.find('.inline_loading').addClass('active')
      }
    } else if (state == 'success') {
      stateColor = '#DFD'
      stateChanges = function(){
        $element.attr('disabled', false)
        $parent.find('.inline_loading').removeClass('active')
        $parent.find('.inline_loading').addClass('inactive')
      }
    } else if (state == 'failure') {
      stateColor = "#FDD"
      stateChanges = function(){
        $element.attr('disabled', false)
        $parent.find('.inline_loading').removeClass('active')
        $parent.find('.inline_loading').addClass('inactive')
      }
    }

    stateChanges()
    $container.stop(true, true).effect("highlight", {'color': stateColor}, 3000);
  }

  collapseContent()

  $('.state_pane').addClass('clearfix')

  $.extend($.fn.tipsy.defaults, {delayIn: 500, delayOut: 500})

  apply_tipsy()

  // Applicant Photo
  $("#show_applicant_photo_upload").click(function (ev) { ev.preventDefault(); $('#applicant_photo_upload').dialog({ width: 'auto', title: 'Select a photo' }); return false; });

  $(document).on('reorder', 'table.ordered', function(event, row) {
    var table = $(row).parents('table.ordered:first')
    var form = $(row).parents('table.ordered:first').parent('form')[0]
    var data_url = table.attr("data-url")

    if (data_url || form) {
      var action_url = form ? form.action : data_url
      var method = form ? form.method : "post"
      var params = form ? $(form).serialize() : table.find('input[type=hidden]').serialize()

      $[method](action_url, params, function(data, status, request) {
        $(row).effect('highlight', {color: '#dfd'}, 2000)
      }, 'json')

    }
    return false
  });


  $('[data-remote][data-replace]')
    .data('type', 'html')
    .ajaxSuccess(function(event, data) {
      var $this = $(this);
      $($this.data('replace')).html(data);
      $this.trigger('ajax:replaced');
  });

  $(document).on('click', 'body, html', function(evt){
    if(evt.target.className.split(' ').includes('action') ||
      evt.target.className.split(' ').includes('pop_over_toggle_wrapper') ||
      evt.target.className.split(' ').includes('pop_over_toggle') ||
      $(evt.target).closest('tr.opened-popup').length ||
      $(evt.target).closest('.dl-opened').length ||
      $(evt.target).closest('.pop_over').length ||
      $(evt.target).closest('.toggle_detail').length)
    {
      return;
    }

    closeOpenedPopups();
  });

  $(document).on('click', '.clone_log_link', function(){
    $(this).closest('tr').next().toggle();
  })

  reorder_widget_instruction();

  $('form.new_medical_user_form a.button').removeClass('submit_form_link');

  function get_medical_schools(callback){
    if($('#user_cycle_id').length && $('#user_cycle_id').val().length == 0){
      $('form.new_medical_user_form a.button').removeClass('submit_form_link').addClass('grey-background cursor-auto');
      $('#residency_user_identities_college_base').html('');
      return false
    }
    $.get('/residency_users/' + $('.medical_school_cycle').val() + '/load_medical_schools', function(data){
      $('#residency_user_identities_college_base').html('');
      if(data.length > 0 && $('.new_medical_user_form').find('#user_email').val().length > 0){
        $('form.new_medical_user_form a.button').removeClass('grey-background cursor-auto').addClass('submit_form_link');
      }
      $.each(data, function(index, item){
        $('#residency_user_identities_college_base').append('<option '+ (item[2] ? item[2] : '') +'value="'+ item[1] +'">'+ item[0] +'</option>')
      })
      if (typeof callback === 'function') {
          callback();
      }
    })
  }

  if ($('#user_cycle_id').length && $('#user_cycle_id').val().length > 0) {
    get_medical_schools(function() {
        $('#residency_user_identities_college_base').val($('.data-tag').data('medical'));
    });
  }

  $(document).on('change', '.medical_school_cycle', function(){get_medical_schools()})

  $(document).on('keyup', 'form.new_medical_user_form #user_email', function(){
    if($(this).val().length > 0 && $('#user_cycle_id').val().length > 0){
      $('form.new_medical_user_form a.button').removeClass('grey-background cursor-auto').addClass('submit_form_link');
    }
    else{
      $('form.new_medical_user_form a.button').removeClass('submit_form_link').addClass('grey-background cursor-auto');
    }
  })
});

function designationDisplayStateToggle(is_active, button, rows){
  is_active ? button.text('Collapse All') : button.text('Expand All');
  rows.toggle()
  rows.toggleClass('active');
}

$(window).on('beforeunload', function () {
  window.pageIsClosing = true;
});

// make flash work with ajax
$(document).ajaxComplete(function(event, request){
  var flash = {}
  if (request.getResponseHeader('X-Flash-Messages') != undefined) {
    flash = JSON.parse(request.getResponseHeader('X-Flash-Messages'))
  }
  if(!flash) return
  var flashTypes = ['positive', 'negative', 'notice']
  for(var ix = 0;ix < flashTypes.length; ++ix) {
    var k = flashTypes[ix];
    $('#flash ' + '.' + k).html(flash[k]);
  }
});

function updateProgressIndicator(url) {
  setTimeout( function() {
    $.get(url, function(data) {
      $('#scoring_progress').html(data);
    })
  }, 5000);
}

window.closeOpenedPopups = function() {
  $('tr.opened-popup').each(function(){
    closeTablePopup($(this));
  })

  $('dt.dl-opened, dd.dl-opened').each(function(){
    closeDlPopup($(this));
  })

  $('.action.opened').each(function(){
    closeActionLink($(this));
  })
}

function setScreenReaderHelpText(tabArray, statePane){
  var tabCount = tabArray.length;
  var tabNames = tabArray.join(', ');
  var labelNoun = tabCount > 1 ? 'are' : 'is';
  var labelTab = tabCount > 1 ? 'tabs' : 'tab';
  if (tabCount > 1){
    var dirLabel = '. Please use the right and left arrows to move between tabs and press the enter key to read the tab contents.';
  }else{
    var dirLabel = '. Please press the enter key to read the tab contents.';
  }
  var helpText = 'There ' + labelNoun + ' ' + tabCount + ' ' + labelTab + ' in this panel which ' + labelNoun + ' ' + tabNames + dirLabel;
  statePane.prepend('<p class="screen-reader-only">' + helpText + '</p>' );
  statePane.find('p.screen-reader-only').uniqueId();
}

window.closeOtherPopups = function(key){
    $('.action.opened').each(function(){
      closeActionLink($(this));
    })
    $('tr.opened-popup').each(function(){
      if(!$(this).hasClass(key)){
        closeTablePopup($(this));
      }
    })
    $('dt.dl-opened, dd.dl-opened').each(function(){
      if(!$(this).hasClass(key)){
        closeDlPopup($(this));
      }
    })
}

function closeTablePopup(element){
  element.removeClass('opened-popup').addClass('closed-popup');
  var dataOrigin = element.find('td').children().first().attr('data-origin');
  if($('#' + dataOrigin).attr('aria-label') !== undefined){
    let labelText = $('#' + dataOrigin).attr('aria-label').replace('Hide', 'Show');
    $('#' + dataOrigin).attr('aria-label', labelText);
  }
  if($('#' + dataOrigin + ' a.pop_over_toggle').attr('aria-label') !== undefined){
    let labelText = $('#' + dataOrigin + ' a.pop_over_toggle').attr('aria-label').replace('Hide', 'Show');
    $('#' + dataOrigin + ' a.pop_over_toggle').attr('aria-label', labelText);
  }
}

function closeDlPopup(element){
  element.removeClass('dl-opened').addClass('dl-closed');
  var dataOrigin = element.children().first().attr('data-origin');
  if($('#' + dataOrigin).attr('aria-label') !== undefined){
    let labelText = $('#' + dataOrigin).attr('aria-label').replace('Hide', 'Show');
    $('#' + dataOrigin).attr('aria-label', labelText);
  }
}

function closeActionLink(element){
  element.removeClass('opened').addClass('closed');
}

window.toggleScreenReaderHelptext = function(toggleAction, element){
  setToggleState(toggleAction, 'Show', 'Hide');
  var popupHelpText = 'Please press the Escape key to close.';
  element.find('p.screen-reader-only').remove();
  element.prepend('<p class="screen-reader-only">' + popupHelpText + '</p>' );
  var pId = element.find('p.screen-reader-only').uniqueId().attr('id');
  element.attr({ 'aria-describedby': pId });
}

window.setToggleState = function(toggleAction, from, to){
  setTimeout(function(){ toggleAction.attr({ 'aria-label': toggleAction.attr('aria-label').replace(from, to) }) }, 200);
}

window.checkRowSpan = function(element, details){
  if(element.children('td[rowspan]').length > 0){
    let jumpRow = element.children('td[rowspan]').attr('rowspan');
    let rowIndex = element.index();
    let jumpIndex = rowIndex + (jumpRow - 1);
    element.closest('tbody').children('tr').eq(jumpIndex).after(details);
  }else{
    element.after(details);
  }
}

$( window ).on('load', function() {
  $('select').each(function(){
    var ele = $(this);
    if(ele.attr('data-title') !== undefined){
      ele.find('option[value=""]').attr('title', ele.attr('data-title'))
    }
  });
  if($('.failed_alert').length > 0) {
    $('.failed_alert').focus()
  } else if(window.location.pathname == '/clipboard') {
    if($('.negative').text().length > 0 || $('.notice').text().length > 0) {
      $('.action_list')[0].focus()
    }
  }
})

$( window ).on('load', function() {
  let splash = $("#splash_frame").length
  let errorExplanation = $("#errorExplanation").length
  let flash = $("#flash").length
  let flash_visible = $('#flash').children().is(":visible")
  if(splash > 0) {
    $('#flash').removeAttr( "role" );
    $('.flash_error').removeAttr( "role" );
    $("#errorExplanation").removeAttr( "role" );
    let splashAlert = document.createElement("div");
    splashAlert.setAttribute("class", "screen-reader-only splash_error");
    splashAlert.setAttribute("role", "alert");
    let splashAlertText = document.createTextNode($("#splash_frame").text());
    splashAlert.appendChild(splashAlertText);
    $('#splash_frame').append(splashAlert);
  }
  if(errorExplanation) {
    $('#flash').removeAttr( "role" );
    $('.flash_error').removeAttr( "role" );
    $('.splash_error').removeAttr( "role" );
    if($('#errorExplanation ul li').length > 2){
      $("#errorExplanation").attr({ role: 'alert' })
      $("#errorExplanation ul").attr({ tabindex: "0" });
      $("#errorExplanation ul").focus();
    }else{
      $("#errorExplanation").attr( { role: "alert", tabindex: "0" } );
      $("#errorExplanation").focus();
    }
  }else if((flash > 0 && flash_visible)) {
    $('#flash').removeAttr( "role" );
    $('.splash_error').removeAttr( "role" );
    $("#errorExplanation").removeAttr( "role" );
    let flashAlert = document.createElement("span");
    flashAlert.setAttribute("class", "screen-reader-only flash_error");
    flashAlert.setAttribute("role", "alert");
    let flashAlertText = document.createTextNode($("#flash").text());
    flashAlert.appendChild(flashAlertText);
    document.body.appendChild(flashAlert);
    if (($('.applicant_name h1').length === 0 ) && $('#flash .negative').length > 0) {
      $("html, body").animate({ scrollTop: 0 }, "fast");
      $('form:not(.filter) :input:visible:enabled:first').focus();
    }
  }
})

window.isDate = function (txtDate, yearfirst) {
  var currVal = txtDate;
  if (currVal == '')
    return true;

  var rxDatePattern = yearfirst ? /^(\d{4})(-)(\d{1,2})(-)(\d{1,2})$/ : /^(\d{1,2})(\/)(\d{1,2})(\/)(\d{4})$/

  var dtArray = currVal.match(rxDatePattern);

  if (dtArray == null)
    return false;

  if (yearfirst) {
    var dtYear = dtArray[1];
    var dtMonth = dtArray[3];
    var dtDay = dtArray[5];
  } else {
    var dtMonth = dtArray[1];
    var dtDay = dtArray[3];
    var dtYear = dtArray[5];
  }

  return dateFormatCheck(dtDay, dtMonth, dtYear)
}

window.dateFormatCheck = function(day, month, year) {
  if ((month < 1 || month > 12) || (year < 1900))
    return false;
  else if (day < 1 || day > 31)
    return false;
  else if ((month == 4 || month == 6 || month == 9 || month == 11) && day == 31)
    return false;
  else if (month == 2) {
    var isleap = (year % 4 == 0 && (year % 100 != 0 || year % 400 == 0));
    if (day > 29 || (day == 29 && !isleap))
      return false;
  }
  return true;
}

$(document).on('keydown', '.j-search-datepicker, .pickerdialog, .assignment_date, .datepicker, .date-datepicker, .projected-datepicker, .expiration_date_picker, .datepicker_format, .date_picker, .open-date-picker, .association-date-picker', function(e){
  var currentObj = $(this);
  var alertId = $(this).attr('data-picker-status');
  if (e.keyCode === 9 || e.keyCode === 13) {
    var yearfirst = currentObj.hasClass('datepicker_format')
    var format = isDate(currentObj.val(), yearfirst);
    if (!format) {
      e.preventDefault();
      if (yearfirst){
        $('#' + alertId).text('Please enter valid date in yyyy-mm-dd format');
      } else {
        $('#' + alertId).text('Please enter valid date in mm/dd/yyyy format');
      }
    }
  }
});

$(function() {
  $('#filter_form, #new_email_search, #assignment_form, #pdf_template_form, #association_form, #edit_program, #new_program').on('submit', function(e) {
    $('.j-search-datepicker, .pickerdialog, .datepicker, .pickerenddate, .open-date-picker, .association-date-picker').each(function(){
      var currentObj = $(this);
      var yearfirst = currentObj.hasClass('datepicker_format')
      var format = isDate(currentObj.val(), yearfirst)
      if(!format){
        alert(currentObj.attr('aria-label'));
        e.preventDefault();
      }
    });
  });
});

$(document).on('keypress', '#pdf_manager_template_last_printed_start_date, #pdf_manager_template_last_printed_end_date', function(e){
   var key = e.keyCode;
   if ((key >= 47 && key <= 57) || key == 191 || key == 9 || key == 13) {
     return true;
   } else {
     return false;
   }
});

$(document).on('keyup','#user_password', function(){
  var ele = $(this).val();
  var minChar = $('.minChar').attr('data-char');
  var upper = ele.toUpperCase();
  ele == '' ? showStrongtext() : hideStrongtext()
  addRemoveIcon('.minChar', ele.length >= minChar)
  addRemoveIcon('.upperCase', ele.match(/[A-Z]/))
  addRemoveIcon('.lowerCase', ele.match(/[a-z]/))
  addRemoveIcon('.numSpec', (ele.match(/[0-9]/) || ele.match(/[^a-zA-Z0-9]/)))
});

function addRemoveIcon(toggleClass, status){
  var toggle = $(toggleClass)
  if(status){
    toggle.addClass('icon')
    toggle.parent().children(':first-child').removeClass('text-red')
  }else{
    toggle.removeClass('icon')
    toggle.parent().children(':first-child').addClass('text-red')
  }
}

function showStrongtext(){
  $('.noName').addClass('text-red');
  $('.noName').show();
}

function hideStrongtext(){
  $('.noName').removeClass('text-red');
  $('.noName').hide();
}

$(document).on('keyup','#user_password_confirmation', function(){
  if($('div.password-criteria').length == $('span.icon').length && $('span.noName').is(':hidden') && $(this).val() == $('#user_password').val())
    $('.submit').removeAttr("disabled");
  else
    $('.submit').attr('disabled',true)
});

$(document).on('blur','#user_password', function(){
  if($(this).val().length != 0) {
    $.ajax({ method: 'POST', url: "/password_validation", data: { password: $(this).val(), id: $('#user_id').val() }, success: function(result){
    if(result.status){
      hideStrongtext();
    }else{
      showStrongtext();
    }
    }});
  }
});

$(document).on('focus','#user_password', function(){
  if($('.text-red').length){
    var msg = 'Please make sure your password meet following requirements. '
    $('.text-red').each(function(){
      msg += $(this).text() + ', '
    });
    $('#pwd-alert').text(msg)
  }
});

$(document).on('blur','#user_password_confirmation', function(){
  if($(this).val().length != 0) {
    if($(this).val() != $('#user_password').val()){
      $('#pwd-alert').text('Passwords must match')
      $('.samePwd').removeClass('hide');
    }else
      $('.samePwd').addClass('hide');
  }
});

$(document).on('focus','#assignment_due_date', function(){
  $('#ui-datepicker-div').attr('aria-label', 'Select a date');
});

$(document).on('focus','#assignment_form', function(){
  var el = $('#assignment_due_date')
  if (el.length && el.hasClass("datepicker") == false) {
    el.addClass('datepicker');
    el.datepicker({
      beforeShow:function(textbox, instance){
        var txtBoxOffset = $(this).offset();
        var top = txtBoxOffset.top;
        var left = txtBoxOffset.left;
        setTimeout(function () {
          instance.dpDiv.css({
            position: 'absolute',
            top: top - 210,
            left: left - 5
          });
        }, 0);
      }
    });
  }
});

$(document).on('keydown', '#assignment_due_date', function(e){
  if(e.keyCode == 13){
    $(this).click();
    e.preventDefault();
  }
});

$(document).on('click', '#assignment_due_date', function(e){
  $('#assignment_due_date').datepicker('show');
});

window.reorder_widget_instruction = function () {
  $('tr.reorder_widget').on('focus', function(){
    $('.screen-reader-only#reorder-status').text('Use Control + j to move row down/Control + p to move row up');
  });
  $('tr.reorder_widget').on('focusout', function(){
    $('.screen-reader-only#reorder-status').text('');
  });
}

$(document).on('change', '.filter_cas', function(event) {
  var cycle = $('#splash_cycle_id :selected').val();
  var cas = $('#splash_cas_identifier_id :selected').text().toLowerCase().replace(/[^a-zA-Z ]/g, "").replace(/\s/g,'');
  $('.splashCas').hide();
  $('.splashCas input.splash_checkbox').removeClass('splash_checkbox').addClass('splash_checkbox_hidden');
  var selected = 'splashCas';
  if(cycle){
    selected += '.' + cycle;
  }
  if(cas){
    selected += '.' + cas;
  }
  $('.'+selected+ ' input.splash_checkbox_hidden').removeClass('splash_checkbox_hidden').addClass('splash_checkbox');
  $('.'+selected).show();
});

function triggerAccessLink(status, checkedObject, triggerClass, previousState){
  if(status){
    var tableCell = checkedObject.closest('td');
    var link = tableCell.find('.' + triggerClass + ' a');
    $.post(link.attr('href'), function(data) {
      if (data.hasOwnProperty('positive')) {
        tableCell.effect('highlight', {color: '#dfd'}, 2000);
      } else if (data.hasOwnProperty('negative')) {
        checkedObject.prop( "checked", previousState);
        alert(data.negative);
      }
    }, 'json')
  }else{
    checkedObject.prop( "checked", previousState);
  }
}

$(document).on('click', '.user_additional_program_access', function(){
  var currentObj = $(this);
  var msg = 'Are you sure you want to update the Config. Portal Live Editing Access permission? Click OK to confirm or Cancel to go back';
  if(currentObj.is(':checked') == true){
    var access_confirm = confirm(msg);
    triggerAccessLink(access_confirm, currentObj, 'add_access_link', false);
  }else{
    var access_remove = confirm(msg);
    triggerAccessLink(access_remove, currentObj, 'remove_access_link', true);
  }
});

$(document).on('click', '.resend_button', function(){
  $(this).closest('form').submit();
  return false;
});

$('#residency_applicant_usmle_id').keyup(function(e){
  if(/\D/g.test(this.value)){
    this.value = this.value.replace(/\D/g, '');
  }
});
