import 'jquery-ui-dist/jquery-ui';
import 'jquery-hoverintent'
import 'jquery-validation'
import Rails from '@rails/ujs';

window.jQuery = $;
window.$ = $;
window.Rails = Rails
window.WEBADMIT = WEBADMIT;
window.ApplicantGateway = ApplicantGateway;
Rails.start()

console.log('jquery-loaded')
const event = new Event('jquery-loaded');
document.dispatchEvent(event)

import '../../assets/javascripts/application.js'
