// export.js

window.exportFormatChangeValue = function(format, export_limit_event) {
  exportFormatChangeValueCallback(format, function() {
    var current_export_limit_value = exportLimitDropdownDom().val();

    exportLimitDropdownDom().trigger(export_limit_event);

    exportTreePickerDom().trigger(export_limit_event);

    if( current_export_limit_value != exportLimitDropdownDom().val() ) {
      exportLimitDropdownDom().trigger('change');
    }
  });
}

function exportDisplayColumnsDom() {
  return $('#column_count');
}

function exportFieldsListDom() {
  return $('#fields_target');
}

function exportFieldsListChildrenDom() {
  return exportFieldsListDom().children('li.chosen_item');
}

function exportFieldsListChildCategoriesDom() {
  return exportFieldsListDom().children('li.chosen_category');
}

function exportFieldsListChildFieldsDom() {
  return exportFieldsListDom().children('li.chosen_field');
}

// Wanders through the DOM and returns all field limit inputs.
function exportFieldsListChildCategoriesLimitDom() {
  return exportFieldsListChildCategoriesDom().
    children('.field_options_table').children('tbody').children('tr').
    children('td').children('input.field_association_length')
}

// Given an object from the DOM, which is presumed to be
// a limit input element from a field category, find the field list
// object for the field category. Basically the opposite of
// exportFieldsListChildCategoriesWidthDom().
function exportFieldsListChildCategoryLimitParentDom(dom) {
  return dom.parent().parent().parent().parent().parent();
}

function exportFixedWidthDom() {
  return exportFieldsListChildrenDom().children('.fixed_width');
}

function exportFormatDropdownDom() {
  return $('#export_export_format_id');
}

function exportLimitDropdownDom() {
  return $('#export_export_limit_association_id');
}

function exportFieldsListChildCategoryDom(dom) {
  return dom.children('.category_label').first();
}

function exportTreePickerCategoryForField(pickedFieldDom) {
  return pickedFieldDom.parents('.depth_1').children('.tree_node_title');
}

function exportTreePickerCategoryIdForField(pickedFieldDom) {
  return exportTreePickerCategoryForField(pickedFieldDom).
    attr('data-category-id');
}

function exportTreePickerCategoryTitleForField(pickedFieldDom) {
  return exportTreePickerCategoryForField(pickedFieldDom).text();
}

function exportTreePickerCategoryMaxAssociationCountForCategoryId(categoryId) {
  var category = $(exportFieldsListDom().children('li.chosen_category[data-category-id="' + categoryId + '"]'));
  return category.find('input.field_association_length').val();
}

function exportTreePickerCategoryHasAllowMany(pickedFieldDom) {
  return (pickedFieldDom.parents('.depth_1').children('.fields_list').
    children('li').children('.child_node').children('.tree_leaf').
    children('a[data-allow-many="true"]').length != 0);
}

function exportFieldsListCategoryStyleAdd() {
  exportFieldsListDom().addClass('fields_target_categories');
}

function exportFieldsListCategoryStyleRemove() {
  exportFieldsListDom().removeClass('fields_target_categories');
}

function exportFieldsListInCategoryMode() {
  return exportFieldsListDom().hasClass('fields_target_categories');
}

function exportFieldCategoriesAppend(cb) {
  var currentFields = exportFieldsListChildrenDom();

  // This is a bit messy, and I didn't want to couple the mustache template
  // too tightly, but categoryDetails is going to contain all the pertinent
  // information to pass to the mustache template.

  var categoryDetails = [];
  var alreadyHaveCategoryIds = [];
  currentFields.each(function() {
    var currentFieldCategoryId = $(this).attr('data-category-id');

    if($.inArray(currentFieldCategoryId, alreadyHaveCategoryIds) == -1) {

      if($(this).hasClass('chosen_category')) {
        // We already have the field category in the list, so do stuff
        alreadyHaveCategoryIds.push(currentFieldCategoryId);
        delete categoryDetails[currentFieldCategoryId];
      } else {
        // We might not have this category in the list yet, so gather up some
        // details for later if we don't already have it.

        if($.inArray(currentFieldCategoryId, alreadyHaveCategoryIds) == -1) {
          var cDetails = {}
          alreadyHaveCategoryIds.push(currentFieldCategoryId);
          cDetails[currentFieldCategoryId] ={
            category_id: currentFieldCategoryId,
            category: exportFieldsListChildCategoryDom($(this)).text(),
            category_has_allow_many:
              $(this).attr('data-category-has-allow-many') == 'true',
            category_max_association_count:
              $(this).children('.field_association_length').attr('value')
          }
            categoryDetails.push(cDetails)
        }
      }
    }
  });

  $.mustacheLoader('field_chooser_category', function(template) {
    $.each(categoryDetails, function(index, categoryData) {
      var keys = Object.keys(categoryData);
      var fieldCategoryContent = $($.mustache(template, categoryData[keys[0]]));
      fieldCategoryContent.on('input', exportFieldCategoriesPropagateLimit);
      exportFieldsListDom().append(fieldCategoryContent);
    });

    cb();
  });
}

function exportFieldCategoriesBind() {
  exportFieldsListDom().on('sortstop', exportFieldCategoriesSort);
  exportFieldsListChildCategoriesLimitDom().on('input',
    exportFieldCategoriesPropagateLimit);
}

function exportFieldCategoriesUnbind() {
  exportFieldsListDom().off('sortstop', exportFieldCategoriesSort);
}

function exportFieldCategoriesRemove() {
  exportFieldsListChildCategoriesDom().remove();
}

function exportFieldCategoriesSort() {
  var currentFields = exportFieldsListChildrenDom();

  currentFields.detach();

  var fieldCategoryIds = [];
  var fieldsByCategoryId = {};

  currentFields.each(function() {
    var currentFieldCategoryId = $(this).attr('data-category-id');

    if(!(currentFieldCategoryId in fieldsByCategoryId)) {
      fieldsByCategoryId[currentFieldCategoryId] = [];
    }

    if($(this).hasClass('chosen_category')) {
      fieldsByCategoryId[currentFieldCategoryId].unshift(this);

      fieldCategoryIds.push(currentFieldCategoryId);
    } else {
      fieldsByCategoryId[currentFieldCategoryId].push(this);
    }
  });

  var reorderedFields = exportFieldsListDom();

  $.each(fieldCategoryIds, function(index, fieldCategoryId) {
    // If there is only one entry in fieldByCategoryId[fieldCategoryId],
    // then the corresponding category only has one entry, which
    // is the field category entry itself. It is pointless to add
    // an orphan category entry.
    if(fieldsByCategoryId[fieldCategoryId].length != 1) {
      $.each(fieldsByCategoryId[fieldCategoryId], function(index, field) {
        reorderedFields.append(field);
      });
    }
  });
}

function exportFieldCategoriesPropagateLimit(event) {
  var input = event.target;
  var dom = $(input);
  var categoryId = exportFieldsListChildCategoryLimitParentDom(dom).
    attr('data-category-id');

  var currentFields = exportFieldsListChildrenDom();

  currentFields.each(function() {
    var currentFieldCategoryId = $(this).attr('data-category-id');

    if(currentFieldCategoryId == categoryId) {
      $(this).children('.field_association_length').attr('value',
        input.value);
    }
  });
}

window.exportFieldRemove = function (fieldContainerSelector, statusMessage) {
  statusMessage = (statusMessage === undefined) ? false : statusMessage;
  var containerToRemove = $(fieldContainerSelector);
  var fieldId = containerToRemove.attr('data-field');
  var fieldToReenable = $('#field_' + fieldId);
  fieldToReenable.removeClass('taken');
  var focus_el = containerToFocus(containerToRemove);
  $("li.chosen_field[data-field='" + fieldId + "']").remove();
  if(exportFieldsListInCategoryMode()) {
    exportFieldCategoriesSort();
  }
  if(statusMessage){
    $('.j-export-fields-status').text('Field ' + containerToRemove.find('.field').text() + ' removed.');
    focus_el.focus();
    if(focus_el.hasClass('remove_field')){
      $('.j-export-focus-status').text(focus_el.attr('aria-label'));
    }
  }
}

function containerToFocus(containerToRemove){
  var focus_el
  if(containerToRemove.next('li').length > 0){
    focus_el = containerToRemove.next('li').find('a.remove_field');
  }else if(containerToRemove.prev('li').length > 0){
    focus_el = containerToRemove.prev('li').find('a.remove_field');
  }else{
    focus_el = $('.tree_node_title:first a');
  }
  return focus_el;
}

/* Makes the fields list in export active and sortable */
function exportFieldsListInit() {
  exportFieldsListDom().sortable({});
}

function exportFormatChangeValueCallback(value, cb) {
  var dom = exportFormatDropdownDom();

  exportGeneralChangeValueCallback(dom, value, cb);
}

window.exportFormatDisplayFixedWidthHideFor = function (value) {
  exportFormatChangeValueCallback(value, function() {
    exportFixedWidthDom().hide();
  });
}

window.exportFormatDisplayFixedWidthShowFor = function (value) {
  exportFormatChangeValueCallback(value, function() {
    exportFixedWidthDom().show();
  });
}

function exportFormatInit() {
  setTimeout(function() {
    exportFormatDropdownDom().change();
  }, 100)
  WEBADMIT.exports.fixCustomHeaderHeight();
}

window.exportFiledsTreeLeafsLoadAsync = function() {
  $('.child_node.depth_1').on('tree-open', function(event) {
    var target = $(event.target)
    var exportId = $('#export_form').data().exportId
    var tree_node_title = target.find('.tree_node_title');
    var categoryId = tree_node_title.data().categoryId
    if (target.find('.fields_list').text() == 'Loading...') {
      $.ajax({
        url: '/export/lists/fields_leafs',
        data: { category_id: categoryId, id: exportId },
        success: function (data) {
          var field_list = target.find('.fields_list')
          field_list.html(data);
          field_list.find('li').each(function(){
            var a_tag = $(this).find('a')
            var field_id  = a_tag.attr('data-object-id')
            if($('#picked_field_' + field_id).length == 0){
              a_tag.removeClass('taken');
            }
          });
          $('#fields_target li.chosen_field').each(function() {
            $('#field_' + this.dataset.field).addClass('taken');
          })
        },
        error: function (data) {
          target.find('.fields_list').html('<li><div>Error occured...</div></li>');
        }
      });
    }
  });
}

/* Set up the export stuff. Don't call this via .ready() in some
   Javascript somewhere, but call this from the field chooser HTML directly.
   This way, load times on other pages hopefully improves. */
window.exportInit = function() {
  exportFieldsListInit();
  exportLimitInit();
  exportTreePickerInit();
  exportFormatInit();
  validateChoosenField();
  WEBADMIT.exports.showOrHideListCriteriaInput();
}

function addItemToExportLimitAssociation( event ) {
  exportLimitDropdownDom().not(':has(option[value="' + event.data.id + '"])').append($('<option/>', { value: event.data.id, text: event.data.name }));
}

function removeItemFromExportLimitAssociation( event ) {
  exportLimitDropdownDom().find('option[value="' + event.data.id + '"]').remove();
}

window.exportLimitAddMultiFile = function (id, name) {
  exportLimitDropdownDom().on('export_limit.single_file', { id: id }, removeItemFromExportLimitAssociation);
  exportLimitDropdownDom().on('export_limit.multi_file', { id: id, name: name }, addItemToExportLimitAssociation);
}

window.exportLimitAddSingleFile = function (id, name) {
  exportLimitDropdownDom().on('export_limit.single_file', { id: id, name: name }, addItemToExportLimitAssociation);
  exportLimitDropdownDom().on('export_limit.multi_file', { id: id }, removeItemFromExportLimitAssociation);
}

window.exportLimitDisplayColumnsHideFor = function (value) {
  exportLimitChangeValueCallback(value, function() {
    exportDisplayColumnsDom().hide();
  });
}

window.exportLimitDisplayColumnsShowFor = function(value) {
  exportLimitChangeValueCallback(value, function() {
    exportDisplayColumnsDom().show();
  });
}

window.exportLimitFieldCategoriesHideFor = function (value) {
  exportLimitChangeValueCallback(value, function() {
    exportFieldsListCategoryStyleRemove();
    exportFieldCategoriesRemove();
    exportFieldCategoriesUnbind();
  });
}

window.exportLimitFieldCategoriesShowFor = function(value) {
  exportLimitChangeValueCallback(value, function() {
    exportFieldsListCategoryStyleAdd();
    exportFieldCategoriesAppend(function() {
      exportFieldCategoriesSort();
      exportFieldCategoriesBind();
    });
  });
}

function exportLimitChangeValueCallback(value, cb) {
  var dom = exportLimitDropdownDom();

  exportGeneralChangeValueCallback(dom, value, cb);
}

function exportLimitInit() {
  exportLimitDropdownDom().change();
}

function exportGeneralChangeValueCallback(dom, value, cb) {
  dom.change(function() {
    if($(this).val() == value) { cb(); }
  });
}

function exportTreePickerDom() {
  return $('#export_field_list');
}

function exportTreePickerInit() {
  $(document).on('click', '#export_field_list .pickable_field', exportTreePickerPick);
  $(document).on('click', '#export_field_list .add', exportTreePickerAddCategory);
  $(document).on('click', '#export_field_list .remove', exportTreePickerRemoveCategory);
  exportTreePickerDom().on('export_limit.multi_file', exportTreePickerHideSingleFileOnlyItems);
  exportTreePickerDom().on('export_limit.single_file', exportTreePickerShowSingleFileOnlyItems);
}

function exportTreePickerSingleFileOnlyItemsDom() {
  return $('*[data-can-export-multiple-files="false"]').closest('li');
}

WEBADMIT.exports = (function () {
  function customHeaderInputs() {
    return $('.custom-header-input');
  }

  function listCriteria() {
    return $('#export_criteria');
  }

  function listCriteriaInput() {
    return $('#export_use_list_criteria');
  }

  function exportList() {
    return $('#export_list_id');
  }

  function isUsingHeadings() {
    return !!parseInt($('#export_headings').val(), 10);
  }

  function isUsingList() {
    let referenceIndex = exportList().find('option[value="------------"]').index();
    if(referenceIndex == -1) {
      return false;
    }
    return exportList().prop('selectedIndex') > referenceIndex
  }

  return {
    exportFormatMultipleFiles: null,
    addWatcherOnWithOrWithoutHeadings: function () {
      $('#export_headings').on('change', WEBADMIT.exports.showOrHideCustomHeaderInputs);
    },
    addWatcherOnExportList: function () {
      exportList().on('change', WEBADMIT.exports.showOrHideListCriteriaInput);
    },
    showOrHideListCriteriaInput: function () {
      if (isUsingList()) {
        listCriteria().show();
      } else {
        listCriteriaInput().find("option:selected").prop("selected", false)
        listCriteria().hide();
      }
    },
    showOrHideCustomHeaderInputs: function () {
      if (!isUsingHeadings() || WEBADMIT.exports.exportFormatMultipleFiles) {
        customHeaderInputs().hide();
        customHeaderInputs().prop('disabled', true);
      } else {
        customHeaderInputs().show();
        customHeaderInputs().prop('disabled', false);
      }

    },
    fixCustomHeaderHeight: function () {
      if (isUsingHeadings() && !WEBADMIT.exports.exportFormatMultipleFiles) {
        customHeaderInputs().each(function(index, element) {
          WEBADMIT.exports.autoGrow(element)
        });
      }
    },
    autoGrow: function (customHeaderTextarea) {
      customHeaderTextarea = $(customHeaderTextarea);
      var hiddenDiv = $(document.createElement('div'));
      hiddenDiv.addClass('hidden-textarea-content');
      hiddenDiv.css('font-size', customHeaderTextarea.css('font-size'));
      hiddenDiv.css('font-family', customHeaderTextarea.css('font-family'));
      hiddenDiv.css('width', customHeaderTextarea.width());
      $('body').append(hiddenDiv);

      var content = customHeaderTextarea.val() || customHeaderTextarea.attr('placeholder');
      content = content.replace(/\n/g, '<br>');
      hiddenDiv.html(content + '<br>');
      customHeaderTextarea.css('height', hiddenDiv.height());
      hiddenDiv.remove();
    }
  };
})();

function showOrHideTreePickerSingleOrMultipleFiles(multipleFiles) {
  WEBADMIT.exports.exportFormatMultipleFiles = multipleFiles;
  WEBADMIT.exports.showOrHideCustomHeaderInputs();
  if (multipleFiles) {
    exportTreePickerSingleFileOnlyItemsDom().hide();
  } else {
    exportTreePickerSingleFileOnlyItemsDom().show();
  }
}

window.exportTreePickerHideSingleFileOnlyItems = function() {
  showOrHideTreePickerSingleOrMultipleFiles(true);
}

function exportTreePickerShowSingleFileOnlyItems() {
  showOrHideTreePickerSingleOrMultipleFiles(false)
}

function getFieldDetails(field, fieldId, categoryId) {
  return {
    name: field.text(),
    id: fieldId,
    identifier: field.data('identifier'),
    header: field.data('header'),
    category: exportTreePickerCategoryTitleForField(field),
    category_id: categoryId,
    category_has_allow_many:
      exportTreePickerCategoryHasAllowMany(field),
    can_export_multiple_files: field.attr('data-can-export-multiple-files'),
    width: field.attr('data-default-width'),
    has_filters: field.data('has_filters'),
    filters: field.data('filters'),
    has_transformations: field.data('has_transformations'),
    has_customize_export_manager_column_headers_feature: field.data('has_customize_export_manager_column_headers_feature'),
    transformations: field.data('transformations'),
    max_association_count: exportTreePickerCategoryMaxAssociationCountForCategoryId(categoryId)
  };
}

function exportTreePickerPick() {
  var pickedField = $(this);
  $('.j-export-fields-status').text('Field ' + pickedField.text() + ' added.')

  if(!pickedField.hasClass('taken')) {
    var fieldId = pickedField.attr('data-object-id');
    var categoryId = exportTreePickerCategoryIdForField(pickedField);
    var fieldDetails = getFieldDetails(pickedField, fieldId, categoryId);

    $.mustacheLoader('field_chooser', function(template) {
      var fieldContent = $($.mustache(template, fieldDetails));
      var currentFields = exportFieldsListChildrenDom();
      var currentFieldIds = currentFields.map(function(){
        return $(this).attr('data-field');
      });

      if($.inArray(fieldId, currentFieldIds) == -1) {
        exportFieldsListDom().append(fieldContent);

        if(exportFieldsListInCategoryMode()) {
          exportFieldCategoriesAppend(exportFieldCategoriesSort);
        }
      }

      exportFormatDropdownDom().change();
      customHeaderHeightForFields(pickedField);
    })
    pickedField.addClass('taken');
    if(pickedField.attr('data-allow-many') == 'true'){
      addMultivalueToCategory(categoryId);
    }
  }
}

function exportTreePickerPickFullCategory(fields, categoryId) {
  var html = '';

  $.mustacheLoader('field_chooser', function(template) {
    var categoryMultiValued = false;
    for (var index = 0, field, n = fields.length; index < n; index += 1) {
      field = $(fields[index]);
      var fieldId = field.attr('data-object-id');
      if($('#picked_field_' + fieldId).length == 0){
        var fieldDetails = getFieldDetails(field, fieldId, categoryId);
        var fieldContent = $($.mustache(template, fieldDetails));
        html += fieldContent[0].outerHTML;
      }
      field.addClass('taken');
      if(field.attr('data-allow-many') == 'true'){
        categoryMultiValued = true;
      }
    }

    $('#fields_target').append(html);
    if (exportFieldsListInCategoryMode()) {
      exportFieldCategoriesAppend(exportFieldCategoriesSort);
    }
    if(categoryMultiValued){
      addMultivalueToCategory(categoryId);
    }
    exportFormatDropdownDom().change();
    for (var index = 0, field, n = fields.length; index < n; index += 1) {
      field = $(fields[index]);
      customHeaderHeightForFields(field);
    }
  });
  $('.j-export-fields-status').text('All fields of the field category ' + $('.tree_node').find("[data-category-id='" + categoryId + "']").find('a').text() + ' added.');
}

function exportTreePickerAddCategory(event) {
  var start = performance.now();
  var fieldsInCategory = $(this).closest('.fields_list');
  var categoryId = $(this).parents('.depth_1').children('.tree_node_title').attr('data-category-id');

  exportTreePickerPickFullCategory(fieldsInCategory.find('.pickable_field:not(.taken)'), categoryId);

  event.preventDefault? event.preventDefault() : event.returnValue = false;
  var end = performance.now();
  console.log('took ' + (end - start) + ' milliseconds to execute.');
}

function exportTreePickerRemoveCategory(event) {
  var fieldsInCategory = $(this).closest('.fields_list');

  fieldsInCategory.find('.taken').each(function(index, field) {
    var id = $(field).data('object-id');
    var remove = $('#picked_field_' + id + ' .remove_field')
    remove.click();
  });
  $('.j-export-fields-status').text('All fields of the field category ' + $(this).closest('.fields_list').find('a.pickable_field:first').attr('data-category-name') + ' removed.');

  event.preventDefault ? event.preventDefault() : event.returnValue = false;
}

function validateChoosenField() {
  if ($('.negative').html() !== ''){
    $('.chosen_field').each(function() {
      var fieldId = ($( this ).data( "field" ));
      var selectedField = $('#field_' + fieldId)
      if (!selectedField.hasClass("taken")) {
        selectedField.addClass('taken');
      }
    });
  }
}

function addMultivalueToCategory(categoryId) {
  var categorySelected = $("li.chosen_category[data-category-id='" + categoryId + "']")
  if(categorySelected.has("table.field_options_table").length === 0 ) {
    $.mustacheLoader('category_multi_value', function(template) {
      var categoryName = { category: $(".tree_node_title[data-category-id='" + categoryId + "']").find('a').text() };
      var tableContent = $($.mustache(template, categoryName));
      categorySelected.append(tableContent);
      var categoryFields = $("li.chosen_field[data-category-id='" + categoryId + "']");
      categoryFields.find("input[name='export[max_association_counts][]']").val('');
    });
  }
}

window.customHeaderHeightForFields = function(field) {
  var id = field.data('object-id');
  var element = $('#picked_field_' + id + ' .custom-header-input');
  if(element.length > 0) {
    WEBADMIT.exports.autoGrow(element);
  }
}
