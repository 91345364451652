/* action_list.js */
WEBADMIT.ActionList = {};

;(function ($, WEBADMIT) {
  WEBADMIT.ActionList.addAttrTextAndToolTip = function (text, list) {
    list.find('a').each(function() {
      if ($(this).is('[preserve-click]')) {
        return true;
      } else {
        $(this).attr('original-title', text);
        $(this).addClass('tipsy-right');
        apply_tipsy();
      }
    });
  };

  WEBADMIT.ActionList.displayDisableList = function (object) {
    var top = object.offset().top - $(window).scrollTop();
    var height = object.height();
    var width = object.width();
    var left = object.offset().left;
    var windowWidth = $(window).width();
    var calTop = top + height + 3;
    var calRight = windowWidth - ( left + width );
    object.find('ul.disable').css({ top: calTop + 'px', right: calRight + 'px' });
    object.children('ul.disable').fadeIn(100);
    object.find('h2 div').attr({'aria-expanded': 'true'});
  };

  WEBADMIT.ActionList.displayNormalList = function (object) {
    var top = object.offset().top - $(window).scrollTop();
    var height = object.height();
    var width = object.width();
    var left = object.offset().left;
    var windowWidth = $(window).width();
    var calTop = top + height + 3;
    var calRight = windowWidth - ( left + width );
    object.find('ul.buttons').css({ top: calTop + 'px', right: calRight + 'px' });
    object.children(':not(h2, ul.disable)').fadeIn(100);
    object.find('h2 div').attr({'aria-expanded': 'true'});
  };

  WEBADMIT.ActionList.removeAllEvents = function (list) {
    list.find('a').each(function() {
      if ($(this).is('[preserve-click]')) {
        return true;
      } else {
        $(this).off();
        $(this).removeAttr('href');
      }
    });
  };

  WEBADMIT.ActionList.hideNormalList = function (object) {
    object.children(':not(h2)').fadeOut(100);
    object.find('h2 div').attr({'aria-expanded': 'false'});
  };

  WEBADMIT.ActionList.createDisabledList = function (list) {
    var items = list.find('.buttons');
    var disabledText = 'You have not selected any applicants.';

    if(list.find('.disable').length === 0  && list.hasClass('disablable')) {
      var newItems = items.clone();
      newItems.find('*').removeAttr('id');
      newItems.addClass('disable');
      list.append(newItems);
      WEBADMIT.ActionList.removeAllEvents(newItems);
      WEBADMIT.ActionList.addAttrTextAndToolTip(disabledText, newItems);
    }
  };

  WEBADMIT.ActionList.checkToggleAll = function (list) {
    if ($('.toggle_all_selection').is(':checked')) {
      WEBADMIT.ActionList.displayNormalList(list);
      list.removeClass('disable');
    } else if (list.hasClass('disablable')) {
      WEBADMIT.ActionList.displayDisableList(list);
      list.addClass('disable');
    } else {
      WEBADMIT.ActionList.displayNormalList(list);
    }
  };

  WEBADMIT.ActionList.checkToggleItems = function (list) {
    var numberChecked = $('.toggle_item_selection:checked').length;
    if (numberChecked === 0) {
      WEBADMIT.ActionList.displayDisableList(list);
      list.addClass('disable');
    } else {
      WEBADMIT.ActionList.displayNormalList(list);
    }
  };
})(jQuery, WEBADMIT);

$(document).ready(function() {
  $('.action_list').mouseenter(function() {
    var list = $(this);
    WEBADMIT.ActionList.createDisabledList(list);
    WEBADMIT.ActionList.checkToggleAll(list);
    WEBADMIT.ActionList.checkToggleItems(list);
  });

  $('.action_list').mouseleave(function() {
    var list = $(this);
    WEBADMIT.ActionList.hideNormalList(list);
  });

  $('.action_list').on('keyup', function(e){
    var list = $(this);
    var keycode = (e.keyCode ? e.keyCode : e.which);
    if(keycode === 13){
      WEBADMIT.ActionList.createDisabledList(list);
      WEBADMIT.ActionList.checkToggleAll(list);
      WEBADMIT.ActionList.checkToggleItems(list);
    }else if(keycode === 27){
      WEBADMIT.ActionList.hideNormalList(list);
    }
  });

  $(window).on('scroll', function(){
    var list = $('.action_list');
    WEBADMIT.ActionList.hideNormalList(list);
    $('body').find('.tipsy').each(function(){
      $(this).remove();
    });
  });
});
