$(document).ready(function() {
  $("#show_api_key").click(function( event ) {
    event.preventDefault();
    get_api_key()
  });

  $("#generate_access_token").click(function( event ) {
    event.preventDefault();
    confirm_api_create()
  });

  function api_token_from_json(json) {
    var token = json.access_token
    if (token === null) {
      return "Please Generate a Key"
    } else {
      return token
    }
  }

  function confirm_api_create() {
    if (confirm("Generating a new token will replace your old token.") == true) {
      create_api_key();
    }
  }

  function create_api_key() {
    $.post("/api_keys", replace_access_token);
  }

  function get_api_key() {
    $.getJSON("/api_keys", replace_access_token_show);
  }

  function replace_access_token(json) {
    var access = "<div class='token' tabindex='0'><span class='screen-reader-only'>New API Key: </span>" + api_token_from_json(json) + " </div>";
    $("#access_token").html(access);
    $("#access_token_show").hide();
    $("#access_token").show();
    setTimeout(function(){$('.token').focus()}, 300);
  }

  function replace_access_token_show(json) {
    var access = "<div class='token' tabindex='0'><span class='screen-reader-only'>API Key: </span>" + api_token_from_json(json) + " </div>";
    $("#access_token_show").html(access);
    $("#access_token_show").removeClass('hide');
    $("#show_api_key").hide();
    setTimeout(function(){$('.token').focus()}, 300);
  }

});
