/* jquery.pops.js */

;(function($){

  $.fn.ignore = function(sel){
    return this.clone().find(sel || ">*").remove().end();
  };

  // Pop ins appear in normal page flow
  $.fn.initPopIn = function(popConfig){

  };

  // Pop ups do not reflow content
  $.fn.initPopOver = function(popConfig){
    var allPopOvers = this;
    allPopOvers.off('.popOver');
    this.each(function(){
      var popOver = $(this);
      var popOverToggle  = popOver.children('a.pop_over_toggle');
      var popOverHref    = popOverToggle.attr('href');
      var popOverTarget  = popOverHref ? $(popOverHref.match(/#.*$/)[0]) : popOver;
      var popOverAction  = popOver.find('.pop_over_toggle .action');
      var popOverDismiss = popOverTarget.find('.pop_over_dismiss');
      popOver.on('click.popOver', function(event){
        var leftPosition = $(this).offset().left;
        var fullWidth = $(window).width();
        var alignment = leftPosition > (fullWidth / 2) ? "customized-float-right" : "customized-float-left";

        if ($(event.target).closest('.pop_over_toggle').length) {
          var key = popOver.uniqueId().attr('id');
          var targetRow = popOver.closest('tr');

          closeOtherPopups(key);

          if(targetRow.length) {
            var detailsTr = $('tr.' + key);

            var closestTd = popOver.closest('td');
            var header = closestTd.closest('table').find('th').eq(closestTd.index());
            if(header.length){
              var header_id = header.uniqueId().attr('id');
              var header_html = 'headers="' + header_id + '"'
            }
            else{
              var header_html = ''
            }

            if(detailsTr.length && detailsTr.hasClass('opened-popup')) {
              detailsTr.removeClass('opened-popup').addClass('closed-popup');
              if(popOverToggle.attr('aria-label') !== undefined){
                setToggleState(popOverToggle, 'Hide', 'Show');
              }
            }
            else if(detailsTr.length && detailsTr.hasClass('closed-popup')){
              detailsTr.removeClass('closed-popup').addClass('opened-popup');
              detailsTr.find('td').children().first().focus();
              if(popOverToggle.attr('aria-label') !== undefined){
                setToggleState(popOverToggle, 'Show', 'Hide');
              }
            }
            else {
              var content = popOverTarget.html();
              popOverTarget.empty();
              if (content != undefined) {
                detailsTr = $('<tr class="opened-popup ' + key + '"><td class="padding5" colspan="100%"' + header_html + '>' + content + '</td></tr>');
                checkRowSpan(targetRow, detailsTr);
                var tdFirstChild = $('tr.' + key + ' td').children().first();
                tdFirstChild.attr({'tabindex': 0, 'data-origin': popOver.attr('id')});
                tdFirstChild.addClass(alignment).focus();
                if(popOverToggle.attr('aria-label') !== undefined){
                  toggleScreenReaderHelptext(popOverToggle, tdFirstChild);
                }
              }
            }
          } else {
            var targetDd = popOver.closest('dd');

            if(targetDd.length) {
              var detailsDt = $('dt.' + key);
              var detailsDd = $('dd.' + key);
              if(detailsDt.length && detailsDt.hasClass('dl-opened') && detailsDd.length && detailsDd.hasClass('dl-opened')) {
                detailsDt.removeClass('dl-opened').addClass('dl-closed');
                detailsDd.removeClass('dl-opened').addClass('dl-closed');
                if($(this).attr('aria-label') !== undefined){
                  setToggleState(popOverToggle, 'Hide', 'Show');
                }
              }
              else if(detailsDt.length && detailsDt.hasClass('dl-closed') && detailsDd.length && detailsDd.hasClass('dl-closed')){
                detailsDt.removeClass('dl-closed').addClass('dl-opened');
                detailsDd.removeClass('dl-closed').addClass('dl-opened');
                detailsDd.children().first().focus();
                $(this).removeClass('closed').addClass('opened');
                if($(this).attr('aria-label') !== undefined){
                  setToggleState(popOverToggle, 'Show', 'Hide');
                }
              }
              else {
                var content = popOverTarget.html();
                popOverTarget.empty();
                if (content != undefined) {
                  detailsDt = $('<dt class="dl-opened ' + key + '">&nbsp;</dt>');
                  detailsDd = $('<dd class="dl-opened ' + key + '">' + content + '</dd>');
                  targetDd.after(detailsDd);
                  targetDd.after(detailsDt);
                  var ddFirstChild = $('dd.' + key).children().first();
                  ddFirstChild.attr({'tabindex': 0, 'data-origin': popOver.attr('id')});
                  ddFirstChild.focus();
                  if($(this).attr('aria-label') !== undefined){
                    toggleScreenReaderHelptext(popOverToggle, ddFirstChild);
                  }
                }
              }
            } else {
              var popOverAction  = popOver.find('.pop_over_toggle .action');
              if(popOver.hasClass('opened')){
                $.closePop(popOver, popOverTarget, popOverAction);
                if(popOverToggle.attr('aria-label') !== undefined){
                  setToggleState(popOverToggle, 'Hide', 'Show');
                }
              } else {
                popOver.trigger('opened');
                $.closeAllPop(allPopOvers);
                $.openPop(popOver, popOverTarget, popOverAction);
                $.positionPopOver(popOverToggle, popOverTarget);
                popOver.find('.pop_over_target').css({position: 'static', marginTop: 16});
                popOverTarget.find('.content').attr({'tabindex': 0, 'data-origin': popOver.attr('id')});
                popOverTarget.find('.content').focus();
                if(popOverToggle.attr('aria-label') !== undefined){
                  toggleScreenReaderHelptext(popOverToggle, popOverTarget.find('.content'));
                }
              }
            }
          }
          event.preventDefault ? event.preventDefault() : event.returnValue = false;
        }
      });
      popOverDismiss.on('click.popOver', function(){
        popOver.trigger('closed');
        $.closeAllPop(allPopOvers);
        if(popOverToggle.attr('aria-label') !== undefined){
          setToggleState(popOverToggle, 'Hide', 'Show');
          popOverToggle.focus();
        }
      });

    });
    return allPopOvers;
  };

  $.positionPopOver = function(relativeTo, elementToMove){

    relativeTo = $(relativeTo);
    elementToMove = $(elementToMove);
    var popOver = relativeTo.closest('.pop_over');

    if(popOver.hasClass('right')){
      // This calculation is only approximate
      var relativeToLeft = relativeTo.position()['left'] + relativeTo.width() - elementToMove.width() + 24;
    } else {
      var relativeToLeft = relativeTo.position()['left'];
    }

    var relativeToTop = relativeTo.position()['top'] + relativeTo.height() + 21;
    elementToMove.css({'top': relativeToTop + 'px', 'left': relativeToLeft + 'px'});

    var elementToMoveTab = elementToMove.find('.tab');
    if(popOver.hasClass('right')){
      // This calculation is only approximate
      elementToMoveTab.css({'left': (elementToMove.width() - (relativeTo.width() / 2) - 40) + 'px'})
    } else {
      elementToMoveTab.css({'left': ((relativeTo.width() / 2) - 6) + 'px'})
    }
  };

  $.closeAllPop = function(allPopOvers){
    allPopOvers.each(function(){
      var popOver = $(this);
      var popOverTarget = $(popOver.find('.pop_over_toggle').attr('href').match(/#.*$/)[0]);
      var popOverAction = popOver.find('.pop_over_toggle .action');
      $.closePop(popOver, popOverTarget, popOverAction);
    });
  };

  $.closePop = function(popOver, popOverTarget, popOverAction){
    if ( popOver.hasClass('opened') ) {
      popOver.trigger('closedPopOver')
      popOver.removeClass('opened');
    }
    popOver.addClass('closed');
    popOverAction.text('Open');
    popOverTarget.hide();
  };

  $.openPop = function(popOver, popOverTarget, popOverAction){
    if ( popOver.hasClass('closed') ) {
      popOver.trigger('openedPopOver')
      popOver.removeClass('closed');
    }
    popOver.addClass('opened');
    popOverAction.text('Close');
    popOverTarget.show();
  };

})(jQuery);

$(document).ready(function(){
  $('.pop_over').initPopOver();
});
