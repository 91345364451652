ApplicantGateway.BatchActivityModal = function(activityTypes) {
  var programs = JSON.parse($('a.enable_applicant_gateway_activity_link').attr('data-programs'));
  ScheduleInterview.Modal.call(
    this,
    {
      activityTypes: activityTypes,
      programs: programs,
      interviewTypeOptions: { hide_full: 1 },
      title: 'Enable Applicant Gateway Activities for Checked Applicants'
    });

  this.getViewBuilder = function() {
    return (this.viewBuilder || (this.viewBuilder = new ScheduleInterview.ViewBuilder(
      {
	viewSections: [new ScheduleInterview.InterviewTypeSection(),
		       new ScheduleInterview.ProgramSection(),
		       new ApplicantGateway.ActivityTypeSection()],
	activityTypes: this.options.activityTypes,
	interviewTypes: this.interviewTypes,
	programs: programs
      })));
  };

  this.getSubmitOptions = function() {
    var __ = this;
    return $.extend(
      __.defaultSubmitOptions(),
      {
        beforeSubmit: function () {
	  __.modal.find('form').selectable_submit();
	},
        httpMethod: function() { return 'post' },
        url: function() {
	  return $('a.enable_applicant_gateway_activity_link').attr('data-submit_url');
	},
        data: function () { return __.modal.find('form').serialize(); },
        onSuccess: function (data, status, xhr) {
	  var errors = "";
	  var error_preface = $("<div class='error_preface'>The selected activity could not be enabled for the following applicants:</div>");
	  var error_dialog = $("<div class='activity_error_dialog'></div>");
	  var close_button = $("<button>Close</button>");
	  for (var ix = 0; ix < data.activities.length; ++ix) {
	    var activity = data.activities[ix];
	    if (activity.errors && Object.keys(activity.errors).length > 0) {
	      errors += activity.applicant + ' - ' + activity.program + '<br/>';
	    };
	  }
	  if (errors) {
	    errors = $('<div class="error_messages">' + errors + '</div>');
	    error_preface.appendTo(error_dialog);
	    errors.appendTo(error_dialog);
	    close_button.appendTo(error_dialog);
	    error_dialog.appendTo($('body')).dialog({ width: 600, height: 450, title: 'Alert' });
	    close_button.on('click', function() { error_dialog.dialog('close'); });
	  }
	  PageUtils.jsonFlashMessage(xhr);
	  return true;
	},
	dataType: function () { return 'json'; }
      }
    );
  };
};
