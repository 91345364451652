WEBADMIT.refreshRecentFiles = function (fileId, recentFileType, row){
  var downloadLinksDiv = row.find('#recent_file_download_links_' + fileId);
  var downloadEmptyDiv = row.find('.recent_file_download_links_' + fileId);
  var expirationCell = row.find('#recent_file_expiration_' + fileId);

  var path = "/recent_files/" + fileId + "?recent_file_type=" + recentFileType;

  function updateDownloadLinks(downloadLinks) {
    downloadLinksDiv.removeClass('hidden_recent_file_link').addClass('visible_recent_file_link');
    downloadEmptyDiv.removeClass('visible_recent_file_link').addClass('hidden_recent_file_link');
    // Not all recent file types have links that refresh dynamically.
    if (downloadLinks.length > 0) {
      downloadLinksDiv.html("");

      $.each(downloadLinks, function(index, downloadLink) {
        downloadLinksDiv.append('<li><a href="' + downloadLink.path + '">' + downloadLink.file_name + '</a><li>');
      });
    };
  }

  $.getJSON(path,{},function(json){
    row.children("td.recent_file_status").html(json.status_message);

    if (json.available) {
      updateDownloadLinks(json.download_links);
    }

    switch(json.status_name) {
      case "Failed":
        break;
      case "Available":
      case "Success With Errors":
        expirationCell.html(json.expires);
        row.children("td.recent_file_updated_at").html(json.updated_at);
        row.effect("highlight", {}, 5000);
        break;
      default:
        setTimeout(function(){WEBADMIT.refreshRecentFiles(fileId, recentFileType, row)}, 5000);
    }
  });
};

$(function() {
  $(".recent_file_row").each(function(){
    var row = $(this);
    var fileId = /\d+/.exec(row.attr('id'))[0];
    var recentFileType = row.data("recent-file-type");

    if (row.hasClass("in_progress_recent_file")) {
      WEBADMIT.refreshRecentFiles(fileId, recentFileType, row);
    }
  });
});

