ScheduleInterview.LocationSection = function() {
  ScheduleInterview.ViewSection.call(this);
  this.render = function(options) {
    return { hasLocations: this.buildListView(
      'locations',
      options.interviewType.locations,
      {
        idKey: 'location_id',
        selectedId: (this.hasPath(options, 'interview', 'location', 'location_id') ?
		     options.interview.location.location_id : 'none')
      }
    )};
  };
};
ScheduleInterview.LocationSection.prototype = Object.create(ScheduleInterview.ViewSection.prototype);
ScheduleInterview.LocationSection.prototype.constructor = ScheduleInterview.LocationSection;
