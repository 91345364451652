/* jquery-accordion_select.js */

/* Author: Bill Chapman
   Desc:  Turn a select box in to an accordion
   Code Standards: methods and hash keys are camel case and variables are underscored
   Requirements: jquery-ui for accordion and modal
   Usage: Works on a select box with options groups, converts it to an accordion
   Output: HTML for accordion format that is than converted to accordion by jquery ui

  <div id="accordion">
    <a href="#">First header</a>
    <div>First content</div>
    <a href="#">Second header</a>
    <div>Second content</div>
   </div>

   Usage: $('element').accordionSelect({options},callBackMethod = function(id,value) );
   The call back method will have an id and a value passed in synononmous to value and text
   for a select box entry
*/

//Should allow noConflict compatibility
; ( function( $ ) {

  $.fn.accordionSelect = function(options,callBack) {
    this.each(function (index) {
      // all options are initialized here for
      // reference, options provided externall will take precidence
      options = $.extend( {
         containerId: "container_for_accordion",
         linkText:    "Click to select an item",
         target: 'self',
         modal: false
      }, options || {});

      var replacement_link = $("<a href='#'></a>");
      replacement_link.on("click", function(){
        var select_box = $(this).siblings("select:first");
        accordion_container.accordion({fillSpace: true, clearStyle: true}).dialog({modal: true, autoOpen: false, position: { my: 'top', at: 'top' }}).dialog('open');
        bind_field_selector_links(select_box);
      });

      // Take the select box that this was called upon and
      // convert it to an accordion. We create the structure
      // required by the jquery ui accordion and attach a
      // callback function to each link
      var accordion_container = $(
        "<div id='" + options.containerId + "'></div>"
      );

      var optgroups = $(this).children('optgroup');
      var selected_option = $(this).find(":selected");
      if (selected_option.length != 0 && !selected_option.attr("disabled")) {
        var selected_text = selected_option.text();
        replacement_link.html(selected_text);
      } else {
        replacement_link.html(options.linkText);
      }

      optgroups.each(function(){
        accordion_container.append(
          $("<div><a href='#'>" + $(this).attr('label') + "</a></div>")
        );
        var option_container = $("<div class='option_container' />");
        var options = $(this).children('option');

        var select_box = this;

        options.each(function(){
          replacement_link.attr("class", "field_chooser");

          var current_option = $(this);
          var callback_link = $("<div></div>");

          callback_link.on('click',function(){
            callBack(accordion_container, replacement_link, select_box, current_option.val(), current_option.text());
          });

          callback_link.html($(this).text());
          option_container.append(callback_link);

        });

        accordion_container.append(option_container);

      });

      // If you set modal to true it pops up the accordion
      // in a modal window. otherwise it either replaces
      // the select box (self) or appends to the specified target element
      if(options.modal) {
        // bind is now called when replacement_link is clicked
        $(this).after(replacement_link);
        $(this).hide();

      } else if (options.target == 'self') {
        // just place the accordion in the target container area
        $(this).replaceWith(accordion_container);
        accordion_container.accordion();

      } else {
        // the accordion should be placed inside of a target element
        $(this).toggle();
        $(options.target).append(accordion_container);
        accordion_container.accordion({fillSpace: true});
      }

      function bind_field_selector_links(select_box) {
        var field_links = $('.option_container div');
        var replacement_link = select_box.siblings(".field_chooser:first");
        var optgroups = select_box.children('optgroup');

        field_links.each( function(index) {
          text_value = $(this).text();
          var current_option = optgroups.children().filter( function() {
            return $(this).text() == text_value;
          });
          $(this).off('click').on('click', function() {
            callBack(accordion_container, replacement_link, select_box, current_option.val(), current_option.text());
          });
        });
      }
    })
  }
})( jQuery );
