$(document).ready(function () {
  $('#primary_actions').on('click', '.clipboard_import', function (e) {
    e.preventDefault();
    var clipboarddialogNode = $('<div id="import_applicants_dialog"></div>').html('<div class="loading"></div>').load(this.href);
    var clipboarddialog = clipboarddialogNode.dialog({
      height: 250,
      autoOpen: false,
      modal: true,
      width: 400,
      title: 'Add Applicants From .CSV To Clipboard',
      close: function(event, ui) {
          $(this).dialog('close');
          $(this).remove();
      }
    });
    clipboarddialog.parent().css('overflow', 'hidden');
    clipboarddialog.dialog('open');
  });

  $(document).on('change', '#clipboard_upload',function() {
    if($('#clipboard_upload').get(0).files.length > 0) {
      $('#import_applicants_dialog li').addClass('clipboard_submit');
    }else{
      $('#import_applicants_dialog li').removeClass('clipboard_submit');
    }
  });
});
