/*global Bloodhound*/
WEBADMIT.genericTypeahead = {
  addGenericTypeaheadsToPage: function addGenericTypeaheadsToPage(inputBox) {
    var bloodhound = new Bloodhound({
      initialize: false,
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      identify: function(obj) { return obj.id; },
      prefetch: {
        url: inputBox.data().typeahead,
        cache: false
      }
    });

    var hiddenInput =
      $('<input id="' + inputBox.attr('id') +
        '-hidden-typeahead" type="hidden" name="' +
        inputBox.attr('name') + '">'
      );

    inputBox.after(hiddenInput);

    inputBox.typeahead(null, {
      limit: 25,
      displayKey: 'name',
      source: bloodhound
    }).
    on('typeahead:autocomplete typeahead:select', function (event, data) {
      hiddenInput.val(data.id);
    });
    return bloodhound.initialize();
  }
};

$(document).on('bloodhound-loaded', function() {
  $('input.generic-typeahead').each(function() {
    WEBADMIT.genericTypeahead.addGenericTypeaheadsToPage($(this));
  });
});
