/* jquery.tree.js */

; (function($) {

  $.fn.treeClose = function(noRecurse){
    var $tree = $(this);
    $tree.addClass('closed').removeClass('opened');
    if(!noRecurse){
      $tree.find('.tree_node').treeClose(true);
    }
    return $tree;
  }

  $.fn.treeOpen = function(){
    var $tree = $(this);
    $tree.addClass('opened').removeClass('closed');
    $tree.trigger('tree-open');
  }

  $.fn.treeView = function(options, callback){
    var $treeView = $(this);
    $treeView.find('.tree_node').treeClose();

    $treeView.find('.toggle_tree').click(function(event){
      var $target     = $(this);
      var item = $(this).find('a');
      var item_li = $(this).closest('li');
      var name = item.text();
      var $targetTree = $target.closest('.tree_node');
      if($targetTree.is('.opened')){
        $targetTree.treeClose();
        item.attr('aria-expanded', 'false');
      } else {
        $targetTree.parent().closest('.tree_node').treeClose().treeOpen();
        $targetTree.treeOpen();
        item.attr('aria-expanded', 'true');
         var verifyFields = setInterval(checkFields, 200);
         function checkFields(){
          if(item_li.find('ul.collapsible-list li:first a:first').length){
            item_li.find('ul.collapsible-list li:first a:first').focus();
            clearInterval(verifyFields);
          }
         }
      }
      if(! $.data($target, 'allow_default')){
        event.preventDefault ? event.preventDefault() : event.returnValue = false;
      }
    });

    $treeView.find('.toggle_tree a').click(function(){
      window.location = $(this).attr('href');
    });
  };

})( jQuery );
