$(document).ready(function(event) {
  var dataCache = {}
  var populateDropdown = WEBADMIT.populateDropdown;

  $('body.programs form.program_form .cas_dropdown').change(function(event) {
    var casId = $(this).val()

    retrieveData(casId, function(data) {
      populateDropdown(".organization_dropdown", data.organizations)
      populateDropdown(".degree_dropdown", data.degrees)
      populateDropdown(".delivery_dropdown", data.deliveries)
      populateDropdown(".track_dropdown", data.tracks)
      populateDropdown(".type_dropdown", data.types)
    })
  })

  function retrieveData(casId, callback) {
    if (dataCache[casId]) {
      callback(dataCache[casId])
      return
    }

    $.getJSON('/associations/' + casId, function(data, status, xhr) {
      dataCache[casId] = data
      callback(data)
    })
  }
})
